import React, { useEffect, useState } from "react";
import CamBrand from "../../../components/camera-register/cam-brand";
import CamModel from "../../../components/camera-register/cam-model";
import CamPlan from "../../../components/camera-register/cam-plans";
import HikvisionCamSerial from "../../../components/camera-register/hikvision-serial-number";
import IntelbrasCamSerial from "../../../components/camera-register/intelbras-serial-number";
import RtspLink from "../../../components/camera-register/rtsp-link";
import CamMap from "../../../components/camera-register/cam-map";
import { UserData, ClientError, invalidBearerToken, Plan, platformAPI, UnexpectedError, Area } from "../../../services/platform-api";
import '../../../styles/tooltips.css';
import { translate } from "../../../services/service-translate";
import RtspImage from "../../../components/camera-register/rtsp-image";
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import Toast from "react-native-toast-message";

interface CrmCameraRegistrationComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}
export interface Model {
    title: string,
    maxLength: number;
}

export type CameraBrands = "HIKVISION" | "HIK_PRO_CONNECT" | "INTELBRAS" | 'UNKNOWN';
export type CameraSteps = "brand" | "model" | "serial_number_hik" | "plan" | 'serial_number_intelbras' | "map" | 'rtsp_link' | 'rtsp_image';

//São Paulo
const startingLat = -23.5506507;
const startingLng = -46.6333824;


export default function CrmCameraRegistration({
    navigation, authenticatedUser
}: CrmCameraRegistrationComponentProps) {
    const [brand, setBrand] = useState<CameraBrands>('UNKNOWN');
    const [model, setModel] = useState<Model>();
    const [loading, setLoading] = useState(false);
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [camPlan, setCamPlan] = useState<Plan>();
    const [step, setStep] = useState<CameraSteps>("brand");
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [port, setPort] = useState<number>(80);
    const [rtsp, setRtsp] = useState<string>('');
    const [lat, setLat] = useState<number>(startingLat);
    const [lng, setLng] = useState<number>(startingLng);
    const [image, setImage] = useState<string>('');
    const [area, setArea] = useState<Area | null>(null);
    const [isRtmpCamera, setIsRtmpCamera] = useState(false);

    async function saveCamera() {
        setLoading(true);
        try {
            if (!camPlan) {
                return;
            }
            const res = await platformAPI.publishCamera({
                title: title,
                streamName: serialNumber,
                factory: brand,
                rtsp: isRtmpCamera ? 'RTMP' : rtsp,
                isRtmpCamera: isRtmpCamera,
                plan: camPlan,
                username: login !== '' ? login : undefined,
                password: password !== '' ? password : undefined,
                port: brand === 'INTELBRAS' ? port : undefined,
                lat: lat,
                lng: lng,
                area: area
            });
            setLoading(false);
            window.location.href = `/cameras/${res.id}`;
            return;

        } catch (error) {
            setLoading(false);
            console.error(error);
            if (error instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(error.message),
                });
            } else if (error instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate(error.message),
                });
            }
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (!authenticatedUser.permissions.can_create_camera || wl.id === 1) {
                return navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {
        checkPermissions();
        const unsubscribe = navigation.addListener('focus', () => {
            setBrand('UNKNOWN');
            setModel(undefined);
            setLogin('');
            setPassword('');
            setPort(80);
            setSerialNumber('');
            setTitle('');
            setCamPlan(undefined);
            setStep('brand');
            setImage('');
            setRtsp('');
        });
        return unsubscribe;
    }, [navigation, authenticatedUser]);

    useEffect(() => {
        document.body.style.position = "fixed";
    }, []);

    function goBack() {
        if (step === 'plan') {
            return setCamPlan(undefined);
        }
        if (step === 'serial_number_hik') {
            return setSerialNumber('');
        }
        navigation.navigate("CrmCameraList");
    }

    return (
        <DefaultPageContainer>
            {step === 'brand' ?
                <CamBrand
                    setStep={setStep}
                    setBrand={setBrand}
                    goBack={goBack}
                    setIsRtmpCamera={setIsRtmpCamera}
                /> : <></>
            }
            {step === 'model' ?
                <CamModel
                    camBrand={brand}
                    setModel={setModel}
                    setStep={setStep}
                    setBrand={setBrand}
                    setIsRtmpCamera={setIsRtmpCamera}
                /> : <></>
            }
            {step === 'serial_number_hik' ?
                <HikvisionCamSerial
                    model={model}
                    brand={brand}
                    serialNumber={serialNumber}
                    setSerialNumber={setSerialNumber}
                    setStep={setStep}
                    setTitle={setTitle}
                    setLoading={setLoading}
                    title={title}
                    setBrand={setBrand}
                    loading={loading}
                /> : <></>
            }
            {step === 'serial_number_intelbras' ?
                <IntelbrasCamSerial
                    model={model}
                    brand={brand}
                    serialNumber={serialNumber}
                    setSerialNumber={setSerialNumber}
                    setStep={setStep}
                    setTitle={setTitle}
                    setLoading={setLoading}
                    title={title}
                    setBrand={setBrand}
                    login={login}
                    password={password}
                    port={port}
                    setLogin={setLogin}
                    setPassword={setPassword}
                    setPort={setPort}
                    loading={loading}
                /> : <></>
            }
            {step === 'map' ?
                <CamMap
                    brand={brand}
                    setLoading={setLoading}
                    setStep={setStep}
                    setLat={setLat}
                    setLng={setLng}
                    lat={lat}
                    lng={lng}
                    setArea={setArea}
                    area={area}
                /> : <></>
            }
            {step === 'rtsp_link' ?
                <RtspLink
                    rtsp={rtsp}
                    setRtsp={setRtsp}
                    setStep={setStep}
                    setLoading={setLoading}
                    setBrand={setBrand}
                    setImage={setImage}
                    loading={loading}
                /> : <></>
            }
            {step === 'rtsp_image' ?
                <RtspImage
                    setTitle={setTitle}
                    setStep={setStep}
                    title={title}
                    image={image}
                    setImage={setImage}
                    setLoading={setLoading}
                /> : <></>
            }
            {step === 'plan' ?
                <CamPlan
                    setPlan={setCamPlan}
                    setStep={setStep}
                    saveNewCam={saveCamera}
                    setLoading={setLoading}
                    plan={camPlan}
                    loading={loading}
                /> : <></>
            }
        </DefaultPageContainer>
    );
}
