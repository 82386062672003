import React, { useEffect, useState } from 'react';
import {
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    TextInput,
    Image
} from 'react-native';
import { ActivityIndicator, List } from 'react-native-paper';
import { useNavigationState } from '@react-navigation/native';
import { translate } from '../../../services/service-translate';
import { VehicleInformation, forbiddenAccess, invalidBearerToken, platformAPI } from '../../../services/platform-api';
import { TextInputMask } from "react-native-masked-text";
import DefaultPageContainer from '../../../components/DefaultPageContainer';

interface BlacklistFormComponentProps {
    navigation: Navigation;
    route: {
        params?: { id?: string, cameraGroupId: string; };
    };
}

const headerSize = 60;
const footerSize = 30;

export default function BlacklistForm({ navigation, route }: BlacklistFormComponentProps) {
    const blacklistItemId = route.params?.id ? route.params.id : undefined;
    const cameraGroupId = route.params?.cameraGroupId ? route.params.cameraGroupId : undefined;

    const index = useNavigationState(state => state);
    const [isPlateValid, setIsPlateValid] = useState<boolean>(true);
    const [whitelist, setWhitelist] = useState(false);
    const [plate, setPlate] = useState<string>('');
    const [vehicle, setVehicleInfo] = useState<VehicleInformation>();
    const [description, setDescription] = useState<string>('');
    const [loading, setLoading] = useState(false);

    async function getVehicleInfo() {
        try {
            if ((plate.length < 8 && !blacklistItemId)) {
                return setVehicleInfo(undefined);
            }
            setLoading(true);

            const unmaskedPlate = plate.replace("-", "");

            const vehicleInformation = await platformAPI.getVehicleInfo(unmaskedPlate);
            setVehicleInfo(vehicleInformation);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
        }
    }

    async function getBlacklistGroup(id: string) {
        const lprGroup = await platformAPI.getBlacklistGroup(id);
        setWhitelist(lprGroup.whitelist);
    }

    async function getBlacklistItem(id: string) {
        const blacklist = await platformAPI.getBlacklistItem({
            id: id,
            cameraGroupId: cameraGroupId
        });
        setPlate(blacklist.plate);
        setDescription(blacklist.desc);
    }

    useEffect(() => {
        getVehicleInfo();
    }, [plate]);

    useEffect(() => {
        if (blacklistItemId) {
            getBlacklistItem(blacklistItemId);
        }
        if (cameraGroupId) {
            getBlacklistGroup(cameraGroupId);
        }
    }, []);

    function goBack() {
        if (index.routes.length > 1) {
            navigation.navigate("Blacklist", { id: cameraGroupId });
            return;
        }
        window.location.href = `/crm/lpr/blacklist${cameraGroupId ? '/' + cameraGroupId : ''}`;
    }

    function validate() {
        if (!plate || !description) {
            return false;
        }

        if (!isPlateValid) {
            return false;
        }

        return true;
    }

    async function save() {
        try {

            const payload = {
                desc: description,
                plate: plate,
            };

            if (blacklistItemId) {
                if (cameraGroupId) {
                    await platformAPI.updateBlacklistItem({
                        ...payload, id: blacklistItemId, cameraGroup: {
                            cameraGroupId: cameraGroupId,
                            whitelist: whitelist
                        }
                    });
                } else {
                    await platformAPI.updateBlacklistItem({ ...payload, id: blacklistItemId });
                }
            } else {
                if (cameraGroupId) {
                    await platformAPI.createBlacklistItem({
                        ...payload, cameraGroup: {
                            cameraGroupId: cameraGroupId,
                            whitelist: whitelist
                        }
                    });
                } else {
                    await platformAPI.createBlacklistItem(payload);
                }
            }
            goBack();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                goBack();
                return;
            }
            console.error(err);
        }
    }

    function plateInputStyle() {
        if (blacklistItemId) {
            return styles.inputsDisabled;
        }
        if (isPlateValid) {
            return [styles.textInputs, styles.textInputsTitle];
        }
        return styles.emptyTextInputs;
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("blacklist")}</Text>
                    </View>
                </View>
                <View style={styles.registerView}>
                    {validate() ?
                        <TouchableOpacity onPress={() => save()}>
                            <Text style={styles.register}>{translate("save")}</Text>
                        </TouchableOpacity>
                        : <Text style={styles.registerInvalid}>{translate("save")}</Text>
                    }
                </View>

            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={[styles.inputTextView, styles.titleInput]}>
                    <Text style={styles.inputTextTitle}>{translate("plate")}</Text>
                    <TextInputMask
                        editable={!blacklistItemId}
                        type={'custom'}
                        style={plateInputStyle()}
                        options={{
                            mask: 'AAA-9S99'
                        }}
                        value={plate}
                        onChangeText={(text) => {
                            setPlate(text.toUpperCase());
                            if (text.length == 8) {
                                setIsPlateValid(true);
                                return;
                            }
                            setIsPlateValid(false);
                        }}
                        maxLength={8}
                        keyboardType='default'
                    />
                </View>
                <View style={[styles.inputTextView, styles.titleInput]}>
                    <Text style={styles.inputTextTitle}>{translate("description")}</Text>
                    <TextInput
                        style={[styles.textInputs, styles.textInputsTitle]}
                        value={description}
                        onChangeText={(text) => setDescription(text)}
                    />
                </View>

                {vehicle ?
                    <>
                        <ItemSeparator />

                        <View style={{ marginTop: 15, marginLeft: 3 }}>
                            <View style={styles.carIconView}>
                                <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_car.png' }} style={styles.carIcon} />
                            </View>
                            {vehicle.brand ?
                                <View style={styles.contentView}>
                                    <View style={styles.iconView} />
                                    <Text style={styles.contentLabel}>
                                        <Text style={{ fontWeight: '600' }}>{translate('vehicleBrand')}:</Text> {vehicle.brand}
                                    </Text>
                                </View>
                                : null
                            }
                            {vehicle.model ?
                                <View style={styles.contentView}>
                                    <View style={styles.iconView} />
                                    <Text style={styles.contentLabel}>
                                        <Text style={{ fontWeight: '600' }}>{translate('vehicleModel')}:</Text> {vehicle.model}
                                    </Text>
                                </View>
                                : null
                            }
                            {vehicle.color ?
                                <View style={styles.contentView}>
                                    <View style={styles.iconView} />
                                    <Text style={styles.contentLabel}>
                                        <Text style={{ fontWeight: '600' }}>{translate('vehicleColor')}:</Text> {vehicle.color}
                                    </Text>
                                </View>
                                : null
                            }
                            {vehicle.type ?
                                <View style={styles.contentView}>
                                    <View style={styles.iconView} />
                                    <Text style={styles.contentLabel}>
                                        <Text style={{ fontWeight: '600' }}>{translate('vehicleType')}:</Text> {vehicle.type}
                                    </Text>
                                </View>
                                : null
                            }
                            {vehicle.city ?
                                <View style={styles.contentView}>
                                    <View style={styles.iconView} />
                                    <Text style={styles.contentLabel}>
                                        <Text style={{ fontWeight: '600' }}>{translate('vehicleOrigin')}:</Text> {vehicle.city}
                                    </Text>
                                </View>
                                : null
                            }
                        </View> </> : null}
                <View style={styles.footer}>
                    <ActivityIndicator
                        animating={loading}
                        style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                        size="small"
                        color="#0071bc"
                    />
                </View>
            </ScrollView>
        </DefaultPageContainer>
    );

}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start"
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    scrollContainer: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 60px)',
    },
    inputTextView: {
        marginTop: 15,
        width: '100%',
    },
    titleInput: {
        marginBottom: 10,
    },
    inputTextTitle: {
        marginLeft: 10,
        marginBottom: 3,
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        color: '#000',
    },
    textInputsTitle: {
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    emptyTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        cursor: 'default'
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
        marginVertical: 5
    },
    contentView: {
        flexDirection: "row",
        alignItems: 'center',
        height: 30,
    },
    contentLabel: {
        fontFamily: 'Helvetica',
        marginLeft: 14,
        color: '#58595b'
    },
    iconView: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    carIconView: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
    carIcon: {
        width: 16,
        height: 11.2
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: -20,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
});
