import React, { useState, useEffect, useCallback } from 'react';
import {
    View,
    Text,
    StyleSheet,
    KeyboardAvoidingView,
    TouchableOpacity,
    TextInput,
    Image,
    ScrollView,
    FlatList,
    Modal,
    Pressable,
    Alert,
    ActivityIndicator,
    ListRenderItemInfo
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Icon } from 'react-native-elements';
import { translate } from '../services/service-translate';
import moment from 'moment';
import { platformAPI, invalidBearerToken, Video, UserData } from '../services/platform-api';
import { RefreshControl } from 'react-native-web-refresh-control';
import VideoImage from '../components/VideoImage';
import Toast from 'react-native-toast-message';
import { useFocusEffect } from '@react-navigation/native';
import DefaultPageContainer from '../components/DefaultPageContainer';
import PersonalAlert from '../components/PersonalAlert';

interface VideoComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData
}


export default function VideoComponent({ navigation, authenticatedUser }: VideoComponentProps) {
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [noVideos, setNoVideos] = useState(false);
    const [refreshing, setRefreshing] = useState(true);
    const [filteredVideos, setFilteredVideos] = useState<Video[]>([]);
    const [allVideos, setAllVideos] = useState<Video[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [videoToBeDeleted, setVideoToBeDeleted] = useState(0);

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    useEffect(() => {
        document.body.style.position = 'fixed';
        loadVideos();
    }, []);

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            setOnChangeSearch(true);
            setFilteredVideos(
                allVideos.filter(
                    (item) =>
                        item.title.toLowerCase().includes(val.toLocaleLowerCase())
                )
            );
        } else {
            setOnChangeSearch(false);
            setFilteredVideos(allVideos);
        }
    };

    const cleanSearchBar = () => {
        onChangeSearchBar('');
        setOnChangeSearch(false);
    };

    function renderItem({ item }: ListRenderItemInfo<Video>) {

        return (
            <>
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        Alert.alert('Modal has been closed.');
                        setModalVisible(!modalVisible);
                    }}
                >
                    <View style={styles.fadeView} />
                    <View style={styles.centeredView}>
                        <View style={[styles.containerModal, { marginTop: window.innerHeight / 2.2 }]}>
                            <Text style={styles.titleModal}>{translate('videoDeleteAnswer')}</Text>
                            <View style={styles.viewButtonsModal}>
                                <Pressable style={styles.buttonCloseModal} onPress={() => setModalVisible(!modalVisible)}>
                                    <Text style={styles.textButtonModal}>{translate('no')}</Text>
                                </Pressable>
                                <Pressable style={styles.buttonDeleteModal} onPress={() => onPressDelete()}>
                                    <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <TouchableOpacity disabled={item.status != 1} onPress={() => navigation.navigate('WatchVideo', { id: item.id })}>
                    <View style={styles.viewVideo}>
                        <View style={styles.headerVideo}>
                            <Text style={styles.title}>{item.title}</Text>
                            <View style={styles.viewDate}>
                                <Text style={styles.timeDate}>{moment(item.createdDate).fromNow()}</Text>
                            </View>
                        </View>
                        <VideoImage status={item.status} src={item.shotUrl} style={styles.image} />
                        {item.status == 2 ?
                            <>
                                <Text style={styles.textError}>{translate('error')}</Text>
                                <View style={styles.errorStatusView} />
                            </>
                            : null}
                        {item.status == 0 ?
                            <>
                                <View style={styles.processingView} />
                                <Text style={styles.process}>{translate('processing')}</Text>
                                <ActivityIndicator size="large" color="#000000" style={styles.loader} />
                            </>
                            : null}
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.deleteIcon} onPress={() => {
                    setModalVisible(true), setVideoToBeDeleted(item.id);
                }}>
                    <Icon name='delete' color='#f03667' />
                </TouchableOpacity>
                <View style={styles.cameraTitleView}>
                    <TouchableOpacity style={styles.cameraTitleButton}>
                        <Icon style={styles.iconVideo} name="videocam" color='#0071bc' size={22} />
                        <Text style={styles.cameraTitleText}>{item.Camera.title}</Text>
                    </TouchableOpacity>
                </View>
            </>
        );

    }

    async function loadVideos() {
        try {
            setRefreshing(true);

            const locale: string | null = await AsyncStorage.getItem('Translate');
            if (locale) {
                moment.locale(locale);
            }
            const listVideos = await platformAPI.getVideos();

            if (listVideos.length == 0) {
                setNoVideos(true);
            }

            setFilteredVideos(listVideos);
            setAllVideos(listVideos);
            setTimeout(() => setRefreshing(false), 1000);
        } catch (err) {

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);

            setTimeout(() => setRefreshing(false), 1000);
            setNoVideos(true);
        }
    }

    async function onPressDelete() {
        try {
            await platformAPI.deleteVideo(videoToBeDeleted);
            Toast.show({
                type: 'success',
                text1: translate('videoDeletedSuccess'),
            });
            setModalVisible(false);

            await loadVideos();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    return (
        <DefaultPageContainer excludeBottomPadding={true}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={50}
                behavior={'padding'}
                style={styles.containerAvoidView}
            >
                {showSearchBar ?
                    <View style={styles.searchInputView}>
                        <TouchableOpacity
                            style={styles.searchBack}
                            onPress={() => setShowSearchBar(false)}
                        >
                            <Icon name='arrow-back' type='Ionicons' color={'#fff'} />
                        </TouchableOpacity>
                        <TextInput
                            selectionColor='#0071bc'
                            style={styles.searchInput}
                            onChangeText={text => onChangeSearchBar(text)}
                            value={search}
                            autoFocus
                        />
                        {onChangeSearch ?
                            <TouchableOpacity
                                style={styles.searchDelete}
                                onPress={cleanSearchBar}
                            >
                                <Icon name='close' type='Ionicons' color={'#fff'} />
                            </TouchableOpacity> : null}
                    </View>
                    :
                    <View style={styles.header}>
                        <View style={styles.leftView} />
                        <View style={styles.logoView}>
                            {showSearchBar ? null : <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-topo.png' }} style={styles.logo} />}
                        </View>
                        <View style={styles.searchIconView}>
                            <TouchableOpacity onPress={() => setShowSearchBar(true)}>
                                {showSearchBar ? null : <Icon
                                    name='search'
                                    type='Feather'
                                    style={styles.searchIcon}
                                    color='#fff'
                                    size={40}
                                />}
                            </TouchableOpacity>
                        </View>
                    </View>
                }
            </KeyboardAvoidingView>
            {noVideos ?
                <View style={styles.noVideosView}>
                    <Text style={styles.noVideosText}>{translate('noVideos')}</Text>
                </View>
                :
                <ScrollView
                    bounces={false}
                    style={[styles.scrollView, { height: 1 }]}
                    refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={loadVideos} />
                    }
                >
                    <FlatList
                        bounces={false}
                        data={filteredVideos}
                        keyExtractor={(item) => item.id.toString()}
                        contentContainerStyle={styles.flatlist}
                        renderItem={renderItem}
                        removeClippedSubviews={true}
                        initialNumToRender={2}
                        maxToRenderPerBatch={1}
                        updateCellsBatchingPeriod={100}
                        windowSize={7}
                    />
                </ScrollView>
            }
            <PersonalAlert authenticatedUser={authenticatedUser} />
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0071bc',
        paddingBottom: 0
    },
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff"
    },
    containerAvoidView: {
        alignItems: 'center',
    },
    secundaryContainer: {
        backgroundColor: 'white',
    },
    header: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    leftView: {
        width: '33%',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 10
    },
    logoView: {
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        width: '33%',
        height: 60
    },
    logo: {
        width: 166,
        height: 32,
        alignSelf: 'center',
        justifyContent: 'center'
    },
    searchIconView: {
        alignItems: 'flex-end',
        width: '33%',
    },
    searchIcon: {
        marginRight: 10
    },
    searchInputView: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    searchInput: {
        width: '80%',
        height: '100%',
        color: '#fff',
        fontSize: 20,
    },
    searchBack: {
        alignSelf: 'center',
        marginLeft: '-10%',
        padding: 15
    },
    searchDelete: {
        justifyContent: 'center',
        marginRight: -30,
        padding: 10,
    },
    process: {
        position: 'absolute',
        marginTop: 215,
        alignSelf: 'center',
        fontSize: 20,
        fontWeight: '400',
        color: "#080808",
        marginRight: 15
    },
    loader: {
        alignSelf: 'center',
        position: 'absolute',
        marginTop: 130,
        marginRight: 15
    },
    processingView: {
        width: "97%",
        height: 200,
        borderRadius: 14,
        backgroundColor: '#e8ecf7',
        position: 'absolute',
        marginTop: 54
    },
    errorStatusView: {
        width: "97%",
        height: 200,
        borderRadius: 14,
        backgroundColor: 'red',
        opacity: 0.08,
        position: 'absolute',
        marginTop: 54
    },
    textError: {
        color: 'red',
        position: 'absolute',
        alignSelf: 'center',
        marginTop: 225,
        marginRight: 15,
        fontSize: 20
    },
    scrollView: {
        width: '100%',
    },
    flatlist: {
        paddingLeft: 15,
        paddingTop: 10
    },
    viewVideo: {
        paddingBottom: 30,
        alignItems: 'flex-start'
    },
    headerVideo: {
        flexDirection: 'row',
        marginBottom: 5,
        flex: 1,
        width: "97%",
        padding: 15
    },
    image: {
        width: "97%",
        height: 200,
        borderRadius: 14,
    },
    title: {
        alignSelf: "center",
        fontSize: 16,
        marginLeft: 5
    },
    cameraTitleView: {
        flexDirection: 'row',
        alignSelf: 'flex-end',
        marginEnd: "5%",
        marginTop: -40,
        marginBottom: 40,
        height: 10
    },
    cameraTitleButton: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    cameraTitleText: {
        color: "#0071bc",
        fontSize: 15
    },
    iconVideo: {
        justifyContent: 'center',
        alignSelf: 'center',
        paddingEnd: 3,
        paddingTop: 3
    },
    viewDate: {
        flex: 1,
        justifyContent: 'center',
        width: "80%",
    },
    timeDate: {
        alignSelf: 'flex-end',
        marginRight: 10,
        color: '#878787',
        paddingTop: 3
    },
    deleteIcon: {
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: 20,
        marginTop: -15
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140
    },
    titleModal: {
        color: "#0071bc",
        fontSize: 20,
        fontWeight: "600",
        paddingTop: 15,
        paddingBottom: 30,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 2,
        borderColor: '#0B4870',
        borderRadius: 15,
        padding: 5,
        paddingHorizontal: 15,
        backgroundColor: '#0B4870'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 2,
        borderColor: '#0071bc',
        borderRadius: 15,
        padding: 5,
        paddingHorizontal: 15,
        backgroundColor: '#0071bc'
    },
    textButtonModal: {
        fontSize: 16,
        fontWeight: "600",
        color: '#fff'
    },
    noVideosView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
    noVideosText: {
        fontSize: 16,
        fontWeight: '500',
        color: '#aeaeae',
        alignSelf: 'center',
        justifyContent: 'center',
        textAlignVertical: 'center',
        textAlign: 'center'
    }
});
