import React from 'react';
import { translate } from '../../services/service-translate';
import {
    StyleSheet,
    View,
    Text,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
} from "react-native";
import { List } from "react-native-paper";
import { CameraBrands, CameraSteps } from '../../pages/crm/camera/Register';
import { ClientError, platformAPI, UnexpectedError } from '../../services/platform-api';
import '../../styles/tooltips.css';
import Toast from 'react-native-toast-message';

interface CamUniqueProps {
    setRtsp: React.Dispatch<React.SetStateAction<string>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setBrand: React.Dispatch<React.SetStateAction<CameraBrands>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setImage: React.Dispatch<React.SetStateAction<string>>;
    rtsp: string;
    loading: boolean;
}

const headerSize = 60;
const footerSize = 30;
export default function RtspLink({ setRtsp, setStep, rtsp, setLoading, setBrand, setImage, loading }: CamUniqueProps) {

    async function validCamera() {
        setLoading(true);
        try {
            const res = await platformAPI.testRtsp(
                rtsp
            );
            setLoading(false);
            setImage(`data:image/JPEG;base64,${res.image}`);
            return setStep('rtsp_image');
        } catch (error) {
            setLoading(false);
            console.error(error);
            if (error instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(error.message),
                });
            } else if (error instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate(error.message),
                });
            }
        }
    }

    function goBack() {
        setStep('brand');
        setLoading(false);
        setRtsp('RTMP');
        setBrand('UNKNOWN');
    }

    return (
        <>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                        onPress={() => goBack()}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("cameraRTSP")}</Text>
                    </View>
                </View>
            </View>
            {loading === true ? (null) : (
                <View style={styles.viewInputs}>
                    <Text style={styles.labelText}>{translate("rtspAddress")}</Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => setRtsp(value)}
                        value={rtsp}
                    />
                </View>
            )}
            {loading === true ? (
                <View style={styles.loadingView}>
                    <Text style={styles.loadingText}>{translate("connectRTSP")}</Text>
                    <ActivityIndicator
                        animating={loading}
                        style={styles.loading}
                        size="large"
                        color="#0071bc"
                    >
                    </ActivityIndicator>
                </View>
            ) : (null)}
            {loading === true ? (null) : (
                <View style={styles.footer}>
                    {rtsp !== 'RTMP' ?
                        <TouchableOpacity style={styles.buttonNext} onPress={() => {
                            validCamera();
                            setLoading(true);
                        }}>
                            <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                        </TouchableOpacity>
                        :
                        <TouchableOpacity style={styles.invalidButtonNext} disabled={true}>
                            <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                        </TouchableOpacity>
                    }
                </View>
            )}
        </>
    );
}
const styles = StyleSheet.create({

    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
    },
    viewInputs: {
        height: 300,
        flexDirection: 'column',
        width: '100%',
    },
    titleInput: {
        backgroundColor: '#fff',
        width: "95%",
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
    },
    labelText: {
        marginTop: 25,
        marginLeft: 10,
        fontSize: 16,
    },
    footer: {
        position: "absolute",
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "#FFF"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    textInputs: {
        backgroundColor: '#fff',
        width: "95%",
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    loading: {
        marginHorizontal: 'auto',
        transform: [{ scaleX: 2 }, { scaleY: 2 }],
        width: '20%',
        height: '20%',
    },
    loadingText: {
        color: 'black',
        textAlign: 'center',
        fontSize: 16
    },
    loadingView: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFFF2',
        marginVertical: 'auto',
        justifyContent: 'space-between',
        height: 150
    }
});

export { RtspLink };
