import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Hammer from 'hammerjs';

interface PlateImageProps {
    src: string | null;
    vehiclePosition: string | null;
    platePosition: string | null;
    maxCanvasWidth: number;
}

interface VehicleInfo {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface Point {
    x: number;
    y: number;
}

enum Zoom {
    NoZoom = 0,
    Vehicle = 1,
    Plate = 2
}

const lprImageWidth = 1920;
const lprImageHeight = 1080;
const lprImageRatio = lprImageWidth / lprImageHeight;

export default function VehicleImage({ src, vehiclePosition, platePosition, maxCanvasWidth }: PlateImageProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divCanvasRef = useRef<HTMLDivElement>(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [zoom, setZoom] = useState<Zoom>(1);
    let vehicleInfo: VehicleInfo | null = vehiclePosition ? JSON.parse(vehiclePosition) : null;
    const plateInfo: Point[] | null = platePosition ? JSON.parse(platePosition) : null;
    const [image] = useState<HTMLImageElement>(new Image());

    function resizeCanvas() {
        const canvas = canvasRef.current;
        const divCanvas = divCanvasRef.current;
        setScreenWidth(window.innerWidth);

        if (!canvas) {
            return;
        }

        if (!divCanvas) {
            return;
        }

        if (divCanvas.offsetWidth / divCanvas.offsetHeight > lprImageRatio) {
            // based on view height
            canvas.width = divCanvas.offsetHeight * lprImageRatio;
            canvas.height = divCanvas.offsetHeight;
        } else {
            // based on view width
            canvas.width = divCanvas.offsetWidth;
            canvas.height = divCanvas.offsetWidth / lprImageRatio;
        }
    }

    useEffect(() => {
        if (!src) {
            noImage();
            return;
        }
        resizeCanvas();
        image.addEventListener('load', () => {
            setZoom(1);
            if (!plateInfo && !vehicleInfo) {
                setZoom(0);
                return;
            }
            drawVehicleImage();
        });
        image.src = src;

        function handleResize() {
            resizeCanvas();
            setZoom(1);
            if (!plateInfo && !vehicleInfo) {
                setZoom(0);
                return;
            }
            drawVehicleImage();
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (zoom === Zoom.NoZoom) drawDefaultImage();

        if (zoom === Zoom.Vehicle) drawVehicleImage();

        if (zoom === Zoom.Plate) drawPlateImage();

        const hammer = new Hammer(canvasRef.current as HTMLElement);

        hammer.on('swiperight', handleSwipeRight);
        hammer.on('swipeleft', handleSwipeLeft);

        return () => {
            hammer.off("swiperight", handleSwipeRight);
            hammer.off("swipeleft", handleSwipeLeft);
        };
    }, [zoom]);


    const handleSwipeRight = () => {
        if (zoom == Zoom.NoZoom) {
            return;
        }
        setZoom(zoom - 1);
    };


    const handleSwipeLeft = () => {
        if (zoom == Zoom.Plate) {
            return;
        }
        setZoom(zoom + 1);
    };


    function noImage() {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.beginPath();
        ctx.setLineDash([]);
        ctx.moveTo(0, 0);
        ctx.lineTo(canvas.width, canvas.height);
        ctx.moveTo(0, canvas.height);
        ctx.lineTo(canvas.width, 0);
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.stroke();
        return;
    }

    function scaleIt(source: HTMLImageElement, scaleFactor: number) {
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        if (!ctx) {
            throw new Error('context doesnt exist');
        }
        const w = source.width * scaleFactor;
        const h = source.height * scaleFactor;
        c.width = w;
        c.height = h;
        ctx.drawImage(source, 0, 0, w, h);
        return (c);
    }

    async function drawDefaultImage() {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const c1 = scaleIt(image, canvas.width / lprImageWidth);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(c1, 0, 0, canvas.width, canvas.height);
    }

    function guessVehiclePosition(): VehicleInfo {
        const plateDimension = getPlateDimension();
        const vehicleWidth = plateDimension.width * 4; // vehicle should be something like 4 times larger than the plate
        const widthCenter = plateDimension.x + plateDimension.width - (plateDimension.width / 2);
        const heightCenter = plateDimension.y + plateDimension.height - (plateDimension.height / 2);
        const guessedVehiclePosition: VehicleInfo = {
            x: widthCenter - (vehicleWidth / 2),
            y: heightCenter - (vehicleWidth / 2),
            width: vehicleWidth,
            height: vehicleWidth
        };

        return guessedVehiclePosition;
    }

    function drawVehicleImage() {
        if (!vehicleInfo && !plateInfo) {
            return;
        }

        if (!vehicleInfo) {
            vehicleInfo = guessVehiclePosition();
        }

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (vehicleInfo.width / vehicleInfo.height > lprImageRatio) {
            // based on vehicle width
            const oldHeight = vehicleInfo.height;
            vehicleInfo.height = vehicleInfo.width / lprImageRatio;
            const heightDiff = vehicleInfo.height - oldHeight;
            vehicleInfo.y -= (heightDiff / 2);

            if (vehicleInfo.height + vehicleInfo.y > lprImageHeight) {
                vehicleInfo.y -= vehicleInfo.height + vehicleInfo.y - lprImageHeight;
            }

            if (vehicleInfo.y < 0) {
                vehicleInfo.y = 0;
            }
        } else {
            // based on vehicle height
            const oldWidth = vehicleInfo.width;
            vehicleInfo.width = vehicleInfo.height * lprImageRatio;
            const widthDiff = vehicleInfo.width - oldWidth;
            vehicleInfo.x -= (widthDiff / 2);

            if (vehicleInfo.width + vehicleInfo.x > lprImageWidth) {
                vehicleInfo.x -= vehicleInfo.width + vehicleInfo.x - lprImageWidth;
            }

            if (vehicleInfo.x < 0) {
                vehicleInfo.x = 0;
            }
        }

        ctx.drawImage(
            image,
            vehicleInfo.x,
            vehicleInfo.y,
            vehicleInfo.width,
            vehicleInfo.height,
            0,
            0,
            canvas.width,
            canvas.height,
        );
    }

    function getPlateDimension() {

        if (!plateInfo) {
            throw Error("There's no plate info.");
        }

        const rect = {
            minX: Infinity,
            maxX: 0,
            minY: Infinity,
            maxY: 0
        };

        for (const position of plateInfo) {
            if (position.x < rect.minX) {
                rect.minX = position.x;
            }

            if (position.x > rect.maxX) {
                rect.maxX = position.x;
            }

            if (position.y < rect.minY) {
                rect.minY = position.y;
            }

            if (position.y > rect.maxY) {
                rect.maxY = position.y;
            }
        }

        return {
            x: rect.minX,
            y: rect.minY,
            width: rect.maxX - rect.minX,
            height: rect.maxY - rect.minY
        };
    }

    function drawPlateImage() {

        if (!plateInfo) {
            return;
        }

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const plateDimension = getPlateDimension();

        if (plateDimension.width / plateDimension.height > lprImageRatio) {
            // based on vehicle width
            const oldHeight = plateDimension.height;
            plateDimension.height = plateDimension.width / lprImageRatio;
            const heightDiff = plateDimension.height - oldHeight;
            plateDimension.y -= (heightDiff / 2);

            if (plateDimension.height + plateDimension.y > lprImageHeight) {
                plateDimension.y -= plateDimension.height + plateDimension.y - lprImageHeight;
            }

            if (plateDimension.y < 0) {
                plateDimension.y = 0;
            }
        } else {
            // based on vehicle height
            const oldWidth = plateDimension.width;
            plateDimension.width = plateDimension.height * lprImageRatio;
            const widthDiff = plateDimension.width - oldWidth;
            plateDimension.x -= (widthDiff / 2);

            if (plateDimension.width + plateDimension.x > lprImageWidth) {
                plateDimension.x -= plateDimension.width + plateDimension.x - lprImageWidth;
            }

            if (plateDimension.x < 0) {
                plateDimension.x = 0;
            }
        }

        ctx.drawImage(
            image,
            plateDimension.x,
            plateDimension.y,
            plateDimension.width,
            plateDimension.height,
            0,
            0,
            canvas.width,
            canvas.height,
        );
    }

    return (
        <>
            <div ref={divCanvasRef} style={{
                width: screenWidth > maxCanvasWidth ? maxCanvasWidth : screenWidth,
                height: screenWidth > maxCanvasWidth ? maxCanvasWidth / lprImageRatio : screenWidth / lprImageRatio,
                justifyContent: "center",
                alignSelf: "center",
                alignItems: "center",
                position: "absolute",
                textAlign: "center",
            }}>
                <canvas
                    ref={canvasRef}
                />
                <View style={styles.bottomView}>
                    <View style={styles.buttonView}>
                        <TouchableOpacity onPress={() => setZoom(0)}>
                            <View style={[styles.circleView, { backgroundColor: zoom === 0 ? "#0071bc" : "#9d9ea0" }]} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setZoom(1)}>
                            <View style={[styles.circleView, { backgroundColor: zoom === 1 ? "#0071bc" : "#9d9ea0", display: !plateInfo && !vehicleInfo ? 'none' : undefined }]} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setZoom(2)}>
                            <View style={[styles.circleView, { backgroundColor: zoom === 2 ? "#0071bc" : "#9d9ea0", display: !plateInfo ? 'none' : undefined }]} />
                        </TouchableOpacity>
                    </View>
                </View>
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    bottomView: {
        width: "100%",
        height: 20,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonView: {
        width: 35,
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    circleView: {
        width: 8,
        height: 8,
        borderRadius: 20
    }
});
