import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
} from "react-native";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import L, { Map, Marker as LeafletMarker } from 'leaflet';
import { translate } from '../../../services/service-translate';
import { List } from 'react-native-paper';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../../../styles/map.css';
import { platformAPI } from '../../../services/platform-api';
import { useNavigationState } from '@react-navigation/native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';

interface functionProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
}

const headerSize = 60;

export default function ChangeCameraAddress({ navigation, route }: functionProps) {
    const params = route.params;
    const index = useNavigationState(state => state);
    const [address, setAddress] = useState<string>();
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [map, setMap] = useState<Map>();
    const markerRef = useRef<LeafletMarker>(null);

    /* eslint-disable */
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
        iconUrl: require("leaflet/dist/images/marker-icon.png").default,
        shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
    });
    /* eslint-enable */

    const myIcon = L.icon({
        iconUrl: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_map_badge.png',
        iconSize: [29, 35],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    });

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
    }, [navigation, route]);

    useEffect(() => {
        loadCameraData();
    }, [navigation]);

    useEffect(() => {
        getLatLon();
    }, [address]);

    useEffect(() => {
        map?.setView([lat, lng], 18);
    }, [lat, lng, map],);

    async function loadCameraData() {
        try {
            const camera = await platformAPI.getCameraForUpdate(params.id);

            const cameraLat = Number(camera.latitude);
            const cameraLon = Number(camera.longitude);

            setLat(cameraLat);
            setLng(cameraLon);

        } catch (err) {
            console.log(err);
        }
    }

    async function updateCameraAddress() {
        try {

            await platformAPI.updateCameraConfig(params.id, {
                latitude: lat?.toString(),
                longitude: lng?.toString()
            });
            goBack();

        } catch (err) {
            console.error(err);
        }
    }

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    const latLng = marker.getLatLng();
                    setLat(latLng.lat);
                    setLng(latLng.lng);
                }
            },
        }),
        [],
    );

    const getLatLon = async () => {
        try {
            if (address !== undefined) {
                const geocode = await geocodeByAddress(address);
                const { lat, lng } = await getLatLng(geocode[0]);
                setLat(lat);
                setLng(lng);
            }
        } catch (err) {
            console.log(err);
        }
    };

    function goBack() {
        if (index.routes.length > 1) {
            navigation.navigate("CrmCameraList");
            return;
        }
        window.location.href = '/crm/cameras';
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("camRegister")}</Text>
                    </View>
                </View>
                <View style={styles.registerView}>
                    <TouchableOpacity onPress={() => updateCameraAddress()}>
                        <Text style={styles.register}>{translate("save")}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Text style={styles.labelText}>{translate("Address")}</Text>
            <View style={styles.GooglePlaces}>
                <GooglePlacesAutocomplete
                    apiKey="AIzaSyATThzieYphmKnlRyELRUHs3NCLgAU9LrQ"
                    selectProps={{
                        onChange: (value) => {
                            setAddress(value?.label);
                        }
                    }}
                />
            </View>
            < View style={styles.mapContainer}>
                <MapContainer
                    doubleClickZoom={false}
                    dragging={false}
                    zoomControl={false}
                    scrollWheelZoom={true}
                    whenCreated={(map: Map) => setMap(map)}
                    center={[lat, lng]}
                    id="map"
                    zoom={18}
                >
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    />
                    <Marker
                        eventHandlers={eventHandlers}
                        icon={myIcon}
                        position={{ lat: lat, lng: lng }}
                        draggable={true}
                        ref={markerRef}
                    />
                </MapContainer>
            </View >
        </DefaultPageContainer>
    );
}
const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },

    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    labelText: {
        marginTop: 25,
        marginLeft: 10,
        fontSize: 16,
    },
    titleInput: {
        backgroundColor: '#fff',
        width: "95%",
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
    },
    GooglePlaces: {
        zIndex: -1,
        display: "flex",
        backgroundColor: '#fff',
        width: "95%",
        marginRight: 10,
        height: 45,
        color: '#000',
        fontSize: 16,
        marginLeft: 10,
    },
    mapContainer: {
        zIndex: -9999,
        flex: 1,
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "#FFF"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
});

