import React, { useState, useEffect } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    PageParam,
    CrmGroupListItem,
    forbiddenAccess
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    LayoutChangeEvent,
    ListRenderItemInfo,
    ScrollView,
    Modal,
    TextInput,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
    useWindowDimensions
} from 'react-native';
import {
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import '../../../styles/tooltips.css';
import { RefreshControl } from "react-native-web-refresh-control";
import { FlatList } from "react-native-gesture-handler";
import { showActionSheet, Option } from "../../../components/ActionSheet";
import { useActionSheet } from "@expo/react-native-action-sheet";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface CrmPermissionLevelsProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}
interface thisPage extends PageParam {
    filter?: string;
}

const headerSize = 60;
const footerSize = 30;

export default function CrmPermissionLevels({ navigation, authenticatedUser }: CrmPermissionLevelsProps) {
    const insets = useSafeAreaInsets();
    const { showActionSheetWithOptions } = useActionSheet();
    const [emptyList, setEmptyList] = useState(false);
    const [permissionLevelsList, setPermissionLevelsList] = useState<CrmGroupListItem[]>([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [permissionLevelsToBeDeleted, setPermissionLevelsToBeDeleted] = useState<CrmGroupListItem>();
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const [viewHeight, setViewHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [searchParams, setSearchParams] = useState<thisPage>({
        page: 1,
        filter: search
    });

    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSearchParams({
                page: 1
            });
        });
        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";
        checkPermissions();

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [authenticatedUser]);

    useEffect(() => {
        loadCrmLevelsPermissions();
    }, [searchParams]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !permissionLevelsList.length || permissionLevelsList.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id == 1 && !authenticatedUser.permissions.can_see_permission_levels_tab) {
                return navigation.navigate("Camera", { screen: "More" });
            } else if (!authenticatedUser.permissions.can_edit_permission_levels) {
                setReadOnly(true);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function loadCrmLevelsPermissions() {
        try {
            setLoading(true);
            if (searchParams.page === 1) {
                setRefreshing(true);
            }
            const permissionLevelsData = await platformAPI.getCrmPermissionLevel(searchParams);

            if (permissionLevelsData.length === 0 && searchParams.page === 1) {
                setPermissionLevelsList([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (permissionLevelsData.length === 0) {
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setPermissionLevelsList(permissionLevelsData);
            } else {
                setPermissionLevelsList([...permissionLevelsList, ...permissionLevelsData]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
            setLoading(false);
            setRefreshing(false);
        }
    }

    async function turnEnableDisable(selectedPermissionLevel: CrmGroupListItem) {
        try {
            await platformAPI.updateCrmPermissionsGroup({ id: selectedPermissionLevel.id, enabled: !selectedPermissionLevel.enabled, permissions: {} });
            handleRefresh();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function deletePermissionLevels() {
        if (!permissionLevelsToBeDeleted) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteGroup(permissionLevelsToBeDeleted.id);
            setDeleteModalVisible(false);
            handleRefresh();
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function handleLoadMore() {
        if (!searchParams.page) {
            return;
        }
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    function handleRefresh() {
        setRefreshing(true);

        setPermissionLevelsList([]);

        setSearchParams({
            ...searchParams,
            page: 1,
            filter: search
        });
        if (!emptyList) {
            setRefreshing(false);
        } else {
            setRefreshing(true);
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        setPermissionLevelsList([]);
        navigation.navigate("Camera", { screen: "More" });
    }

    function filteredPermissionLevels() {
        setLoading(true);

        if (search === searchParams.filter) {
            return setLoading(false);
        }

        if (search === '') {
            return cleanSearchBar();
        }

        setRefreshing(true);
        setSearchParams({
            page: 1,
            filter: search
        });
        setLoading(false);
        setRefreshing(false);
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            filteredPermissionLevels();
        }
    }

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            return setOnChangeSearch(true);
        }
        setOnChangeSearch(false);
    };

    function cleanSearchBar() {
        setPermissionLevelsList([]);
        setSearchParams({
            ...searchParams,
            page: 1,
            filter: ''
        });
        setSearch('');
        setOnChangeSearch(false);
    }

    function openActionSheet(permissionsGroup: CrmGroupListItem) {
        setPermissionLevelsToBeDeleted(permissionsGroup);
        const options: Option[] = [];

        options.push({
            title: readOnly ? translate("viewRegistration") : translate("editRegistration"),
            onClick: () => {
                navigation.navigate('CrmPermissionLevelsForm', { id: permissionsGroup.id });
            }
        }, {
            title: readOnly ? translate("userAssociations") : translate("associateUsers"),
            subtitle: `${permissionsGroup.totalUsers} ${translate("users")}`,
            onClick: () => {
                navigation.navigate('CrmPermissionLevelsUserAssociation', { id: permissionsGroup.id });
            },
        });

        if (!readOnly) {
            options.push({
                title: permissionsGroup.enabled ? translate("disable") : translate("enable"),
                onClick: (() => turnEnableDisable(permissionsGroup))
            });
            options.push({
                title: translate("delete"),
                destructive: true,
                onClick: () => {
                    setDeleteModalVisible(true);
                }
            });
        }

        showActionSheet(showActionSheetWithOptions, options);
    }

    const renderItem = ({ item }: ListRenderItemInfo<CrmGroupListItem>) => (
        <View style={styles.groupCard}>
            <TouchableOpacity style={styles.innerGroupCard} onPress={() => openActionSheet(item)}>
                <View
                    style={styles.innerGroupCard}
                    onLayout={(e: LayoutChangeEvent) => {
                        if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                    }}>
                    <View>
                        <Text style={styles.textButton}>{item.title}</Text>
                        {item.userDefault ?
                            <Text style={styles.textDefaultGroup}>{translate("defaultPermissionLevel")}</Text>
                            : null}
                    </View>
                    {item.enabled ? (
                        <div className="ur-tooltip">
                            <span className="ur-tooltip-text">
                                {translate("enabled")}
                            </span>
                            <div className="ur-tooltip-icon">
                                <List.Icon
                                    icon={"check-circle"}
                                    color="green"
                                    style={styles.enabledIcon}
                                />
                            </div>
                        </div>
                    ) : <div className="ur-tooltip">
                        <span className="ur-tooltip-text">
                            {translate("disabled")}
                        </span>
                        <div className="ur-tooltip-icon">
                            <List.Icon
                                icon={"close-circle"}
                                color="red"
                                style={styles.enabledIcon}
                            />
                        </div>
                    </div>}
                </View>
            </TouchableOpacity>
        </View>
    );

    return (
        <DefaultPageContainer>
            {showSearchBar ?
                <View style={styles.header}>
                    <View style={styles.bothSides}>
                        <TouchableOpacity
                            onPress={() => {
                                setShowSearchBar(false); cleanSearchBar();
                            }}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.searchInput}>
                        <TextInput
                            selectionColor='#0071bc'
                            style={[styles.searchInput, { width: windowWidth - 168 }]}
                            value={search}
                            onKeyPress={handleKeyDown}
                            onChangeText={text => {
                                setSearch(text); onChangeSearchBar(text);
                            }}
                            autoFocus
                        />
                    </View>
                    <View style={styles.bothSides}>
                        {onChangeSearch ?
                            <TouchableOpacity onPress={() => cleanSearchBar()}>
                                <List.Icon
                                    icon='close'
                                    color='#fff' />
                            </TouchableOpacity>
                            : null}
                        <TouchableOpacity
                            onPress={() => filteredPermissionLevels()}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="magnify"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={styles.header}>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate("permissionLevel")}</Text>
                        </View>
                    </View>
                    <View style={styles.registerView}>
                        <TouchableOpacity onPress={() => setShowSearchBar(true)}>
                            {showSearchBar ? null : <List.Icon
                                icon="magnify"
                                style={styles.searchIcon}
                                color='#fff'
                            />}
                        </TouchableOpacity>
                        {!readOnly ?
                            <TouchableOpacity onPress={() => navigation.navigate("CrmPermissionLevelsForm")}>
                                <List.Icon
                                    style={styles.register}
                                    icon="plus"
                                    color="#fff"
                                />
                            </TouchableOpacity> : null
                        }
                    </View>
                </View>}
            <Modal
                animationType='fade'
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => {
                    setDeleteModalVisible(!deleteModalVisible);
                }}>
                <View style={styles.fadeView} />
                <View style={styles.centeredView}>
                    <View style={[styles.containerModal, { marginTop: viewHeight / 2.2 }]}>
                        <Text style={styles.titleModal} numberOfLines={3} adjustsFontSizeToFit>{translate("deletePermissionLevelConfirmation")}{"\n"}{permissionLevelsToBeDeleted?.title}</Text>
                        <View style={styles.viewButtonsModal}>
                            <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deletePermissionLevels()}>
                                <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
            <ScrollView
                bounces={false}
                style={[styles.scrollView, { height: viewHeight }]}
            >
                {!permissionLevelsList.length ? (
                    !loading && (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>
                    )
                ) : (
                    <View>
                        <FlatList
                            style={[styles.scrollView, { height: viewHeight }]}
                            bounces={false}
                            data={permissionLevelsList}
                            contentContainerStyle={styles.flatlist}
                            renderItem={renderItem}
                            removeClippedSubviews={true}
                            updateCellsBatchingPeriod={100}
                            windowSize={12}
                            ItemSeparatorComponent={ItemSeparator}
                            nestedScrollEnabled
                            onEndReached={() => {
                                handleLoadMore();
                            }}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            onEndReachedThreshold={0.03}
                            onLayout={(e: LayoutChangeEvent) => {
                                setContentHeight(e.nativeEvent.layout.height);
                            }}
                        />
                    </View>
                )}
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    enabledIcon: {
        width: 20,
        height: 20,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    flatlist: {},
    contentContainer: {
        justifyContent: "space-between",
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    groupCard: {
        color: "red",
        width: "100%",
        height: "72px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    innerGroupCard: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textButton: {
        fontSize: 16,
        marginLeft: "15px",
        width: 250,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    textDefaultGroup: {
        marginLeft: "15px",
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    headerLeft: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    searchInputView: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: headerSize,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    searchInput: {
        height: '100%',
        color: '#fff',
        fontSize: 20,
        flexGrow: 1,
    },
    bothSides: {
        flexDirection: "row",
        alignItems: "center"
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    plus: {
        alignSelf: "center",
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        alignSelf: 'center'
    },
    register: {
        alignSelf: "flex-end",
    },
    registerView: {
        flexDirection: "row",
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 15,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    textButtonCloseModal: {
        color: "#000",
    },
});
