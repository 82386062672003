import React, { useState, useEffect } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    forbiddenAccess,
    CrmGroupCamera,
    GetCrmUserGroups,
    WhitelabelGroup
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import CheckBoxLists, { CheckAssociationItem } from "../../../components/CheckAssociation";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface CameraGroupsComponentProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

export default function CrmUserCameraGroups({ navigation, route, authenticatedUser }: CameraGroupsComponentProps) {
    const params = route.params;
    const [uncheckedGroups, setUncheckedGroups] = useState<WhitelabelGroup[]>([]);
    const [checkedGroups, setCheckedGroups] = useState<GetCrmUserGroups[]>([]);
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    useEffect(() => {
        checkPermissions();
        loadCameraGroups();
    }, [authenticatedUser]);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            document.body.style.position = 'fixed';
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        });
        return unsubscribe;
    }, [navigation, route]);

    useEffect(() => {
        if (!readOnly && checkedGroups) {
            loadWhitelabelGroups();
        }
    }, [readOnly, checkedGroups]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id == 1 && !authenticatedUser.permissions.can_see_user_details) {
                navigation.navigate("Camera", { screen: "More" });
            }
            if (authenticatedUser.permissions.can_edit_camera_group) {
                return setReadOnly(false);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function save() {
        try {
            if (!checkedGroups) return;

            const groupsUnchecked = checkedGroups.filter((groupCamera: GetCrmUserGroups) => !groupCamera.checked);
            const groupsChecked = uncheckedGroups.filter((groupCamera: WhitelabelGroup) => groupCamera.checked);

            const groupToPut = {
                groups_to_add: groupsChecked.map((camera: CrmGroupCamera) => camera.id),
                groups_to_rm: groupsUnchecked.map((camera: CrmGroupCamera) => camera.id),
            };

            if (groupToPut.groups_to_add.length || groupToPut.groups_to_rm.length) {
                await platformAPI.updateUserGroups(params.id, groupToPut);
            }

            goBack();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function loadWhitelabelGroups() {
        const userGroups = await platformAPI.getCrmGroupWl();
        setUncheckedGroups(userGroups.filter((camera: WhitelabelGroup) => !checkedGroups.find((userGroups) => userGroups.id === camera.id)));
    }

    async function loadCameraGroups() {
        try {
            if (!authenticatedUser) return;
            setLoading(true);

            const userGroupRequest = await platformAPI.getCrmUser(params.id);

            if (!userGroupRequest.GroupUser) {
                setLoading(false);
                return setCheckedGroups([]);
            }

            for (const user of userGroupRequest.GroupUser) {
                user.checked = true;
            }

            userGroupRequest.GroupUser.sort((a, b) => a.title > b.title ? 1 : -1);
            setCheckedGroups(userGroupRequest.GroupUser);

            setLoading(false);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate('CrmUserList');
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    function goBack() {
        setCheckedGroups([]);
        setUncheckedGroups([]);
        navigation.navigate("CrmUserList");
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    {!readOnly ?
                        <TouchableOpacity style={styles.titleHeaderView} onPress={save}>
                            <Text style={styles.titleHeader}>{translate("save")}</Text>
                        </TouchableOpacity> : null}
                </View>
            </View>
            <View style={styles.scrollContainer}>
                <ScrollView style={styles.backgroundView}>
                    <CheckBoxLists
                        dataChecked={checkedGroups as CheckAssociationItem[]}
                        dataUnchecked={uncheckedGroups as CheckAssociationItem[]}
                        readOnly={readOnly}
                    />
                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    backgroundView: {
        backgroundColor: "#fff",
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        width: '100%',
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
});
