import React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    GestureResponderEvent,
    Text,
    FlatList,
} from "react-native";
import { List } from "react-native-paper";
import { CameraBrands, CameraSteps } from '../../pages/crm/camera/Register';
import { translate } from '../../services/service-translate';

interface CamBrands {
    setBrand: React.Dispatch<React.SetStateAction<CameraBrands | 'UNKNOWN'>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setIsRtmpCamera: React.Dispatch<React.SetStateAction<boolean>>;
    goBack: ((event: GestureResponderEvent) => void);
}

interface ListProps {
    title: string;
    onPress: ((event: GestureResponderEvent) => void);
}

const headerSize = 60;

export default function CamBrand({ setBrand, setStep, goBack, setIsRtmpCamera }: CamBrands) {

    const brandOptions = [
        {
            title: 'INTELBRAS',
            onPress: () => {
                setBrand('INTELBRAS');
                setStep('model');
                setIsRtmpCamera(true);
            }

        },
        {
            title: 'HIKVISION',
            onPress: () => {
                setBrand('HIKVISION');
                setStep('model');
                setIsRtmpCamera(true);
            }

        },
        {
            title: translate('others'),
            onPress: () => {
                setBrand('UNKNOWN');
                setStep('rtsp_link');
                setIsRtmpCamera(false);
            }
        }
    ];

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    const Item = ({ title, onPress }: ListProps) => (
        <>
            <View style={styles.viewButton}>
                <TouchableOpacity style={styles.button} onPress={onPress}>
                    <View style={styles.button}>
                        <Text style={styles.textButton}>{title}</Text>
                    </View>
                </TouchableOpacity>
            </View>
            <ItemSeparator />
        </>
    );

    const renderItem = ({ item }: { item: ListProps; }) => (
        <Item title={item.title} onPress={item.onPress} />
    );

    return (
        <>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("camRegister")}</Text>
                    </View>
                </View>
            </View>
            <FlatList
                bounces={false}
                data={brandOptions}
                renderItem={renderItem}
                keyExtractor={(item: ListProps) => item.title}
            />
        </>
    );
}
const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    textButton: {
        fontSize: 26,
        color: 'black',
        textAlign: 'center',
        justifyContent: 'center',
    },
    button: {
        flexDirection: 'row',
        width: "100%",
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
    },
    viewButton: {
        borderTopColor: '#cecece',
        flexDirection: 'row',
    },
    buttonActive: {
        flexDirection: 'row',
        width: "100%",
        height: 45,
        alignItems: 'center',
        cursor: 'Pointer',
        borderWidth: 1,
        borderColor: "rgba(0, 113, 188, 0.5)",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
});

export { CamBrand };
