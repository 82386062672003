import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ListRenderItemInfo,
    FlatList,
    ScrollView,
    Image
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import Icon from 'react-native-vector-icons/Ionicons';
import Webcam from "react-webcam";

import {
    platformAPI,
    UserData,
    Person,
    Categories,
    ClientError
} from "../../../services/platform-api";
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import { translate } from "../../../services/service-translate";
import Toast from "react-native-toast-message";

interface PersonsOfInterestListProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

const headerSize = 60;

type PersonOfInterestSteps = "takeShot" | "listPeople";


export default function PersonsOfInterestList({
    navigation, authenticatedUser
}: PersonsOfInterestListProps) {
    const [categories, setCategories] = useState<Categories>([]);
    const [peopleOfInterest, setPeopleOfInterest] = useState<Person[]>([]);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState<PersonOfInterestSteps>('takeShot');

    const videoConstraints = {
        facingMode: { exact: "environment" }
    };

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        if (!authenticatedUser.permissions.can_access_horus_requests || !authenticatedUser.permissions.can_see_investigative_map) {
            return goBack();
        }
        getCategories();
    }, [authenticatedUser]);

    async function getCategories() {
        setLoading(true);
        try {
            const categories = await platformAPI.getCategories();
            setCategories(categories);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    }

    async function facialDetect(image: string) {
        setLoading(true);
        try {
            const persons = await platformAPI.facialDetect(image);
            setPeopleOfInterest(persons.pessoas);
            setStep("listPeople");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
            }
            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    const renderItem = ({ item }: ListRenderItemInfo<Person>) => {
        return (
            <View
                style={styles.itemContainer}
            >
                <View style={[styles.itemContainerInfo]}>
                    <View style={styles.iconContainer}>
                        <Image source={{ uri: item.thumbnail }} style={styles.image} resizeMode="cover" />
                    </View>
                    <View style={styles.itemContainerUsersInfo}>
                        <Text style={styles.title} numberOfLines={3} adjustsFontSizeToFit>
                            {item.name}
                        </Text>
                        <Text numberOfLines={3} adjustsFontSizeToFit style={styles.subTitle}>
                            {item.comment}
                        </Text>
                        <View style={{ flexDirection: 'row', alignContent: 'center', flexWrap: 'wrap', width: '98%' }}>
                            {item.watch_lists.map((watchListId) => {
                                const category = categories[watchListId];
                                return (
                                    <View key={category.id} style={[styles.tagContainer, { backgroundColor: `#${category.color}` }]}>
                                        <Text numberOfLines={1} adjustsFontSizeToFit style={styles.tagText}>
                                            {category.name}
                                        </Text>
                                    </View>
                                );

                            })}
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    return (

        <DefaultPageContainer customBackgroundColor={!loading && step == 'takeShot' ? 'black' : ''}>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={() => {
                        step == "listPeople" ? setStep("takeShot") : goBack();
                    }}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate('PersonOfInterest')}</Text>
                    </View>
                </View>
            </View>
            {
                loading ?
                    <View style={styles.loadingView}>
                        <Text style={styles.loadingText}>{translate('processing')}</Text>
                        <ActivityIndicator
                            animating={loading}
                            style={styles.loading}
                            size="large"
                            color="#0071bc"
                        >
                        </ActivityIndicator>
                    </View>
                    :
                    <>
                        {step == 'listPeople' ?
                            <>
                                {peopleOfInterest.length ?

                                    <ScrollView
                                        bounces={false}
                                        style={[styles.scrollView, { height: 1 }]}
                                    >
                                        <FlatList
                                            style={[styles.scrollView, { height: 1 }]}
                                            bounces={false}
                                            data={peopleOfInterest}
                                            keyExtractor={(item) => item.id.toString()}
                                            renderItem={renderItem}
                                            removeClippedSubviews={true}
                                            updateCellsBatchingPeriod={100}
                                            windowSize={12}
                                            ItemSeparatorComponent={ItemSeparator}
                                        />
                                    </ScrollView>

                                    :
                                    <View style={styles.loadingView}>
                                        <Text style={styles.loadingText}>{'Nenhuma pessoa encontrada'}</Text>
                                    </View>

                                }
                            </>
                            :
                            <View style={styles.fadeViewCamera}>
                                <Webcam
                                    allowFullScreen
                                    audio={false}
                                    height={'100%'}
                                    screenshotFormat="image/jpeg"
                                    width={'100%'}
                                    videoConstraints={videoConstraints}
                                >
                                    {({ getScreenshot }) => (
                                        <View style={styles.footer}>
                                            <TouchableOpacity onPress={
                                                () => {
                                                    const image = getScreenshot();
                                                    if (image) {
                                                        facialDetect(image);
                                                    }
                                                }}>
                                                <Icon
                                                    name='radio-button-on'
                                                    color='#fff'
                                                    size={100}
                                                />
                                            </TouchableOpacity>
                                        </View>
                                    )
                                    }
                                </Webcam>
                            </View>
                        }
                    </>
            }
        </DefaultPageContainer >

    );
}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    itemContainer: {
        flexDirection: 'row',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: 144,
        marginVertical: 10,
        paddingLeft: 20,
    },
    itemContainerUsersInfo: {
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: "column",
        gap: 2
    },
    title: {
        fontSize: 20,
    },
    subTitle: {
        fontSize: 12,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    scrollView: {
        width: "100%",
        flex: 1
    },
    loading: {
        marginHorizontal: 'auto',
        transform: [{ scaleX: 2 }, { scaleY: 2 }],
        width: '20%',
        height: '20%',
    },
    loadingText: {
        color: 'black',
        textAlign: 'center',
        fontSize: 16
    },
    loadingView: {
        display: 'flex',
        flexDirection: 'column',
        marginVertical: 'auto',
        justifyContent: 'space-between',
        height: 150
    },
    footer: {
        position: 'absolute',
        bottom: 80,
        left: '50%',
        transform: [{ translateX: -65 }],
        justifyContent: 'center',
        alignItems: 'center',
        height: 130,
        width: 130,
        borderRadius: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
    },
    itemContainerInfo: {
        width: "98%",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    iconContainer: {
        justifyContent: "center",
        marginRight: 5,
        width: 144,
        height: 144,
    },
    image: {
        width: '100%',
        height: 144,
        borderRadius: 20
    },
    tagContainer: {
        flexWrap: 'wrap',
        backgroundColor: '#f0f0f0',
        borderRadius: 10,
        paddingVertical: 4,
        paddingHorizontal: 8,
        marginRight: 4,
        marginTop: 4,
    },
    tagText: {
        fontSize: 12,
        color: '#fff',
    },
    fadeViewCamera: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
