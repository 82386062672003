import React, { useEffect, useState } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    forbiddenAccess,
    CrmGroup,
    groupPermissions,
    createGroupParams
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView,
    TextInput,
} from 'react-native';
import { Switch } from 'react-native-elements';
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface groupFormProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}
interface permissions {
    permission_key: "canSeeCameraDownload" | "canSeeCameraMoviment" | "canSeeCameraPanic" | "canSeeCameraTimeline" | "canSeeFaceRecognitionEvents" | "canSeeLprTab" | "canSeePTZControls" | "canSeePersonDetectionEvents";
}

interface switchItmParams {
    title: string;
    inputKey: "canSeeCameraDownload" | "canSeeCameraMoviment" | "canSeeCameraPanic" | "canSeeCameraTimeline" | "canSeeFaceRecognitionEvents" | "canSeeLprTab" | "canSeePTZControls" | "canSeePersonDetectionEvents";
    callback?: (newValue?: boolean) => void;
}

export default function CrmGroupsForm({ navigation, route, authenticatedUser }: groupFormProps) {
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const params = route.params;
    const [readOnly, setReadOnly] = useState(false);
    const [group, setGroup] = useState<CrmGroup>();
    const [showPermissions, setShowPermissions] = useState(true);
    const [valid, setValid] = useState(false);
    const [inputs, setInputs] = useState<createGroupParams>({
        title: "",
        observation: "",
        userDefault: false,
        hasChat: false,
        permissions: {
            canSeeCameraDownload: true,
            canSeeCameraMoviment: false,
            canSeeCameraPanic: false,
            canSeeCameraTimeline: true,
            canSeeFaceRecognitionEvents: false,
            canSeeLprTab: false,
            canSeePTZControls: false,
            canSeePersonDetectionEvents: false,
        },
    });

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    useEffect(() => {
        loadGroup();

    }, [navigation]);

    const validate = (text: string) => {
        setInputs({ ...inputs, title: text });
        if (text !== "") {
            setValid(true);
        } else {
            setValid(false);
        }

    };

    async function save() {
        try {
            setValid(false);
            if (group) {
                await platformAPI.updateCrmGroup({ ...inputs, id: group.id });
            } else {
                await platformAPI.AddGroup(inputs);
            }
            goBack();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Groups");
                return;
            }
            console.error(err);
        } finally {
            setValid(true);
        }
    }

    async function loadGroup() {
        if (!params) return;
        try {
            const groupRequest = await platformAPI.getCrmGroup(params.id);
            const permissions: groupPermissions = {};

            for (const permission of groupRequest.GroupPermissions) {
                permissions[(permission as permissions).permission_key] = true;
            }

            setInputs({ ...groupRequest, permissions: permissions });

            setGroup(groupRequest);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            if (!authenticatedUser.permissions.can_edit_camera_group) {
                if (!authenticatedUser.permissions.can_see_camera_group_tab) {
                    navigation.navigate("Groups");
                }
                setReadOnly(true);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Groups");
    }

    function SwitchItem({ title, inputKey, callback }: switchItmParams) {
        const inputValue = inputs.permissions[inputKey];
        return (
            <View style={styles.switchItem}>
                <Text style={styles.switchItemText}>{translate(title)}</Text>
                <Switch
                    trackColor={{ false: '#767577', true: '#ADBCF9' }}
                    color={inputValue ? '#5a7bf4' : '#f4f3f4'}
                    onValueChange={() => {
                        inputs.permissions[inputKey] = !inputValue;
                        if (callback) callback(inputs.permissions[inputKey]);
                        forceUpdate();
                    }}
                    disabled={readOnly}
                    value={inputValue}
                />
            </View>
        );
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("moreGroups")}</Text>
                    </View>
                </View>
                <View style={styles.registerView}>
                    {!readOnly ? valid || group ?
                        <TouchableOpacity onPress={() => save()}>
                            <Text style={styles.register}>{translate("save")}</Text>
                        </TouchableOpacity>
                        :
                        <Text style={styles.registerInvalid}>{translate("save")}</Text>
                        : null
                    }
                </View>
            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={[styles.inputTextView, styles.titleInput]}>
                    <Text style={styles.inputTextTitle}>{translate("textTitle")}</Text>
                    <TextInput
                        style={[(!readOnly ? styles.textInputs : styles.inputsDisabled), styles.textInputsTitle]}
                        value={inputs.title}
                        onChangeText={(text) => validate(text)}
                        editable={!readOnly}
                    />
                </View>
                <View style={styles.switchItem}>
                    <Text style={styles.switchItemText}>{translate("groupForm_chat")}</Text>
                    <Switch
                        trackColor={{ false: '#767577', true: '#ADBCF9' }}
                        color={inputs.hasChat ? '#5a7bf4' : '#f4f3f4'}
                        onValueChange={() => setInputs({ ...inputs, hasChat: !inputs.hasChat })}
                        value={inputs.hasChat}
                        disabled={readOnly}
                    />
                </View>
                <View style={styles.switchItem}>
                    <Text style={styles.switchItemText}>{translate('defaultGroup')}</Text>
                    <Switch
                        trackColor={{ false: '#767577', true: '#ADBCF9' }}
                        color={inputs.userDefault ? '#5a7bf4' : '#f4f3f4'}
                        onValueChange={() => setInputs({ ...inputs, userDefault: !inputs.userDefault })}
                        value={inputs.userDefault}
                        disabled={readOnly}
                    />
                </View>
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions(!showPermissions)}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("permissions").toLocaleUpperCase()}</Text>
                </TouchableOpacity>
                {showPermissions ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="groupForm_viewTimeline"
                            inputKey="canSeeCameraTimeline"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canSeeCameraDownload = false;
                                }
                            }} />
                        <SwitchItem
                            title="groupForm_VideDownloadButton"
                            inputKey="canSeeCameraDownload"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTimeline = true;
                                }
                            }} />
                        <SwitchItem title="groupForm_enableAlertNotification" inputKey="canSeeCameraPanic" />
                        <SwitchItem title="groupForm_enableMotionNotification" inputKey="canSeeCameraMoviment" />
                        <SwitchItem title="groupForm_viewPTZ" inputKey="canSeePTZControls" />
                        <SwitchItem title="readPlate" inputKey="canSeeLprTab" />
                        <SwitchItem title="groupForm_viewFacialAlert" inputKey="canSeeFaceRecognitionEvents" />
                        <SwitchItem title="groupForm_viewPeopleAlert" inputKey="canSeePersonDetectionEvents" />
                    </View>
                    : null}
                <View style={styles.inputTextView}>
                    <Text style={styles.inputTextTitle}>{translate("observations")}</Text>
                    <TextInput
                        style={[(!readOnly ? styles.textInputs : styles.inputsDisabled), styles.observations]}
                        value={inputs.observation}
                        onChangeText={(text) => {
                            setInputs({ ...inputs, observation: text });
                        }}
                        multiline={true}
                        editable={!readOnly}
                    />
                </View>
            </ScrollView>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    titleInput: {
        marginBottom: 10,
    },
    inputTextTitle: {
        marginLeft: 10,
        marginBottom: 3,
    },
    inputTextView: {
        marginTop: 15,
        width: '100%',
    },
    permissions: {
        width: "100%",

    },
    observations: {
        textAlignVertical: "top",
        height: 120,
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginBottom: 10,
    },
    switchItemText: {
        fontSize: 14,
        paddingEnd: 20,
    },
    switchItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textInputsTitle: {
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        color: '#000',
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        color: '#9a9a9a',
        cursor: 'default'
    },

    viewIcon: {
        margin: 0,
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
    },
    titleDropDown: {
        fontSize: 16,
    },
    dropDown: {
        width: "100%",
        height: "30px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },


    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    scrollContainer: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 60px)',

    },

    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
});

