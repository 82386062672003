import React, { useState, useEffect, useCallback } from "react";
import {
    View,
    StyleSheet,
    FlatList,
    Text,
    TouchableOpacity
} from 'react-native';
import { translate } from "../services/service-translate";
import { ActivityIndicator, List } from 'react-native-paper';
import { UserData, platformAPI, invalidBearerToken } from "../services/platform-api";
import { useFocusEffect } from '@react-navigation/native';
import DefaultPageContainer from "../components/DefaultPageContainer";

interface ListProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    color?: string;
    hideArrow?: boolean;
}
interface MoreComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function More({ navigation, authenticatedUser }: MoreComponentProps) {
    const [loading, setLoading] = useState(true);
    const [canSeeLpr, setCanSeeLpr] = useState(false);
    const [canSeeUserTab, setCanSeeUserTab] = useState(false);
    const [disableLogout, setDisableLogout] = useState(false);
    const [canImpersonate, setCanImpersonate] = useState(false);
    const [canSeeCameraTab, setCanSeeCameraTab] = useState(false);
    const [canCreateCam, setCanCreateCam] = useState(false);
    const [canSeePermissionLevels, setCanSeePermissionLevels] = useState(false);
    const [canSeeMarketingKit, setCanSeeMarketingKit] = useState(false);
    const [canSeePlatformPaymentInfo, setCanSeePlatformPaymentInfo] = useState(false);
    const [canSeeLprBlacklist, setCanSeeLprBlacklist] = useState(false);
    const [hasLprGroups, setHasLprGroups] = useState(false);
    const [privatePreviewLprGroups, setPrivatePreviewLprGroups] = useState(false);

    const [canSeeGroup, setCanSeeGroup] = useState(false);
    const [wlId, setWlId] = useState<number>();

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    async function getLprGroups() {
        try {
            const lprGroups = await platformAPI.getBlacklistGroups({ page: 1 });
            setHasLprGroups(lprGroups.length > 0);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (privatePreviewLprGroups) {
            getLprGroups();
        }
    }, [privatePreviewLprGroups]);

    useEffect(() => {
        document.body.style.position = 'fixed';
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        setLoading(true);
        try {
            if (!authenticatedUser) return;

            const wl = await platformAPI.getWhitelabel();
            const privatePreview = await platformAPI.getPrivatePreview();
            const blacklist_by_group = Object.hasOwn(privatePreview, 'BLACKLIST_BY_GROUP');

            setWlId(wl.id);
            setCanSeeLpr(Object.values(authenticatedUser.permissions.cameras).some(permission => permission?.can_see_lpr_tab));
            setCanSeeUserTab(authenticatedUser.permissions.can_see_user_tab);
            setCanImpersonate(authenticatedUser.permissions.can_impersonate_users);
            setCanSeeGroup(authenticatedUser.permissions.can_see_camera_group_tab);
            setCanSeeCameraTab(authenticatedUser.permissions.can_see_camera_tab);
            setCanCreateCam(authenticatedUser.permissions.can_create_camera);
            setCanSeePermissionLevels(authenticatedUser.permissions.can_see_permission_levels_tab);
            setCanSeeMarketingKit(authenticatedUser.permissions.can_see_marketing_kit);
            setCanSeePlatformPaymentInfo(authenticatedUser.permissions.can_see_platform_payment_info);
            setCanSeeLprBlacklist(authenticatedUser.permissions.can_see_lpr_blacklist);
            setPrivatePreviewLprGroups(blacklist_by_group);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function logout() {
        setDisableLogout(true);
        await platformAPI.signOut();
        window.location.href = '/cameras';
    }

    const options: ListProps[] = [];

    options.push({
        title: translate('userData'),
        onPress: () => navigation.navigate('UserData')
    });

    options.push({
        title: translate('configs'),
        onPress: () => navigation.navigate('Settings')
    });

    if (wlId == 1 && canImpersonate) {
        options.push({
            title: translate('impersonateTitle'),
            onPress: () => navigation.navigate('Impersonate')
        });
    }
    if (wlId != 1 && canSeeUserTab) {
        options.push({
            title: translate('users'),
            onPress: () => navigation.navigate('CrmUserList')
        });
    }
    if (wlId != 1 && (canCreateCam || canSeeCameraTab)) {
        options.push({
            title: translate('cameras'),
            onPress: () => navigation.navigate('CrmCameraList')
        });
    }
    if (wlId != 1 && canSeeGroup) {
        options.push({
            title: translate('groupsTitle'),
            onPress: () => navigation.navigate('Groups')
        });
    }
    if (wlId != 1 && canSeePermissionLevels) {
        options.push({
            title: translate('userPermissionLevels'),
            onPress: () => navigation.navigate('CrmPermissionLevels')
        });
    }

    if (canSeeLpr) {
        options.push({
            title: translate('readPlate'),
            onPress: () => navigation.navigate('LPR')
        });
    }

    options.push({
        title: translate('paymentsHistory'),
        onPress: () => navigation.navigate('HistoryPayments')
    });

    if (wlId != 1 && canSeePlatformPaymentInfo) {
        options.push({
            title: translate('platformCharges'),
            onPress: () => navigation.navigate('CrmChargesList')
        });
    }

    if (canSeeLprBlacklist || hasLprGroups) {
        options.push({
            title: "Blacklist",
            onPress: () => privatePreviewLprGroups ? navigation.navigate('LprGroups') : navigation.navigate('Blacklist')
        });
    }

    if (wlId != 1 && canSeeMarketingKit) {
        options.push({
            title: translate('marketingKit'),
            onPress: () => navigation.navigate('MarketingKit')
        });
    }

    if (wlId == 1 && authenticatedUser?.permissions.can_see_investigative_map && authenticatedUser?.permissions.can_access_horus_requests) {
        options.push({
            title: translate('SearchPersonOfInterest'),
            onPress: () => navigation.navigate('PersonsOfInterestList')
        });
    }

    options.push(
        {
            title: translate('listTerms'),
            onPress: () => navigation.navigate('Terms')
        },
        {
            title: translate('policy'),
            onPress: () => navigation.navigate('Policy')
        }
    );

    options.push({
        title: translate('logout'),
        onPress: logout,
        disabled: disableLogout,
        color: disableLogout ? "#ed6b8d" : "#f03667",
        hideArrow: true
    });

    const renderItem = ({ item }: { item: ListProps; }) => (
        <View style={styles.viewButton}>
            <TouchableOpacity style={styles.button} onPress={item.onPress} disabled={item.disabled}>
                <View style={styles.button}>
                    <Text style={[styles.textButton, { color: item.color || '#7a8084' }]}>{item.title}</Text>
                    {!item.hideArrow ?
                        <View style={styles.viewIcon}>
                            <List.Icon icon="chevron-right" color="#b5b5b5" />
                        </View>
                        : null
                    }
                </View>
            </TouchableOpacity>
        </View>
    );

    return (
        <DefaultPageContainer excludeBottomPadding={true}>
            {loading ?
                <View style={styles.loadingView}>
                    <Text style={styles.loadingText}>{translate('loading')}</Text>
                    <ActivityIndicator
                        animating={loading}
                        style={styles.loading}
                        size="large"
                        color="#0071bc"
                    >
                    </ActivityIndicator>
                </View>
                :
                <>
                    <View style={styles.header}>
                        <View style={styles.titleView}>
                            <Text style={styles.title}>{translate('moreOptions')}</Text>
                        </View>
                    </View>
                    <View style={styles.viewList}>
                        <FlatList
                            bounces={false}
                            data={options}
                            renderItem={renderItem}
                            keyExtractor={(item) => item.title}
                            style={styles.optionList}
                        />
                    </View>
                </>}
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: '#fff'
    },
    header: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleView: {
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        marginLeft: 13,
    },
    title: {
        alignSelf: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontWeight: '500',
        fontSize: 26
    },
    viewList: {
        flex: 1
    },
    viewLogout: {
        alignSelf: 'center'
    },
    textLogout: {
        fontSize: 20
    },
    optionList: {
        padding: 0,
    },
    viewButton: {
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#cecece',
        flexDirection: 'row',
    },
    button: {
        flexDirection: 'row',
        width: "100%",
        height: 40,
        alignItems: 'center'
    },
    textButton: {
        fontSize: 20,
        color: '#7a8084',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15
    },
    viewIcon: {
        flex: 1,
        justifyContent: "center",
        alignItems: 'flex-end',
        marginRight: -20,
    },
    iconRight: {
        alignSelf: 'flex-end'
    },
    loading: {
        marginHorizontal: 'auto',
        transform: [{ scaleX: 2 }, { scaleY: 2 }],
        width: '20%',
        height: '20%',
    },
    loadingText: {
        color: 'black',
        textAlign: 'center',
        fontSize: 16
    },
    loadingView: {
        display: 'flex',
        flexDirection: 'column',
        marginVertical: 'auto',
        justifyContent: 'space-between',
        height: 150
    }
});
