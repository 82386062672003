
import React, { useEffect, useRef } from 'react';

interface ImagePlayerProps {
    readonly src: string;
}

export default function ImagePlayer({ src }: ImagePlayerProps) {

    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const image = imageRef.current;

        if (!image) {
            return;
        }

        if (src == "") {
            image.style.opacity = "0";
            return;
        }

        image.src = src;
        image.style.opacity = "100%";
    }, [src]);

    return (
        <>
            <img src='' ref={imageRef}
                style={{ position: 'absolute', width: '100%', height: '100%', opacity: '0', zIndex: -1 }} loading="lazy"></img>
        </>
    );
}

export { ImagePlayer };
