import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, Image, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { translate } from '../../services/service-translate';
import { UserData as UserDataInterface, platformAPI, invalidBearerToken, ClientError, forbiddenAccess, WhitelabelSignupField, NotFound, UnexpectedError, ZipCode } from '../../services/platform-api';
import { TextInputMask } from 'react-native-masked-text';
import { ActivityIndicator, List } from 'react-native-paper';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import Toast from "react-native-toast-message";
import { useLinkTo } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { cnpjValidation, cpfValidation } from '../../services/user-validations';
import DropDownPicker from 'react-native-dropdown-picker';

interface UserDataComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserDataInterface;
    route?: {
        params: {
            token?: string,
            wlslug: string;
        };
    };
}
enum UserType {
    Individual = 0,
    Company = 1
}

const headerSize = 60;
const footerSize = 30;

export default function UserData({ navigation, authenticatedUser, route }: UserDataComponentProps) {
    const cepRef = useRef<TextInputMask>(null);
    const cpfRef = useRef<TextInputMask>(null);
    const cnpjRef = useRef<TextInputMask>(null);
    const numRef = useRef<TextInputMask>(null);
    const params = route?.params;
    const insets = useSafeAreaInsets();
    const [dropDown, setDropDown] = useState(false);
    const [viewHeight, setViewHeight] = useState(0);
    const [cepValidError, setCepValidError] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);
    const [cpfValidError, setCpfValidError] = useState(false);
    const [cnpjValidError, setCnpjValidError] = useState(false);
    const [userType, setUserType] = useState<UserType>(UserType.Individual);
    const [isInvite, setIsInvite] = useState(false);
    const [isDemoInvite, setIsDemoInvite] = useState(false);
    const [passwordValidError, setPasswordValidError] = useState(false);
    const [passwordConfirmationValidError, setPasswordConfirmationValidError] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [cep, setCep] = useState<ZipCode>();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [cpf, setCpf] = useState<string | undefined>(undefined);
    const [cnpj, setCnpj] = useState<string | undefined>(undefined);
    const [userCep, setUserCep] = useState<string | undefined>(undefined);
    const [userState, setUserState] = useState<string | undefined>(undefined);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [street, setStreet] = useState<string | undefined>(undefined);
    const [streetNumber, setStreetNumber] = useState<string | undefined>(undefined);
    const [complement, setComplement] = useState<string | undefined>(undefined);
    const [district, setDistrict] = useState<string | undefined>(undefined);
    const [focus, setFocus] = useState(false);
    const [whiteLabelSingupFields, setWhiteLabelSingupFields] = useState<WhitelabelSignupField>();
    const linkTo = useLinkTo();

    function handleValidCpf() {
        if (!cpf) {
            return setCpfValidError(true);
        }
        if (!cpfValidation(cpf)) {
            Toast.show({
                type: 'error',
                text1: translate('INVALID_CPF'),
            });
            return setCpfValidError(true);
        }
        return setCpfValidError(false);
    }

    function handleValidCnpj() {
        if (!cnpj) {
            return setCnpjValidError(true);
        }
        if (!cnpjValidation(cnpj)) {
            Toast.show({
                type: 'error',
                text1: translate('INVALID_CNPJ'),
            });
            return setCnpjValidError(true);
        }
        return setCnpjValidError(false);
    }

    async function signupFieldsInvite() {
        try {
            setLoading(true);
            if (!params || (!isInvite && !isDemoInvite)) return;
            const fields = await platformAPI.getWhitelabelSingupFieldsWithSlug(params.wlslug);
            setWhiteLabelSingupFields(fields);
            return;
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    async function getUserData() {

        try {
            if (!authenticatedUser) {
                return;
            }
            setLoading(true);
            const fields = await platformAPI.getWhitelabelSingupFields();
            setWhiteLabelSingupFields(fields);
            setName(authenticatedUser.name);
            setEmail(authenticatedUser.email);
            setPhone(authenticatedUser.phone);
            setCpf(authenticatedUser.cpf);
            setCnpj(authenticatedUser.cnpj);
            setUserCep(authenticatedUser.cep);
            setUserState(authenticatedUser.state);
            setCity(authenticatedUser.city);
            setStreet(authenticatedUser.street);
            setStreetNumber(authenticatedUser.streetNumber);
            setComplement(authenticatedUser.complement);
            setDistrict(authenticatedUser.district);
            if (!authenticatedUser.cnpj) {
                setUserType(UserType.Individual);
                return;
            }
            if (!authenticatedUser.cpf) {
                setUserType(UserType.Company);
                return;
            }

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate('UNEXPECTED_ERROR'),
                });
                return;
            }
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    async function save() {
        try {
            setLoading(true);
            if (params && isInvite) {
                if (!params.token) return;
                await platformAPI.signupByLink({
                    name: name,
                    email: email,
                    password: password,
                    phone: phone,
                    cep: userCep,
                    city: city,
                    complement: complement,
                    cpf: cpf,
                    cnpj: cnpj,
                    district: district,
                    state: userState,
                    street: street,
                    streetNumber: streetNumber,
                }, params.wlslug, params.token);
                Toast.show({
                    type: 'success',
                    text1: translate("UserCreatedSuccessfully"),
                });
                console.log(`navigating to ${process.env.PUBLIC_URL}/cameras`);
                setTimeout(() => {
                    window.location.href = `${process.env.PUBLIC_URL}/cameras`;
                }, 3000);
                return;

            } else if (params && isDemoInvite) {
                await platformAPI.signupDemoByLink({
                    name: name,
                    email: email,
                    password: password,
                    phone: phone,
                    cep: userCep,
                    city: city,
                    complement: complement,
                    cpf: cpf,
                    cnpj: cnpj,
                    district: district,
                    state: userState,
                    street: street,
                    streetNumber: streetNumber,
                }, params.wlslug);
                Toast.show({
                    type: 'success',
                    text1: translate("UserCreatedSuccessfully"),
                });
                console.log(`navigating to ${process.env.PUBLIC_URL}/cameras`);
                setTimeout(() => {
                    window.location.href = `${process.env.PUBLIC_URL}/cameras`;
                }, 3000);
                return;
            } else {
                if (!authenticatedUser) return;
                await platformAPI.setUserData({
                    name: name,
                    email: email,
                    phone: phone,
                    cep: userCep,
                    city: city,
                    complement: complement,
                    cpf: cpf,
                    cnpj: cnpj,
                    district: district,
                    state: userState,
                    street: street,
                    streetNumber: streetNumber,
                });
            }
            setFocus(false);
            const state = navigation.getState();
            if (state.routeNames.includes('More')) {
                navigation.navigate('More');
                return;
            }
            navigation.navigate('Camera');

        } catch (err) {
            console.error(err);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            if (err instanceof NotFound) {
                Toast.show({
                    type: 'error',
                    text1: translate("NOT_FOUND"),
                });
                return;
            }
            if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate('UNEXPECTED_ERROR'),
                });
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    function handleValidCep(val: string) {

        if (val.length === 9) {
            setCepValidError(false);
            return;
        }
        if (val.length === 0) {
            setCepValidError(false);
            return;
        }
        setCepValidError(true);
    }

    function handleDropdownSelected() {
        if (Number(userType) == UserType.Individual) {
            setUserType(UserType.Individual);
            setCnpj(undefined);
            setCnpjValidError(false);
            return;
        }

        setUserType(UserType.Company);
        setCpf(undefined);
        setCpfValidError(false);
    }

    async function loadZipCode() {
        if (!cepValidError) {
            try {
                setLoading(true);
                const zipCode = await platformAPI.getZipCode(userCep);
                if (!zipCode) {
                    return;
                }
                setCep(zipCode);
            } catch (err) {
                if (err instanceof Error) {
                    Toast.show({
                        type: 'error',
                        text1: translate('invalidZipCode'),
                    });
                }
            } finally {
                setLoading(false);
            }
            return;
        }
    }

    async function handleValidEmail() {
        try {
            if (!email) {
                return setEmailValidError(false);
            }
            setLoading(true);
            const reg = new RegExp(/^[a-zA-Z0-9-_-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);

            if (!reg.test(email)) {
                setEmailValidError(true);
                Toast.show({
                    type: 'error',
                    text1: translate("invalidEmail"),
                });
                return;
            }
            const validEmail = await platformAPI.checkEmail(email);
            if (!validEmail) {
                setEmailValidError(true);
                Toast.show({
                    type: 'error',
                    text1: translate("emailAlreadyRegistered"),
                });
                return;
            }
            setEmailValidError(false);
        } catch (err) {
            if (err instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            if (err instanceof Error) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    function validatePassword() {
        if ((isInvite || isDemoInvite) && (!password || !passwordConfirmation || passwordConfirmationValidError || passwordValidError)) {
            return false;
        }
        return true;
    }

    function checkValidPassword(text?: string) {
        const inputPasswordConfirmation = text ? text : password;

        const regex = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z]).*$/;
        if (inputPasswordConfirmation && regex.test(inputPasswordConfirmation)) {
            setPasswordValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'longError',
                    text1: translate('invalidPassword'),
                });
            }
            setPasswordValidError(true);
            return false;
        }
    }

    function checkValidPasswordConfirmation(text?: string) {
        const inputPassword = text ? text : passwordConfirmation;

        if (inputPassword && inputPassword === password) {
            setPasswordConfirmationValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'error',
                    text1: translate('invalidPasswordConfirmation')
                });
            }
            setPasswordConfirmationValidError(true);
            return false;
        }
    }

    useEffect(() => {
        if (params?.token) {
            setIsInvite(true);
            setIsDemoInvite(false);
            return;
        } else if (params?.wlslug && !params.token) {
            setIsDemoInvite(true);
            setIsInvite(false);
            return;
        }
    }, [params]);

    useEffect(() => {
        if (cep == undefined) {
            return;
        }
        setCity(cep?.localidade);
        setUserState(cep?.uf);
        setStreet(cep?.logradouro);
        setDistrict(cep?.bairro);
    }, [cep]);

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');

        if (authenticatedUser && (isInvite || isDemoInvite)) {
            return linkTo('/cameras');
        }
        if (authenticatedUser) {
            getUserData();
            return;
        }
        if (isDemoInvite) {
            navigation.navigate('DemoInvite', params);
            signupFieldsInvite();
            return;
        }
        if (isInvite) {
            navigation.navigate('Invite', params);
            signupFieldsInvite();
            return;
        }

    }, [authenticatedUser, isInvite, isDemoInvite]);

    useEffect(() => {

        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    async function goBack() {

        if (!checkIfUserHasRequiredFields() || !authenticatedUser) {
            await platformAPI.signOut();
            navigation.navigate('Authentication');
            return;
        }

        setName(authenticatedUser.name);
        setEmail(authenticatedUser.email);
        setPhone(authenticatedUser.phone);
        setCpf(authenticatedUser.cpf);
        setCnpj(authenticatedUser.cnpj);
        setUserCep(authenticatedUser.cep);
        setUserState(authenticatedUser.state);
        setCity(authenticatedUser.city);
        setStreet(authenticatedUser.street);
        setStreetNumber(authenticatedUser.streetNumber);
        setComplement(authenticatedUser.complement);
        setDistrict(authenticatedUser.district);

        const state = navigation.getState();
        if (state.routeNames.includes('More')) {
            navigation.navigate('More');
            return;
        }

        navigation.navigate('Camera');

    }

    function checkIfUserHasRequiredFields() {
        if (!whiteLabelSingupFields) {
            return false;
        }

        if (whiteLabelSingupFields.signup_fields.signup_phone == 'REQUIRED' && !phone) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_name == 'REQUIRED' && !name) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_email == 'REQUIRED' && !email) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_cpf == 'REQUIRED' && !cpf &&
            !cnpj) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_cep == 'REQUIRED' && !userCep) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_state == 'REQUIRED' && !userState) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_city == 'REQUIRED' && !city) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_street == 'REQUIRED' && !street) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_neighborhood == 'REQUIRED' && !district) {
            return false;
        }
        if (whiteLabelSingupFields.signup_fields.signup_number == 'REQUIRED' && !streetNumber) {
            return false;
        }
        if (cepValidError &&
            !(cpfValidError && cnpjValidError) &&
            emailValidError) {
            return false;
        }
        return true;
    }

    return (
        <DefaultPageContainer>
            {focus && checkIfUserHasRequiredFields() && validatePassword() ?
                <View style={styles.saveHeader}>
                    <View style={styles.leftView}>
                        <TouchableOpacity onPress={() => setFocus(false)}>
                            <Image style={styles.imageClose} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_close.png' }} />
                        </TouchableOpacity>
                    </View>

                    <View style={styles.rightView}>
                        <TouchableOpacity onPress={() => save()}>
                            <Text style={styles.saveText}>{translate('save')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={styles.header}>
                    <View style={styles.backButtonView}>
                        <TouchableOpacity style={styles.backButton} onPress={goBack}>
                            <List.Icon icon='arrow-left' color="#fff" />
                        </TouchableOpacity>
                        {!checkIfUserHasRequiredFields() ?
                            (isInvite || isDemoInvite ?
                                <Text style={styles.titleHeader}>{translate("registerUser")} </Text>
                                :
                                <Text style={styles.titleHeader}>{translate("BeforeGoing")} </Text>
                            ) : (
                                <Text style={styles.titleHeader}>{translate('userData')} </Text>
                            )}
                    </View>
                </View>
            }

            <ScrollView
                bounces={false}
                style={{ height: viewHeight, width: '100%', padding: 10 }}
            >
                {whiteLabelSingupFields?.signup_fields.signup_name == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("name")}
                        {whiteLabelSingupFields?.signup_fields.signup_name == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setName(value); setFocus(true);
                        }}
                        value={name}
                    />
                </>
                }
                {whiteLabelSingupFields?.signup_fields.signup_email == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("email")}
                        {whiteLabelSingupFields?.signup_fields.signup_email == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={!emailValidError ? styles.textInputs : styles.invalidTextInputs}
                        onBlur={handleValidEmail}
                        onChangeText={(value) => {
                            setEmail(value); setFocus(true);
                        }}
                        value={email}
                    />
                </>
                }

                {
                    isInvite || isDemoInvite ?
                        <>
                            <Text style={styles.labelText}>{translate('password')}</Text>
                            <TextInput
                                style={!passwordValidError ? styles.textInputs : styles.invalidTextInputs}
                                secureTextEntry
                                onBlur={() => checkValidPassword()}
                                value={password}
                                onChangeText={(text) => {
                                    setPassword(text); checkValidPassword(text);
                                }}
                            />
                            <Text style={styles.labelText}>{translate('passwordConfirm')}</Text>
                            <TextInput
                                style={!passwordConfirmationValidError ? styles.textInputs : styles.invalidTextInputs}
                                onBlur={() => checkValidPasswordConfirmation()}
                                secureTextEntry
                                value={passwordConfirmation}
                                onChangeText={(text) => {
                                    setPasswordConfirmation(text); checkValidPasswordConfirmation(text);
                                }}
                            />
                        </>
                        : null
                }

                {whiteLabelSingupFields?.signup_fields.signup_phone == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("phoneNumber")}
                        {whiteLabelSingupFields?.signup_fields.signup_phone == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>

                            : null}
                    </Text>
                    <TextInputMask
                        type={'cel-phone'}
                        style={styles.textInputs}
                        options={{
                            maskType: 'BRL',
                            withDDD: true,
                            dddMask: '(99) 9 9999-9999'
                        }}
                        value={phone}
                        onChangeText={(text) => {
                            setPhone(text); setFocus(true);
                        }}
                        maxLength={16}
                        keyboardType='numeric'
                        ref={numRef}
                    />
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_cpf == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate('userType')}</Text>
                    <DropDownPicker
                        open={dropDown}
                        value={userType}
                        items={[
                            { label: translate("individual"), value: UserType.Individual },
                            { label: translate("legalPerson"), value: UserType.Company }
                        ]}
                        style={styles.dropDown}
                        dropDownContainerStyle={styles.dropDownContainerStyle}
                        listItemContainerStyle={styles.drops}
                        onChangeValue={handleDropdownSelected}
                        selectedItemContainerStyle={{ backgroundColor: '#F3F4F5'}}
                        showTickIcon={false}
                        setOpen={setDropDown}
                        setValue={setUserType}
                    />
                    {userType == UserType.Individual ? (
                        <Text style={styles.labelText}>{translate("cpf")}
                            {whiteLabelSingupFields?.signup_fields.signup_cpf == "REQUIRED" ?
                                <Text style={{ color: 'red' }}> *</Text>
                                : null}
                        </Text>
                    ) : (
                        <Text style={styles.labelText}>{translate("cnpj")}
                            {whiteLabelSingupFields?.signup_fields.signup_cpf == "REQUIRED" ?
                                <Text style={{ color: 'red' }}> *</Text>
                                : null}
                        </Text>
                    )}
                    {userType == UserType.Individual ?
                        <TextInputMask
                            type={'cpf'}
                            style={styles.textInputs}
                            onBlur={() => handleValidCpf()}
                            options={{ mask: '999.999.999-99' }}
                            value={cpf}
                            onChangeText={(text) => {
                                setCpf(text); setFocus(true);
                            }}
                            maxLength={14}
                            keyboardType='numeric'
                            ref={cpfRef}
                        /> :
                        <TextInputMask
                            type={'cnpj'}
                            style={styles.textInputs}
                            onBlur={() => handleValidCnpj()}
                            options={{ mask: '99.999.999/9999-99' }}
                            value={cnpj}
                            onChangeText={(text) => {
                                setCnpj(text); setFocus(true);
                            }}
                            maxLength={18}
                            keyboardType='numeric'
                            ref={cnpjRef}
                        />}
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_cep == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("cep")}
                        {whiteLabelSingupFields?.signup_fields.signup_cep == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInputMask
                        onBlur={loadZipCode}
                        type={'zip-code'}
                        style={styles.textInputs}
                        options={{
                            mask: '99.999-999'
                        }}
                        value={userCep}
                        onChangeText={(text) => {
                            setUserCep(text); handleValidCep(text); setFocus(true);
                        }}
                        maxLength={9}
                        keyboardType='numeric'
                        ref={cepRef}
                    />
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_state == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("state")}
                        {whiteLabelSingupFields?.signup_fields.signup_state == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setUserState(value); setFocus(true);
                        }}
                        value={userState}
                    />
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_city == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("city")}
                        {whiteLabelSingupFields?.signup_fields.signup_city == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setCity(value); setFocus(true);
                        }}
                        value={city}
                    />

                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_neighborhood == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("district")}
                        {whiteLabelSingupFields?.signup_fields.signup_neighborhood == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setDistrict(value); setFocus(true);
                        }
                        } value={district}
                    />
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_street == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("street")}
                        {whiteLabelSingupFields?.signup_fields.signup_street == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setStreet(value); setFocus(true);
                        }
                        } value={street}
                    />
                </>
                }

                {whiteLabelSingupFields?.signup_fields.signup_number == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("streetNumber")}
                        {whiteLabelSingupFields?.signup_fields.signup_number == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setStreetNumber(value); setFocus(true);
                        }}
                        value={streetNumber}
                    />
                </>
                }
                {whiteLabelSingupFields?.signup_fields.signup_complement == "DISABLE" ? (
                    null
                ) : <>
                    <Text style={styles.labelText}>{translate("complement")}
                        {whiteLabelSingupFields?.signup_fields.signup_complement == "REQUIRED" ?
                            <Text style={{ color: 'red' }}> *</Text>
                            : null}
                    </Text>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => {
                            setComplement(value); setFocus(true);
                        }}
                        value={complement}
                    />
                </>
                }
            </ScrollView >

            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#000'
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    backButtonView: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    backButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
        alignSelf: 'center'
    },
    saveHeader: {
        alignSelf: 'flex-start',
        width: '100%',
        height: 60,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    leftView: {
        width: "50%",
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    imageClose: {
        width: 30,
        height: 30,
        marginLeft: 10
    },
    rightView: {
        width: "50%",
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    saveText: {
        color: "#fff",
        alignSelf: 'center',
        marginRight: 20,
        fontSize: 18
    },
    title: {
        color: '#7a8084',
        marginLeft: 20,
        fontSize: 16,
    },
    image: {
        width: 120,
        height: 120,
        marginLeft: 20
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        borderWidth: 1,
        width: '100%',
        height: 45,
        color: '#3c3c3c',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    invalidTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        width: '100%',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    labelText: {
        fontSize: 16,
    },
    dropDown: {
        borderColor: "#9a9a9a4f",
        cursor: 'pointer',
        backgroundColor: '#fff',
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        color: '#3c3c3c',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dropDownContainerStyle: {
        borderWidth: 0,
        backgroundColor: '#F9FAFC',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    drops: {
        borderColor: "#9a9a9a4f",
        width: '100%',
        borderWidth: 1,
        borderTopWidth: 0,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    }
});

