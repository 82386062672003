
import * as React from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Modal, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Mustache from 'mustache';
import { TimePickerModal, DatePickerModal } from 'react-native-paper-dates';
import { Countdown } from 'react-native-element-timer';

import VideoPlayer from '../components/VideoPlayer';
import { CameraList, platformAPI, invalidBearerToken, forbiddenAccess, Camera, UserData, Timeline } from "../services/platform-api";
import { translate } from '../services/service-translate';
import CameraTimeline from '../components/CameraTimeline';
import { TimelineRecord, Shot } from '../components/CameraTimeline';
import ImagePlayer from '../components/ImagePlayer';
import moment from 'moment';
import { useNavigationState } from '@react-navigation/native';
import DefaultPageContainer from '../components/DefaultPageContainer';
import Toast from 'react-native-toast-message';
import { List } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';

interface CameraWatchComponentProps {
    navigation: Navigation;
    route: {
        params: CameraList;
    };
    authenticatedUser?: UserData;
}

const THIRTY_MINUTES = 1000 * 60 * 30;

export default function CameraWatch({ navigation, route, authenticatedUser }: CameraWatchComponentProps) {
    const [camera, setCamera] = React.useState<Camera>();
    const videoPlayerRef = React.useRef<VideoPlayer>(null);
    const [timelineRecords, setTimelineRecords] = React.useState<TimelineRecord[]>([]);
    const [isLive, setIsLive] = React.useState(true);
    const [timelineAt, setTimelineAt] = React.useState("");
    const [webRTC, setWebRTC] = React.useState("");
    const [videoPlayer, setVideoPlayer] = React.useState<VideoPlayer>();
    const [day, setDay] = React.useState(translate("TODAY"));
    const [planAmount, setPlanAmount] = React.useState(0);
    const [timelineHeight, setTimelineHeight] = React.useState(0);
    const [timelineWidth, setTimelineWidth] = React.useState(0);
    const [timelineShot, setTimelineShot] = React.useState("");
    const [downloadModal, setDownloadModal] = React.useState(false);
    const [saveName, setSaveName] = React.useState('');
    const [startTimeModal, setStartTimeModal] = React.useState(false);
    const [finishTimeModal, setFinishTimeModal] = React.useState(false);
    const [start, setStart] = React.useState(new Date());
    const [finish, setFinish] = React.useState(new Date());
    const MAX_TIME_DOWNLOAD = 1000 * 60 * 15;
    const [currentTime, setCurrentTime] = React.useState(new Date);
    const [currentDate, setCurrentDate] = React.useState(new Date);
    const [panicPerm, setPanicPerm] = React.useState(false);
    const [panicAnswer, setPanicAnswer] = React.useState(false);
    const countdownRef = React.useRef(null);
    const [endTimer, setEndTimer] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [calendar, setCalendar] = React.useState(false);
    const [currentRecord, setCurrentRecord] = React.useState<TimelineRecord>();
    const [inputTime, setInputTime] = React.useState('');

    const index = useNavigationState(state => state);
    const safeAreaInsets = useSafeAreaInsets();

    function getVideoHeightRatio() {
        return window.innerHeight * .35;
    }

    function getTimelineHeight() {
        // timeline = total - video - timeline_header - timeline_buttons
        return window.innerHeight - getVideoHeightRatio() - 60 - 60;
    }

    function setSource(video: TimelineRecord, videoPlayer: VideoPlayer, time: number) {
        setCurrentRecord(video);
        videoPlayer.showPlaybackRateMenuButton();
        videoPlayer.setSrc(video.videoUrl);
        videoPlayer.resetPlaybackRate();
        videoPlayer.onVideoEnded(() => {
            const index = timelineRecords.indexOf(video) + 1;
            if (index < timelineRecords.length) {
                setTimelineAt(timelineRecords[index].start.toString());
            } else {
                setTimelineAt("[live]");
            }
        });
        const timeDiff = time - video.start;
        videoPlayer.setTimeAt(timeDiff / 1000);
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getCamera();

            document.body.style.position = 'fixed';
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#000000');
        });
        return unsubscribe;
    }, [navigation, route]);

    React.useEffect(() => {
        if (!videoPlayer) {
            return;
        }

        setTimelineShot("");
        videoPlayer.showPlayer();

        if (timelineAt == "[live]") {
            videoPlayer.hidePlaybackRateMenuButton();
            if (camera?.ServerId && camera?.factory == 'UNKNOWN') {
                videoPlayer.cleanSrc();
                videoPlayer.setHls(camera?.stream_url);
            } else {
                videoPlayer.setWebRTC(webRTC);
            }
        } else if (!isNaN(Number(timelineAt))) {
            const time = Number(timelineAt);
            let closestVideo: TimelineRecord | undefined;
            videoPlayer.cleanSrc();

            for (const video of timelineRecords) {
                if (!closestVideo && time >= video.start - THIRTY_MINUTES && time < video.finish) {
                    closestVideo = video;
                }

                if (time >= video.start && time < video.finish) {
                    setSource(video, videoPlayer, time);
                    return;
                }
            }

            if (closestVideo) {
                setSource(closestVideo, videoPlayer, time);
            }

        }

    }, [timelineAt]);

    React.useEffect(() => {
        if (videoPlayer) {
            if (isLive) {
                videoPlayer.srsWebRtc.startRetry();
            } else {
                videoPlayer.srsWebRtc.cancelRetry();
            }
        }
    }, [isLive]);

    React.useEffect(() => {
        if (!videoPlayer) {
            return;
        }

        if (timelineShot == "") {
            return;
        }

        videoPlayer.hidePlayer();

    }, [timelineShot]);

    React.useEffect(() => {

        const videoPlayer = videoPlayerRef.current;
        if (!videoPlayer) {
            return;
        }
        setVideoPlayer(videoPlayer);

        videoPlayer.addBackButton(() => {
            if (index.routes.length > 1) {
                navigation.navigate("Camera");
                return;
            }
            window.location.href = '/cameras';
        }, safeAreaInsets.top);
        videoPlayer.addConfigButton(() => navigation.navigate("CameraNotification", route.params), safeAreaInsets.top);
        videoPlayer.hideProgressBar();
        videoPlayer.hidePlaybackRateMenuButton();

        function handleResize() {
            videoPlayer?.setWidth(window.innerWidth);
            videoPlayer?.setHeight(getVideoHeightRatio());
            setTimelineWidth(window.innerWidth);
            setTimelineHeight(getTimelineHeight());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        sources: [],
        playbackRates: [0.50, 1, 2, 4, 8],
        width: window.innerWidth,
        height: getVideoHeightRatio(),
        responsive: true,
        muted: true,
        errorDisplay: false,
        userActions: {
            doubleClick: false,
        }
    };

    React.useEffect(() => {
        if (start.getTime() < finish.getTime() - MAX_TIME_DOWNLOAD) {
            setFinish(new Date(start.getTime() + MAX_TIME_DOWNLOAD));
        }
        if (start.getTime() > finish.getTime()) {
            setFinish(new Date(start.getTime() + MAX_TIME_DOWNLOAD));
        }

    }, [start]);


    React.useEffect(() => {
        if (finish.getTime() > start.getTime() + MAX_TIME_DOWNLOAD) {
            setStart(new Date(finish.getTime() - MAX_TIME_DOWNLOAD));
        }
        if (finish.getTime() < start.getTime()) {
            setStart(new Date(finish.getTime() - MAX_TIME_DOWNLOAD));
        }

    }, [finish]);

    const getDate = (value: Date) => {
        setCurrentDate(new Date(value.setHours(currentTime.getHours())));
        setCalendar(false);
    };

    async function organizeTimelineRecords(timeline: Timeline) {
        setLoading(true);
        setTimelineRecords(timeline.videos.map((video) => {
            const start: number = parseInt(video[timeline.key_map.startTime]);
            const finish: number = parseInt(video[timeline.key_map.finishTime]);
            let videoUrl: string;

            if (Array.isArray(timeline.video_template)) {
                videoUrl = Mustache.render(timeline.video_template[video.template_ind], video);
            } else {
                videoUrl = Mustache.render(timeline.video_template, video);
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const shots: Shot[] = video[timeline.key_map.shotList].map((shot: any) => {
                if (Array.isArray(timeline.shot_template)) {
                    return {
                        moment: shot[timeline.key_map.shotTime],
                        url: Mustache.render(timeline.shot_template[video.template_ind], shot)
                    };
                } else {
                    return {
                        moment: shot[timeline.key_map.shotTime],
                        url: Mustache.render(timeline.shot_template, shot)
                    };
                }
            });

            return { finish: finish, start: start, videoUrl: videoUrl, shots: shots };
        }).sort((a, b) => a.start - b.start));
    }


    async function getCamera() {
        try {
            const cameraParam = route.params;
            let promise;

            promise = platformAPI.getCamera(cameraParam.id);
            const factory = (await promise).factory;
            if (factory == "QUALVISION") {
                promise = platformAPI.initializeCamera(cameraParam.id);
            }

            setCamera(await promise);

            const timeline = await platformAPI.getTimeline(cameraParam.id);

            // cache mustache
            if (Array.isArray(timeline.shot_template)) {
                for (const shot_template of timeline.shot_template) {
                    Mustache.parse(shot_template);
                }
            } else {
                Mustache.parse(timeline.shot_template);
            }

            if (Array.isArray(timeline.video_template)) {
                for (const video_template of timeline.video_template) {
                    Mustache.parse(video_template);
                }
            } else {
                Mustache.parse(timeline.video_template);
            }

            // parse timeline info
            await organizeTimelineRecords(timeline);

            setLoading(false);

        } catch (err) {
            if (err instanceof forbiddenAccess) {
                navigation.navigate('Camera', { screen: 'Cameras' });
                Toast.show({
                    type: 'error',
                    text1: translate('cameraInvalidAccess'),
                });
                return;
            }

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    React.useEffect(() => {
        if (!camera) {
            return;
        }

        setWebRTC(camera.webrtc_data.wss_address);
        setTimelineAt("[live]");

        if (camera.video_archive_ttl_days) {
            setPlanAmount(camera.video_archive_ttl_days);
        }

        if (camera.Plan?.amount) {
            setPlanAmount(camera.Plan.amount);
        }

    }, [camera]);

    React.useEffect(() => {
        checkPanicPermission();
    }, [camera, authenticatedUser]);

    async function checkPanicPermission() {
        if (!authenticatedUser || !camera) return;

        const cameraParam = route.params;
        const whitelabel = await platformAPI.getWhitelabel();


        const isCameraOwner = camera.UserId == authenticatedUser.id;

        let hasCamerasPermission = false;

        const cameraPermission = authenticatedUser.permissions.cameras[cameraParam.id];

        if (cameraPermission) {
            hasCamerasPermission = cameraPermission.can_use_camera_panic;
        }

        setPanicPerm((isCameraOwner || hasCamerasPermission) && whitelabel.has_panic);

    }

    async function createVideo() {
        const cameraParam = route.params;
        await platformAPI.createVideo({
            title: saveName,
            start: start,
            finish: finish,
            camId: cameraParam.id
        });

        setDownloadModal(false);
        notify();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            createVideo();
        }
    }

    async function getPanicAlert() {
        const cameraParam = route.params;

        try {
            const res = await platformAPI.createPanicAlert(cameraParam.id);

            setPanicAnswer(false);

            if (!res) {
                return;
            }

            alertSuccess();

        } catch (err) {
            console.error(err);
            setPanicAnswer(false);

            if (err instanceof forbiddenAccess) {
                alertPerm();
                return;
            }
        }
    }

    React.useEffect(() => {
        setStart(currentTime);
        setFinish(new Date(currentTime.getTime() + MAX_TIME_DOWNLOAD));
    }, [downloadModal]);

    const notify = () => {
        Toast.show({
            type: 'success',
            text1: translate('videoSuccess'),
        });
    };

    const alertPerm = () => {
        Toast.show({
            type: 'error',
            text1: translate('forbiddenAlert')
        });
    };

    const alertSuccess = () => {
        Toast.show({
            type: 'success',
            text1: translate('successAlert')
        });
    };

    function onScreenShot() {
        const titleWithUnderscores = camera?.title.replaceAll(" ", "_");
        const pictureName = titleWithUnderscores + "_" + moment(currentTime).format("YYYY-MM-DD_HH.mm.ss.SSS");
        videoPlayerRef.current?.takePicture(pictureName);
    }

    function timeSkip(skip: string) {
        setCurrentDate(prevTime => {
            const now = new Date();
            const currentHours = prevTime.getHours();
            const currentMinutes = prevTime.getMinutes();
            const currentSeconds = prevTime.getSeconds();

            const [inputHours, inputMinutes, inputSeconds] = inputTime.split(':').map(Number);

            let newHours = currentHours || 0;
            let newMinutes = currentMinutes || 0;
            let newSeconds = currentSeconds || 0;

            if (skip === '-') {
                newHours -= inputHours || 0;
                newMinutes -= inputMinutes || 0;
                newSeconds -= inputSeconds || 0;
            } else if (skip === '+') {
                newHours += inputHours || 0;
                newMinutes += inputMinutes || 0;
                newSeconds += inputSeconds || 0;
            }

            if (newSeconds < 0) {
                newMinutes -= 1;
                newSeconds += 60;
            }
            if (newMinutes < 0) {
                newHours -= 1;
                newMinutes += 60;
            }
            newHours += Math.floor(newMinutes / 60);
            newMinutes %= 60;
            newMinutes += Math.floor(newSeconds / 60);
            newSeconds %= 60;

            const newTime = new Date(prevTime);
            newTime.setHours(newHours);
            newTime.setMinutes(newMinutes);
            newTime.setSeconds(newSeconds);

            setIsLive(newTime >= now);

            return newTime >= now ? now : newTime;
        });
    }

    return (
        <DefaultPageContainer customBackgroundColor='#3c4049' customTopInsetColor='#000000'>
            <View style={[styles.playerView]}>
                <VideoPlayer {...videoJsOptions} ref={videoPlayerRef} />
                <ImagePlayer src={timelineShot} />
            </View>
            <View style={styles.timelineHeader}>
                <Text style={styles.timelineHeaderText}>{day}</Text>
                <View style={styles.timeSkipInput}>
                    <TouchableOpacity style={styles.skipArrow} onPress={() => timeSkip('-')}>
                        <List.Icon icon="arrow-left" color='#a9a9a9'/>
                    </TouchableOpacity>
                    <TextInputMask
                        type ='custom'
                        options = {{
                            mask: '99:99:99'
                        }}
                        onChangeText={(text) => setInputTime(text)}
                        value = {inputTime}
                        style = {styles.inputSkip}
                        placeholder = '00:00:00'
                        maxLength = {8}
                        keyboardType='numeric'
                    />
                    <TouchableOpacity style={styles.skipArrow} onPress={() => timeSkip('+')}>
                        <List.Icon icon="arrow-right" color='#a9a9a9'/>
                    </TouchableOpacity>
                </View>
                {panicPerm && (
                    <TouchableOpacity style={styles.alertButton} onPress={() => setPanicAnswer(true)}>
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_alert.png' }} style={styles.alertImage} />
                        <Text style={styles.alertText}>{translate('alert')}</Text>
                    </TouchableOpacity>
                )}
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={panicAnswer}
                    onRequestClose={() => setPanicAnswer(!panicAnswer)}
                >
                    <View style={styles.modalContainerPanic} />
                    <View style={styles.modalCenterViewPanic}>
                        <View style={styles.modalBodyPanic}>
                            <View style={styles.alertHeader}>
                                <Text style={styles.alertTitle}>{translate('alertTitle')}</Text>
                            </View>
                            <Text style={styles.alertContainerText}>{translate('alertTextCaution')}</Text>
                            <Text style={styles.alertContainerTextConfirm}>{translate('alertTextConfirm')}</Text>
                            <View style={styles.alertButtonsView}>
                                <TouchableOpacity
                                    style={styles.alertButtons}
                                    onPress={() => {
                                        setPanicAnswer(!panicAnswer), setTimeout(() => setEndTimer(false), 1000);
                                    }}
                                >
                                    <Text style={styles.alertButtonText}>{translate('cancel_caps')}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[styles.alertButtons, { backgroundColor: endTimer ? '#0071bc' : '#7eb8dd' }]}
                                    onPress={() => {
                                        getPanicAlert(), setEndTimer(false);
                                    }}
                                    disabled={endTimer ? false : true}
                                >
                                    <Text style={styles.alertButtonText}>{translate('yes_caps')}
                                        {endTimer ? null : <Text style={styles.alertConfirmParentheses}>(<Countdown
                                            ref={countdownRef}
                                            initialSeconds={3}
                                            textStyle={styles.timerAlert}
                                            autoStart={true}
                                            onEnd={() => {
                                                setEndTimer(true);
                                            }}
                                        />)</Text>}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>
            </View>
            <View style={styles.timelineView}>
                <CameraTimeline
                    timelineRecords={timelineRecords}
                    isLive={isLive}
                    setIsLive={setIsLive}
                    timelineAt={timelineAt}
                    setTimelineAt={setTimelineAt}
                    setDay={setDay}
                    planAmount={planAmount}
                    timelineHeight={timelineHeight}
                    timelineWidth={timelineWidth}
                    setTimelineShot={setTimelineShot}
                    videoPlayer={videoPlayer}
                    currentRecord={currentRecord}
                    setCurrentTime={setCurrentTime}
                    currentDate={currentDate}
                    loading={loading}
                    setLoading={setLoading}
                />
            </View>
            <View style={styles.timelineBottom}>
                <View style={styles.view}>
                    <TouchableOpacity onPress={() => setCalendar(!calendar)}>
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_date_range.png' }} style={styles.icon} />
                        <Text style={styles.text}>{planAmount} {(planAmount == 1 ? translate("day") : translate("days"))}</Text>
                    </TouchableOpacity>
                </View>
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={calendar}
                    onRequestClose={() => {
                        setCalendar(!calendar);
                    }}
                >
                    <View style={styles.modalContainerCalendar} />
                    <View style={styles.modalCenterViewCalendar}>
                        <DatePickerModal
                            locale={translate("lang")}
                            label={translate("date")}
                            mode='single'
                            visible={calendar}
                            validRange={{
                                startDate: new Date(new Date().setDate(new Date().getDate() - planAmount)),
                                endDate: new Date()
                            }}
                            onDismiss={() => setCalendar(!calendar)}
                            date={currentTime}
                            onChange={(d) => {
                                if (!d.date) {
                                    setCalendar(false);
                                    return;
                                }
                                getDate(d.date);
                            }
                            }
                            onConfirm={() => setCalendar(!calendar)}
                        />
                    </View>
                </Modal>
                {isLive ?
                    <View style={styles.view}>
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_ondemand_video_disabled.png' }} style={styles.icon} />
                        <Text style={styles.text}>{translate('live')}</Text>
                    </View>
                    :
                    <View style={styles.view}>
                        <TouchableOpacity onPress={() => setIsLive(true)}>
                            <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_ondemand_video.png' }} style={styles.icon} />
                            <Text style={styles.text}>{translate('goLive')}</Text>
                        </TouchableOpacity>
                    </View>
                }
                <View style={styles.view}>
                    <TouchableOpacity onPress={() => setDownloadModal(!downloadModal)}>
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_video_library.png' }} style={styles.icon} />
                        <Text style={styles.text}>{translate('saveVideo')}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.view}>
                    <TouchableOpacity onPress={onScreenShot}>
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_image.png' }} style={styles.icon} />
                        <Text style={styles.text}>{translate('screenshot')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Modal
                visible={downloadModal}
                animationType="fade"
                transparent={true}
                onRequestClose={() => {
                    setDownloadModal(!downloadModal);
                }}
            >
                <View style={styles.modalContainerDownload} />
                <View style={styles.modalCenterViewDownload}>
                    <View style={styles.modalBodyDownload}>
                        <View style={styles.titleDownload}>
                            <Text style={styles.textDownload}>{translate('saveVideo')}</Text>
                            <TouchableOpacity style={styles.dateDownload} onPress={() => setStartTimeModal(!startTimeModal)}>
                                <View style={styles.dateDownload}>
                                    <Text style={styles.startTimeDownload}>{translate('start')} {moment(start).format('DD/MM/YYYY HH:mm')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TimePickerModal
                                label={translate('startModal')}
                                cancelLabel={translate('cancel')}
                                hours={start.getHours()}
                                minutes={start.getMinutes()}
                                visible={startTimeModal}
                                onDismiss={() => setStartTimeModal(false)}
                                onConfirm={(hoursAndMinutes) => {
                                    setStartTimeModal(false);
                                    const newStart = currentTime;
                                    newStart.setHours(hoursAndMinutes.hours);
                                    newStart.setMinutes(hoursAndMinutes.minutes);
                                    setStart(newStart);
                                }}
                            />
                            <TouchableOpacity style={styles.dateDownload} onPress={() => setFinishTimeModal(!finishTimeModal)}>
                                <View style={styles.dateDownload}>
                                    <Text style={styles.startTimeDownload}>{translate('finish')} {moment(finish).format('DD/MM/YYYY HH:mm')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TimePickerModal
                                label={translate('finishModal')}
                                cancelLabel={translate('cancel')}
                                hours={finish.getHours()}
                                minutes={finish.getMinutes()}
                                visible={finishTimeModal}
                                onDismiss={() => setFinishTimeModal(false)}
                                onConfirm={(hoursAndMinutes) => {
                                    setFinishTimeModal(false);
                                    const newFinish = currentTime;
                                    newFinish.setHours(hoursAndMinutes.hours);
                                    newFinish.setMinutes(hoursAndMinutes.minutes);
                                    setFinish(newFinish);
                                }}
                            />
                            <TextInput
                                value={saveName}
                                onChangeText={(text) => setSaveName(text)}
                                style={styles.saveNameInput}
                                placeholder={translate('videoTitle')}
                                placeholderTextColor='#134256'
                                underlineColorAndroid="transparent"
                                onKeyPress={handleKeyDown}
                            />
                            <View style={styles.buttonSaveView}>
                                <TouchableOpacity style={styles.cancelSaveButton} onPress={() => setDownloadModal(!downloadModal)}>
                                    <Text style={styles.textButtonSave}>{translate('cancel_caps')}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.cancelSaveButton} onPress={createVideo}>
                                    <Text style={styles.textButtonSave}>{translate('save_caps')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    playerView: {
        alignItems: 'center',
        backgroundColor: '#42454e',
        zIndex: 5,
    },
    timelineView: {
        flex: 1,
        backgroundColor: '#3c4049',
        zIndex: 5
    },
    modalContainerPanic: {
        flex: 1,
        position: 'relative',
        backgroundColor: '#000',
        opacity: 0.50,
    },
    modalCenterViewPanic: {
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    modalBodyPanic: {
        backgroundColor: "#fff",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 300,
        height: 200,
    },
    alertHeader: {
        width: '100%',
        height: 40,
        backgroundColor: '#0071bc',
        justifyContent: 'center'
    },
    alertTitle: {
        color: "#fff",
        alignSelf: 'center',
        fontSize: 20,
        fontWeight: '600',
    },
    alertContainerText: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20
    },
    alertContainerTextConfirm: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20
    },
    timerAlert: {
        color: "#fff",
        fontSize: 16,
        paddingHorizontal: 1
    },
    alertButtonsView: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 15
    },
    alertButtons: {
        marginHorizontal: 20,
        backgroundColor: '#0071bc',
        height: 30,
        width: 100,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    alertButtonText: {
        color: "#fff",
        fontWeight: '600'
    },
    alertConfirmParentheses: {
        paddingHorizontal: 3
    },
    timelineHeader: {
        backgroundColor: '#42454e',
        height: 60,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: 5,
    },
    timelineHeaderText: {
        color: '#fff',
        marginLeft: 10,
        fontWeight: '500'
    },
    alertButton: {
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 30,
    },
    alertImage: {
        width: 28,
        height: 25,
        tintColor: '#a9a9a9'
    },
    alertText: {
        paddingTop: 5,
        fontSize: 11,
        color: '#a9a9a9',
        fontWeight: '600'
    },
    timelineBottom: {
        backgroundColor: '#42454e',
        width: '100%',
        height: 60,
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: 5,
    },
    view: {
        width: '25%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        width: 33,
        height: 33,
        alignSelf: 'center'
    },
    text: {
        color: '#fff',
        fontWeight: '600',
        marginTop: 5,
        fontSize: 10,
        alignSelf: 'center'
    },
    modalContainerDownload: {
        flex: 1,
        position: 'relative',
        backgroundColor: '#000',
        opacity: 0.50,
    },
    modalContainerCalendar: {
        flex: 1,
        position: 'relative',
        backgroundColor: '#000',
        opacity: 0.50,
    },
    modalCenterViewDownload: {
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    modalCenterViewCalendar: {
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    modalBodyDownload: {
        backgroundColor: "#fff",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 250,
        height: 330,
    },
    titleDownload: {
        width: '100%',
        alignItems: 'center'
    },
    textDownload: {
        fontSize: 28,
        color: "#18799a",
        marginTop: 5
    },
    dateDownload: {
        height: 40,
        width: "90%",
        backgroundColor: '#18799a',
        borderRadius: 4,
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    startTimeDownload: {
        color: '#c9c9c9'
    },
    saveNameInput: {
        borderBottomColor: '#134256',
        borderBottomWidth: 1,
        fontSize: 16,
        marginTop: 80
    },
    buttonSaveView: {
        flexDirection: 'row',
    },
    cancelSaveButton: {
        backgroundColor: '#18799a',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        borderRadius: 4,
        marginHorizontal: 15,
        marginTop: 15
    },
    textButtonSave: {
        color: "#fff"
    },
    timeSkipInput: {
        backgroundColor: '#42454e',
        height: 60,
        maxWidth:'80%',
        justifyContent: 'center',
        alignItems: 'baseline',
        flexDirection: 'row',
        zIndex: 5,
    },
    inputSkip: {
        backgroundColor: '#A9A9A9',
        height: 40,
        maxWidth: '50%',
        border: 0,
        marginLeft: 30,
        borderRadius: 20,
        textAlign: 'center',
        fontSize: 18,
        color: '#000'
    },
    skipArrow: {
        width: 28,
        height: 25,
        color: '#a9a9a9'
    },
});
