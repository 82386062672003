import {
    View,
    StyleSheet,
    KeyboardAvoidingView,
    Image,
    TouchableOpacity,
    TextInput,
    FlatList,
    Text,
    ListRenderItemInfo,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { RefreshControl } from 'react-native-web-refresh-control';
import { Icon } from 'react-native-elements';

import VideoImage from '../components/VideoImage';
import { platformAPI, invalidBearerToken, CameraList, UserData } from '../services/platform-api';
import { translate } from '../services/service-translate';

import { useFocusEffect } from '@react-navigation/native';
import DefaultPageContainer from '../components/DefaultPageContainer';
import LazyLoad from 'react-lazy-load';
import PersonalAlert from '../components/PersonalAlert';

import '../styles/cameras.css';
import NpsSurvey from '../components/nps-survey/NpsSurvey';

interface CameraComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function CameraComponent({ navigation, authenticatedUser }: CameraComponentProps) {
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [filteredCameras, setFilteredCameras] = useState<CameraList[]>([]);
    const [allCameras, setAllCameras] = useState<CameraList[]>([]);
    const [invalidAccess, setInvalidAccess] = useState(false);
    const [refreshing, setRefreshing] = useState(true);

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    useEffect(() => {
        document.body.style.position = 'fixed';
        loadCameras();
    }, []);

    function openMap() {
        navigation.navigate('Map');
    }

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            setOnChangeSearch(true);
            setFilteredCameras(
                allCameras.filter(
                    (item) =>
                        item.title.toLowerCase().includes(val.toLocaleLowerCase())
                )
            );
        } else {
            setOnChangeSearch(false);
            setFilteredCameras(allCameras);
        }
    };

    const cleanSearchBar = () => {
        onChangeSearchBar('');
        setOnChangeSearch(false);
    };

    async function loadCameras() {
        try {
            setRefreshing(true);

            const listCameras = await platformAPI.getCameras();

            if (listCameras.length == 0) {
                setInvalidAccess(true);
            }

            setFilteredCameras(listCameras);
            setAllCameras(listCameras);

            setTimeout(() => setRefreshing(false), 1000);
        } catch (err) {

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);

            setInvalidAccess(true);
            setTimeout(() => setRefreshing(false), 1000);
        }
    }

    function renderItem({ item }: ListRenderItemInfo<CameraList>) {
        return (
            <>
                <LazyLoad height={115} width={'100%'}>
                    <TouchableOpacity onPress={() => navigation.navigate("CameraWatch", { id: item.id })}>
                        <View style={styles.view}>
                            <View style={styles.lazyLoadImage}>
                                <VideoImage src={item.lastShot} style={styles.imageCamera} />
                            </View>
                            <View style={styles.cameraTitleView}>
                                <Text style={styles.cameraTitle}>{item.title}</Text>
                                <Text style={styles.cameraCity}>{item['District.City.name']}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </LazyLoad>
            </>
        );
    }

    return (
        <DefaultPageContainer excludeBottomPadding={true}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={50}
                behavior={'padding'}
                style={styles.containerAvoidView}
            >
            </KeyboardAvoidingView>
            {showSearchBar ?
                <View style={styles.searchInputView}>
                    <TouchableOpacity
                        style={styles.searchBack}
                        onPress={() => setShowSearchBar(false)}
                    >
                        <Icon name='arrow-back' type='Ionicons' color={'#fff'} />
                    </TouchableOpacity>
                    <TextInput
                        selectionColor='#0071bc'
                        style={styles.searchInput}
                        onChangeText={text => onChangeSearchBar(text)}
                        onBlur={() => {
                            if (search === '' || filteredCameras.length === 0) {
                                cleanSearchBar();
                                setShowSearchBar(false);
                            }
                        }}
                        value={search}
                        autoFocus
                    />
                    {onChangeSearch ?
                        <TouchableOpacity
                            style={styles.searchDelete}
                            onPress={cleanSearchBar}
                        >
                            <Icon name='close' type='Ionicons' color={'#fff'} />
                        </TouchableOpacity> : null}
                </View>
                :
                <View style={styles.header}>
                    <View style={styles.mapView}>
                        <TouchableOpacity style={styles.mapIcon} onPress={openMap}>
                            <Image style={styles.mapIcon} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_state_city.png' }} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.logoView}>
                        {showSearchBar ? null : <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-topo.png' }} style={styles.logo} />}
                    </View>
                    <View style={styles.searchIconView}>
                        <TouchableOpacity onPress={() => setShowSearchBar(true)}>
                            {showSearchBar ? null : <Icon
                                name='search'
                                type='Feather'
                                style={styles.searchIcon}
                                color='#fff'
                                size={40}
                            />}
                        </TouchableOpacity>
                    </View>
                </View>
            }

            <View style={[styles.camerasView]}>
                {invalidAccess ?
                    <View style={styles.invalidAcessView}>
                        <Text style={styles.textInvalidAcess}>{translate('camerasAccess')}</Text>
                    </View>
                    :
                    <FlatList
                        style={[{ height: 1 }]}
                        data={filteredCameras}
                        keyExtractor={item => item.id.toString()}
                        contentContainerStyle={styles.flatlist}
                        renderItem={renderItem}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={loadCameras}
                            />
                        }
                    />
                }
            </View>
            <PersonalAlert authenticatedUser={authenticatedUser} />
            {authenticatedUser?.isNpsSurveyAvailable != undefined && authenticatedUser?.isNpsSurveyAvailable ?
                <>
                    <NpsSurvey visible={true} />
                </> : <></>
            }
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#0071bc',
        flex: 1,
        paddingBottom: 0
    },
    containerAvoidView: {
        alignItems: 'center',
    },
    header: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    mapView: {
        height: 60,
        width: '33%',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 10
    },
    mapIcon: {
        width: 30,
        height: 30
    },
    logoView: {
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        width: '33%',
        height: 60
    },
    logo: {
        width: 166,
        height: 32,
        alignSelf: 'center',
        justifyContent: 'center'
    },
    searchIconView: {
        width: '33%',
        alignItems: 'flex-end'
    },
    searchIcon: {
        marginRight: 10
    },
    searchInputView: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    searchInput: {
        width: '80%',
        height: '100%',
        color: '#fff',
        fontSize: 20,
    },
    searchBack: {
        alignSelf: 'center',
        marginLeft: '-10%',
        padding: 15
    },
    searchDelete: {
        justifyContent: 'center',
        marginRight: -30,
        padding: 10,
    },
    flatlist: {
        paddingLeft: 15,
        paddingTop: 40
    },
    view: {
        flexDirection: "row",
        paddingBottom: 15,
    },
    imageCamera: {
        width: '100%',
        height: 100,
        maxWidth: 178,
        borderRadius: 8,
        marginRight: 16,
    },
    cameraTitleView: {
        width: "45%",
        paddingLeft: 16,
    },
    cameraTitle: {
        alignItems: "center",
        marginTop: 10,
        fontSize: 16
    },
    cameraCity: {
        alignItems: "center",
        marginTop: 5,
        fontSize: 16,
        color: '#0071bc'
    },
    camerasView: {
        justifyContent: 'flex-start',
        alignContent: 'center',
        flex: 1
    },
    textInvalidAcess: {
        fontSize: 16,
        fontWeight: '500',
        color: '#aeaeae',
        alignSelf: 'center',
        justifyContent: 'center',
        textAlignVertical: 'center',
        textAlign: 'center'
    },
    invalidAcessView: {
        flex: 1,
        justifyContent: 'center',
    },
    thirdView: {
        height: 60,
        width: '33%'
    },
    lazyLoadImage: {
        backgroundSize: 178,
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
        backgroundImage: 'url(https://d1w4uh7ghtlh1q.cloudfront.net/public/images/home/placeholder_thumb.jpg)',
        borderRadius: 8,
        maxWidth: 178,
        maxHeight: 100,
        width: '45%'
    },
});
