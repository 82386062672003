import React, { useState, useEffect, useCallback } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    CrmGroupListItem,
    forbiddenAccess,
    updateCrmGroupParams,
    GetUsersParams,
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    LayoutChangeEvent,
    ListRenderItemInfo,
    ScrollView,
    Modal,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
    TextInput,
    useWindowDimensions,
} from 'react-native';
import {
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import '../../../styles/tooltips.css';
import { showActionSheet, Option } from "../../../components/ActionSheet";
import { RefreshControl } from "react-native-web-refresh-control";
import { FlatList } from "react-native-gesture-handler";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { useFocusEffect } from "@react-navigation/native";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface grouComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}
const headerSize = 60;
const footerSize = 30;
const buttonsSize = 56;


export default function CrmGroupList({ navigation, authenticatedUser }: grouComponentProps) {
    const insets = useSafeAreaInsets();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [groupToBeDeleted, setGroupToBeDeleted] = useState<CrmGroupListItem>();
    const [emptyList, setEmptyList] = useState(false);
    const [groupList, setGroupList] = useState<CrmGroupListItem[]>([]);
    const [viewHeight, setViewHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [canCreateGroup, setCanCreateGroup] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const windowWidth = useWindowDimensions().width;
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [searchParams, setSearchParams] = useState<GetUsersParams>({
        page: 1,
        filter: search
    });

    const { showActionSheetWithOptions } = useActionSheet();

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";
        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !groupList.length || groupList.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    useEffect(() => {
        loadCrmGroups();
    }, [searchParams]);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    useFocusEffect(
        useCallback(() => {
            setSearchParams({
                page: 1,
                filter: search
            });
        }, [navigation])
    );

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            if (authenticatedUser.permissions.can_edit_camera_group) {
                setCanCreateGroup(true);
            } else if (authenticatedUser.permissions.can_see_camera_group_tab) {
                setReadOnly(true);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function turnEnableDisable(selectedGroup: updateCrmGroupParams) {
        try {
            await platformAPI.updateCrmGroup({ id: selectedGroup.id, enabled: !selectedGroup.enabled });
            handleRefresh();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            setSearchParams({
                page: 1,
                filter: search
            });
        }
    }

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            return setOnChangeSearch(true);
        }
        setOnChangeSearch(false);
    };

    function handleClose() {
        if (search === '') {
            setShowSearchBar(false);
        } else {
            setSearchParams({
                page: 1,
                filter: ''
            });
            setSearch('');
        }
    }

    function openActionSheet(group: CrmGroupListItem) {
        setGroupToBeDeleted(group);
        const options: Option[] = [
            {
                title: readOnly ? translate("viewRegistration") : translate("editRegistration"),
                onClick: () => {
                    navigation.navigate('CrmGroupsForm', { id: group.id });
                }
            }, {
                title: readOnly ? translate("userAssociations") : translate("associateUsers"),
                subtitle: `${group.totalUsers} ${translate("users")}`,
                onClick: () => {
                    navigation.navigate('CrmGroupsUserAssociation', { id: group.id });
                },
            }, {
                title: readOnly ? translate("cameraAssociations") : translate("associateCameras"),
                subtitle: `${group.totalCameras} ${translate("cameras")}`,
                onClick: () => {
                    navigation.navigate('CrmGroupsCamerasAssociation', { id: group.id });
                }
            }
        ];
        if (!readOnly) {
            options.push({
                title: group.enabled ? translate("disable") : translate("enable"),
                onClick: (() => turnEnableDisable({
                    id: group.id,
                    enabled: Boolean(group.enabled)
                }))
            });
            options.push({
                title: translate("delete"),
                destructive: true,
                onClick: () => {
                    setDeleteModalVisible(true);
                }
            });
        }
        showActionSheet(showActionSheetWithOptions, options);
    }

    async function loadCrmGroups() {
        try {
            setLoading(true);

            let crmGroups: CrmGroupListItem[] = [];

            if (search === searchParams.filter) {
                setLoading(false);
            }
            if (showSearchBar) {
                if (search === '') {
                    handleClose();
                    crmGroups = await platformAPI.getCrmGroups(searchParams);
                } else {
                    setRefreshing(true);
                    crmGroups = await platformAPI.getCrmGroups(searchParams);
                }
            } else {
                setSearch('');
                crmGroups = await platformAPI.getCrmGroups(searchParams);
            }

            setLoading(false);

            if (crmGroups.length === 0 && searchParams.page === 1) {
                setGroupList([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }
            if (searchParams.page === 1) {
                setGroupList(crmGroups);
            } else {
                setGroupList([...groupList, ...crmGroups]);
            }

            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
            setLoading(false);
            setRefreshing(false);
        }
    }

    async function deleteGroup() {
        if (!groupToBeDeleted) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteGroup(groupToBeDeleted.id);
            setDeleteModalVisible(false);
            handleRefresh();
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function handleLoadMore() {
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    function handleRefresh() {
        setRefreshing(true);

        setSearchParams({
            page: 1,
            filter: search
        });
        if (!emptyList) {
            setRefreshing(false);
        } else {
            setRefreshing(true);
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    function addGroup() {
        navigation.navigate("CrmGroupsForm");
    }

    const GroupItem = ({ item }: ListRenderItemInfo<CrmGroupListItem>) => (
        <View style={styles.groupCard}>
            <TouchableOpacity style={styles.innerGroupCard} onPress={() => openActionSheet(item)}>
                <View
                    style={styles.innerGroupCard}
                    onLayout={(e: LayoutChangeEvent) => {
                        if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                    }}>
                    <View>
                        <Text style={styles.textButton}>{item.title}</Text>
                        {item.userDefault ?
                            <Text style={styles.textDefaultGroup}>{translate("defaultGroup")}</Text>
                            : null}
                    </View>
                    {item.enabled ? (
                        <div className="ur-tooltip">
                            <span className="ur-tooltip-text">
                                {translate("enabled")}
                            </span>
                            <div className="ur-tooltip-icon">
                                <List.Icon
                                    icon={"check-circle"}
                                    color="green"
                                    style={styles.enabledIcon}
                                />
                            </div>
                        </div>
                    ) : <div className="ur-tooltip">
                        <span className="ur-tooltip-text">
                            {translate("disabled")}
                        </span>
                        <div className="ur-tooltip-icon">
                            <List.Icon
                                icon={"close-circle"}
                                color="red"
                                style={styles.enabledIcon}
                            />
                        </div>
                    </div>}
                </View>
            </TouchableOpacity>
        </View>
    );

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={[styles.backArrow, styles.icons]}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    {showSearchBar ?
                        <View style={styles.headerRight}>
                            <View style={styles.searchInput}>
                                <TextInput
                                    selectionColor='#0071bc'
                                    style={[styles.searchInput, { width: windowWidth - (buttonsSize * 3) }]}
                                    value={search}
                                    onKeyPress={handleKeyDown}
                                    onChangeText={text => {
                                        setSearch(text); onChangeSearchBar(text);
                                    }}
                                    autoFocus
                                />
                            </View>
                            <View style={styles.headerIcons}>
                                <TouchableOpacity onPress={() =>
                                    setSearchParams({
                                        page: 1,
                                        filter: search
                                    })}>
                                    <List.Icon
                                        icon="magnify"
                                        style={[styles.searchIcon, styles.icons]}
                                        color='#fff'
                                    />
                                </TouchableOpacity>
                                {onChangeSearch ?
                                    <TouchableOpacity onPress={handleClose}>
                                        <List.Icon
                                            icon='close'
                                            color='#fff'
                                            style={styles.icons}
                                        />
                                    </TouchableOpacity>
                                    : null
                                }
                            </View>
                        </View>
                        :
                        <View style={styles.headerRight}>
                            <View style={styles.titleHeaderView}>
                                <Text style={styles.titleHeader}>{translate("groupsTitle")}</Text>
                            </View>
                            <View style={styles.headerIcons}>
                                <TouchableOpacity onPress={() => setShowSearchBar(true)}>
                                    {showSearchBar ? null :
                                        <List.Icon
                                            icon="magnify"
                                            style={styles.searchIcon}
                                            color='#fff'
                                        />}
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.titleHeaderView} onPress={addGroup}>
                                    {canCreateGroup ?
                                        <List.Icon
                                            style={styles.plus}
                                            icon="plus"
                                            color="#fff"
                                        />
                                        : null}
                                </TouchableOpacity>
                            </View>
                        </View>}
                </View>
            </View>
            <Modal
                animationType='fade'
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => {
                    setDeleteModalVisible(!deleteModalVisible);
                }}>
                <View style={styles.fadeView} />
                <View style={styles.centeredView}>
                    <View style={[styles.containerModal, { marginTop: viewHeight / 2.2 }]}>
                        <Text style={styles.titleModal} numberOfLines={2} adjustsFontSizeToFit>{translate("deleteGroupConfirmation")}{"\n"}{groupToBeDeleted?.title}</Text>
                        <View style={styles.viewButtonsModal}>
                            <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deleteGroup()}>
                                <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
            <ScrollView
                bounces={false}
                style={[styles.scrollView, { height: viewHeight }]}
            >
                {!groupList.length ? (
                    !loading && (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>
                    )
                ) : (
                    <View>
                        <FlatList
                            style={[styles.scrollView, { height: viewHeight }]}
                            bounces={false}
                            data={groupList}
                            contentContainerStyle={styles.flatlist}
                            renderItem={GroupItem}
                            removeClippedSubviews={true}
                            updateCellsBatchingPeriod={100}
                            windowSize={12}
                            ItemSeparatorComponent={ItemSeparator}
                            nestedScrollEnabled
                            onEndReached={() => {
                                handleLoadMore();
                            }}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            onEndReachedThreshold={0.03}
                            onLayout={(e: LayoutChangeEvent) => {
                                setContentHeight(e.nativeEvent.layout.height);
                            }}
                        />
                    </View>
                )}
            </ScrollView>

            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    enabledIcon: {
        width: 20,
        height: 20,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    flatlist: {},
    contentContainer: {
        justifyContent: "space-between",
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    groupCard: {
        color: "red",
        width: "100%",
        height: "72px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    innerGroupCard: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textButton: {
        fontSize: 16,
        marginLeft: "15px",
        width: 250,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    textDefaultGroup: {
        marginLeft: "15px",
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    headerRight: {
        width: 'calc(100% - 56px)',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    icons: {
        maxWidth: buttonsSize,
        maxHeight: buttonsSize
    },
    searchInput: {
        height: '100%',
        color: '#fff',
        fontSize: 20,
    },
    bothSides: {
        flexDirection: "row",
        alignItems: "center"
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        alignSelf: 'flex-start',
        width: '100%',
        height: headerSize,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    headerIcons: {
        flexDirection: 'row',
        alignItems: "center"
    },
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    plus: {
        alignSelf: "center",
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
        alignSelf: 'center'
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 17,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    textButtonCloseModal: {
        color: "#000",
    },
});
