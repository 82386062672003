import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    Text,
    Image,
    Alert,
    Modal,
    Pressable,
    SafeAreaView,
    NativeSyntheticEvent,
    TextInputKeyPressEventData
} from 'react-native';
import { ActivityIndicator, IconButton } from 'react-native-paper';
import { notificationService } from '../../services/notification-service';
import { platformAPI } from '../../services/platform-api';
import { useNavigationState, useLinkTo, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// translations
import { translate } from '../../services/service-translate';

const Login = () => {
    const [userText, onChangeUserText] = useState('');
    const [passText, onChangePassText] = useState('');
    const [emailText, onEmailText] = useState('');
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [emptyField, setEmptyField] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [invalidEmailRecover, setInvalidEmailRecover] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const index = useNavigationState(state => state);
    const linkTo = useLinkTo();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.body.style.position = 'fixed';
    }, []);

    if (useIsFocused()) {
        checkAuth();
    }

    async function checkAuth() {
        const token = await AsyncStorage.getItem('BEARER_TOKEN');

        if (token) {
            linkTo('/cameras');
        }
    }

    async function login() {
        setLoading(true);
        setInvalidLogin(false);

        if (userText == '' && passText == '') {
            setInvalidLogin(false);
            setEmptyField(true);
            setLoading(false);
            return;
        }

        try {
            await platformAPI.authenticateByMail(userText, passText);
            try {
                await notificationService.registerToken();
                console.log("firebase token registered.");
            } catch (err) {
                console.error(err);
            }
            setLoading(false);
            if (index.routes[0].name == 'Login'
                || index.routes[0].name == 'Authentication'
                || index.routes[0].name == 'InputOTP') {
                linkTo('/cameras');
                return;
            }
            if (index.routes[0].path != undefined) {
                setTimeout(() => {
                    linkTo(String(index.routes[0].path));
                }, 200);
                return;
            }
        } catch (err) {
            setEmptyField(false);
            setInvalidLogin(true);
            setLoading(false);
        }

    }

    const onPressRecover = () => {
        setModalVisible(true);
        setEmptyEmail(false);
        setInvalidEmailRecover(false);
        setEmailSent(false);
    };

    async function recover() {
        setEmailSent(false);
        setInvalidEmailRecover(false);

        if (emailText == '') {
            setEmptyEmail(true);
            setTimeout(() => setModalVisible(!modalVisible), 1500);
            return;
        }

        try {
            await platformAPI.recoverPassword(emailText);
            setEmailSent(true);
        } catch (err) {
            setEmptyEmail(false);
            setInvalidEmailRecover(true);
        }

        setTimeout(() => setModalVisible(!modalVisible), 1500);

    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            if (passText == '' || userText == '') {
                return;
            }
            login();
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    Alert.alert('Modal has been closed.');
                    setModalVisible(!modalVisible);
                }}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalText}>{translate('recoveryTitle')}</Text>
                        <View style={[
                            styles.emailView,
                            {
                                borderBottomColor: invalidEmailRecover || emptyEmail ? '#FF0000' : '#000000'
                            }
                        ]}>
                            <TextInput
                                style={styles.inputEmail}
                                onChangeText={onEmailText}
                                value={emailText}
                                placeholder='  camerite@camerite.com' />
                        </View>
                        {emailSent ? <Text style={styles.sent}>{translate('recoverySent')}</Text> : null}
                        {invalidEmailRecover ? <Text style={styles.invalid}>{translate('recoveryNotEmail')}</Text> : null}
                        {emptyEmail ? <Text style={styles.invalid}>{translate('emptyField')}</Text> : null}
                        <View style={styles.buttonView}>
                            <Pressable
                                style={[styles.buttonClose]}
                                onPress={() => setModalVisible(!modalVisible)}>
                                <Text style={styles.textButton}>{translate('close')}</Text>
                            </Pressable>
                            <Pressable
                                disabled={emailSent}
                                style={[styles.buttonSend]}
                                onPress={recover}>
                                <Text style={styles.textButton}>{translate('send')}</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
            <View style={styles.logoView}>
                <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-logo-azul.png' }} style={styles.logo} />
            </View>
            <View style={styles.viewStyleForLine} />
            {invalidLogin ? <Text style={styles.message}>{translate('invalidLogin')}</Text> : null}
            <View style={styles.inputView}>
                <View style={[
                    styles.emailView,
                    {
                        borderBottomColor: invalidLogin || emptyField ? '#FF0000' : '#000000'
                    }
                ]}>
                    <TextInput
                        style={styles.inputEmail}
                        onChangeText={onChangeUserText}
                        value={userText}
                        onKeyPress={handleKeyDown}
                        placeholder='camerite@camerite.com' />
                </View>
                {emptyField ? <Text style={styles.emptyFieldText}>{translate('emptyField')}</Text> : null}
                <View style={[
                    styles.passwordView,
                    {
                        borderBottomColor: invalidLogin || emptyField ? '#FF0000' : '#000000'
                    }
                ]}>
                    <TextInput
                        style={styles.inputPass}
                        onChangeText={onChangePassText}
                        value={passText}
                        onKeyPress={handleKeyDown}
                        placeholder={showPass ? translate('passwordLabel') : '*******'}
                        secureTextEntry={showPass ? false : true}
                    />
                    {showPass ? <IconButton color='#4A4A4A' onPress={() => setShowPass(false)} style={styles.showPass} icon='eye' /> : <IconButton color='#bababa' onPress={() => setShowPass(true)} style={styles.showPass} icon='eye-off' />}
                </View>
                {emptyField ? <Text style={styles.emptyFieldText}>{translate('emptyField')}</Text> : null}


                <TouchableOpacity
                    style={styles.button}
                    disabled={passText == '' || userText == ''}
                    onPress={login}
                >
                    <Text style={passText == '' || userText == '' ? styles.buttonTextDisabled : styles.buttonText}>
                        {loading ? (
                            <ActivityIndicator color="#0071bc" />
                        ) : (
                            translate('enterLogin')
                        )}
                    </Text>
                </TouchableOpacity>

            </View>
            <View style={styles.forgotView}>
                <TouchableOpacity onPress={onPressRecover}>
                    <Text style={styles.textForgot}>{translate('forgotPass')}</Text>
                </TouchableOpacity>
            </View>

        </SafeAreaView>
    );
};


export default Login;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#ffffff',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    invalid: {
        color: '#ff0000',
        marginTop: -10,
        marginBottom: 20
    },
    sent: {
        color: '#008000',
        marginTop: -10,
        marginBottom: 20
    },
    buttonSend: {
        borderRadius: 20,
        height: 30,
        width: 100,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        marginLeft: 5
    },
    buttonClose: {
        borderRadius: 20,
        height: 30,
        width: 100,
        backgroundColor: '#0B4870',
        justifyContent: 'center',
        marginRight: 5
    },
    textButton: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        textAlignVertical: 'center',
        alignSelf: 'center'
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontSize: 16,
        color: '#0071bc',
        fontWeight: '600'
    },
    buttonView: {
        flexDirection: 'row',
    },
    logoView: {
        marginBottom: 60,
        marginTop: 80,
    },
    logo: {
        width: 270,
        height: 52,
        alignSelf: 'center',
    },
    viewStyleForLine: {
        borderBottomColor: "#0071bc",
        borderBottomWidth: 3,
        width: "100%",
        alignSelf: 'center',
        padding: 2,
        marginHorizontal: 18,
        marginBottom: 60
    },
    inputView: {
        backgroundColor: '#fff',
        color: '#fff',
        height: "20%",
    },
    emailView: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        backgroundColor: "#ffffff",
        height: 44,
        paddingHorizontal: 15,
        marginBottom: 15,
        marginHorizontal: 15,
    },
    inputEmail: {
        width: '100%',

    },
    emptyFieldText: {
        color: '#ff0000',
        marginLeft: 15,
        marginTop: -9
    },
    passwordView: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderColor: "#000000",
        backgroundColor: "#ffffff",
        height: 44,
        paddingHorizontal: 15,
        marginBottom: 15,
        marginHorizontal: 15,
    },
    inputPass: {
        width: '96%',
        alignItems: 'flex-start',
    },
    showPass: {
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginRight: -15,
    },
    button: {
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginTop: 10,
        cursor: 'pointer',
        paddingVertical: 10,
        paddingHorizontal: 20,
        zIndex: 9999
    },
    buttonText: {
        color: "#0071bc",
        fontSize: 20,
        textAlign: 'right',
        fontWeight: '500',
    },
    buttonTextDisabled: {
        color: "#9a9a9a4f",
        fontSize: 20,
        textAlign: 'right',
        fontWeight: '500',
    },
    message: {
        marginTop: 15,
        textAlign: "center",
        color: "red"
    },
    forgotView: {
        height: '40%',
        justifyContent: 'flex-end',
        alignContent: 'center',
        alignItems: 'center',
        zIndex: -1
    },
    textForgot: {
        textDecorationLine: 'underline',
        color: '#aaa',
    }
});
