import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Switch, Icon } from 'react-native-elements';
import { settingsLang, translate } from '../../services/service-translate';
import { List } from 'react-native-paper';
import { UserData, invalidBearerToken, platformAPI } from '../../services/platform-api';
import { useActionSheet } from "@expo/react-native-action-sheet";
import DefaultPageContainer from '../../components/DefaultPageContainer';
import NotificationDialog from '../../components/dialog/notification';
import Toast from 'react-native-toast-message';


interface SettingsComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function Settings({ navigation, authenticatedUser }: SettingsComponentProps) {
    const [allNotifications, setAllNotifications] = useState(false);
    const [movement, setMovement] = useState(false);
    const [status, setStatus] = useState(false);
    const [saved, setSaved] = useState(false);
    const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
    const { showActionSheetWithOptions } = useActionSheet();

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        userData();
    }, [authenticatedUser]);

    async function userData() {
        if (!authenticatedUser) return;

        setAllNotifications(authenticatedUser.pushAll);
        setMovement(authenticatedUser.pushMovement);
        setStatus(authenticatedUser.pushStatus);
    }

    async function setNotificationStatus() {
        await platformAPI.setUserData({
            pushAll: allNotifications,
            pushMovement: movement,
            pushStatus: status
        });
    }

    async function handleSendNotification() {
        try {
            setOpenNotificationDialog(false);
            await platformAPI.sendGenericNotification();
            Toast.show({
                type: 'success',
                text1: translate('notification_test_success'),
            });
        } catch (err) {
            setOpenNotificationDialog(false);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }


    return (
        <DefaultPageContainer>
            {saved ?
                <View style={styles.saveHeader}>
                    <View style={styles.leftView}>
                        <TouchableOpacity onPress={() => setSaved(false)}>
                            <Image style={styles.imageClose} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_close.png' }} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.rightView}>
                        <TouchableOpacity onPress={() => {
                            setSaved(false), setNotificationStatus();
                        }}>
                            <Text style={styles.saveText}>{translate('save')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={styles.header}>
                    <View style={styles.backButtonView}>
                        <TouchableOpacity style={styles.backButton} onPress={goBack}>
                            <List.Icon icon='arrow-left' color="#fff" />
                        </TouchableOpacity>
                        <Text style={styles.titleHeader}>{translate('configs')}</Text>
                    </View>
                </View>
            }
            <View style={styles.translateView}>
                <TouchableOpacity onPress={() => settingsLang(showActionSheetWithOptions)}>
                    <View style={styles.translator}>
                        <Icon
                            name='g-translate'
                            color={'#0071bc'}
                        />
                    </View>
                </TouchableOpacity>
            </View>
            <View style={styles.contentView}>
                <Text style={styles.contentText}>{translate('allNotifications')}</Text>
                <Switch
                    color='#263483'
                    style={styles.switches}
                    value={allNotifications}
                    onValueChange={(value) => {
                        setAllNotifications(value), setSaved(true);
                    }}
                />
            </View>
            <View style={styles.contentView}>
                <Text style={styles.contentText}>{translate('moveNotification')}</Text>
                <Switch
                    color='#263483'
                    style={styles.switches}
                    value={movement}
                    onValueChange={(value) => {
                        setMovement(value), setSaved(true);
                    }}
                />
            </View>
            <View style={styles.contentView}>
                <Text style={styles.contentText}>{translate('statusNotification')}</Text>
                <Switch
                    color='#263483'
                    style={styles.switches}
                    value={status}
                    onValueChange={(value) => {
                        setStatus(value), setSaved(true);
                    }}
                />
            </View>
            <View style={[styles.testNotificationButton, { backgroundColor: '#0071bc' }]}>
                <TouchableOpacity onPress={() => setOpenNotificationDialog(!openNotificationDialog)}>
                    <Text style={styles.testNotificationTextButton}>{translate('notification_test_title')}</Text>
                </TouchableOpacity>
            </View>
            {openNotificationDialog ? (
                <>
                    <NotificationDialog sendNotification={handleSendNotification} user={authenticatedUser} open={openNotificationDialog} setOpen={setOpenNotificationDialog} />
                </>
            ) : <></>}
        </DefaultPageContainer>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: "#000"
    },
    backgroundView: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff'
    },
    contentView: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 20
    },
    header: {
        alignSelf: 'flex-start',
        width: '100%',
        height: 60,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backButtonView: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    backArrow: {
        height: 25,
        width: 25,
        marginLeft: 15
    },
    backButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
        alignSelf: 'center'
    },
    saveHeader: {
        alignSelf: 'flex-start',
        width: '100%',
        height: 60,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    leftView: {
        width: "50%",
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    imageClose: {
        width: 30,
        height: 30,
        marginLeft: 10
    },
    rightView: {
        width: "50%",
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    saveText: {
        color: "#fff",
        alignSelf: 'center',
        marginRight: 20,
        fontSize: 18
    },
    contentText: {
        marginLeft: 20,
        fontSize: 16
    },
    switches: {
        alignSelf: 'flex-end',
        marginRight: 20
    },
    testNotificationButton: {
        marginTop: 20,
        marginLeft: 20,
        width: 200,
        height: 30,
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: 16
    },
    testNotificationTextButton: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '600'
    },
    translateView: {
        justifyContent: 'center',
        height: 27,
        alignSelf: 'baseline',
        marginTop: 20,
        marginLeft: 10
    },
    translator: {
        alignContent: 'center',
        alignItems: 'center'
    },
    ListInfo: {
        backgroundColor: "red"
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    }
});
