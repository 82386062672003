import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ListRenderItemInfo,
    FlatList,
    ScrollView,
    LayoutChangeEvent,
    Image,
    Modal
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { ActivityIndicator, List } from "react-native-paper";
import {
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import {
    LprDetection,
    invalidBearerToken,
    platformAPI,
    GetLprDetectionParams,
    LprCamera
} from "../../services/platform-api";
import { translate } from "../../services/service-translate";
import PlateImage from "../../components/PlateImage";
import LPRFilters from "../../components/LPRFilters";
import '../../styles/tooltips.css';
import DefaultPageContainer from "../../components/DefaultPageContainer";

interface LPRComponentProps {
    navigation: Navigation;
}

interface FilterProps {
    blacklist: boolean;
    camera: LprCamera | null;
    startDate: Date;
    endDate: Date;
    plate: string;
}

const headerSize = 60;
const footerSize = 30;
const oneDay = 86400000;

export default function LicensePlateRecognitionList({
    navigation,
}: LPRComponentProps) {
    const insets = useSafeAreaInsets();
    const [lprList, setLprList] = useState<LprDetection[]>([]);
    const [viewHeight, setViewHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [searchParams, setSearchParams] = useState<GetLprDetectionParams>({
        page: 0,
        startDate: new Date(new Date().getTime() - oneDay),
        finishDate: new Date(new Date().getTime()),
        blacklist: false,
    });
    const [loading, setLoading] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState<FilterProps>({
        blacklist: false,
        camera: null,
        startDate: new Date(new Date().getTime() - oneDay),
        endDate: new Date(new Date().getTime()),
        plate: '',
    });
    const [dropDown, setDropDown] = useState(false);
    const [lprCameras, setLprCameras] = useState<LprCamera[]>([]);

    useEffect(() => {
        setLprList([]);
        getLprCameras();

        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";

        loadLprDetections();

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        loadLprDetections();
    }, [searchParams]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !lprList.length || lprList.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    async function loadLprDetections() {
        try {
            setLoading(true);
            const listDetections = await platformAPI.getLprDetections(searchParams);

            if (searchParams.page === 0) {
                setLprList(listDetections.results);
            } else {
                setLprList([...lprList, ...listDetections.results]);
            }

            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
            setRefreshing(false);
        }
    }

    async function getLprCameras() {
        const cameras = await platformAPI.getLprCameras();

        setLprCameras(cameras);
    }

    function handleLoadMore() {
        if (loading) return;

        setSearchParams({
            ...searchParams,
            page: searchParams.page + 1,
        });
    }

    function handleFilterResults() {
        setLprList([]);
        setSearchParams({
            blacklist: filter.blacklist,
            startDate: filter.startDate,
            finishDate: filter.endDate,
            cameras: filter.camera ? filter.camera.id.toString() : "",
            plate: filter.plate.toUpperCase(),
            page: 0,
        });
    }

    function clearFilters() {
        setSearchParams({
            blacklist: false,
            cameras: "",
            page: 0,
            startDate: new Date(new Date().getTime() - oneDay),
            finishDate: new Date(new Date().getTime()),
        });
        setFilter({
            blacklist: false,
            camera: null,
            startDate: new Date(new Date().getTime() - oneDay),
            endDate: new Date(new Date().getTime()),
            plate: '',
        });
        setShowModal(false);
    }

    function handleRefresh() {
        setRefreshing(true);
        setSearchParams({
            ...searchParams,
            page: 0,
        });
    }

    function handleCameraSearch(camera: LprCamera) {
        setDropDown(!dropDown);

        filter.camera = camera;
    }

    function toggleDropDown() {
        setDropDown(!dropDown);
    }

    function toggleModal() {
        setShowModal(!showModal);
    }

    function goBack() {
        const state = navigation.getState();
        if (state.routeNames.includes("More")) {
            navigation.navigate("More");
            return;
        }
        navigation.navigate("Camera");
    }

    const renderItem = ({ item }: ListRenderItemInfo<LprDetection>) => {
        return (
            <TouchableOpacity onPress={() => navigation.navigate("LPR_Details", { id: item.id })} style={{ width: "100%" }}>
                <View
                    style={styles.itemContainer}
                    onLayout={(e: LayoutChangeEvent) => {
                        if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                    }}
                >
                    <View style={[styles.itemContainerInfo, item.is_blacklist ? { width: "90%" } : { width: "100%" }]}>
                        <PlateImage
                            src={item.image_url}
                            style={{ borderRadius: '2px' }}
                            platePosition={item.plate_position}
                        />
                        <View style={styles.itemContainerPlateInfo}>
                            <Text style={styles.plateLabel}>
                                {item.plate}
                            </Text>
                            <Text numberOfLines={1} style={styles.cameraLabel} >
                                {item["Camera.title"]}
                            </Text>
                            <Text>{item.dateTZFormatted}</Text>
                        </View>
                    </View>
                    <View style={styles.blacklistIconBox}>
                        {item.is_blacklist ? (
                            <div className="lpr-tooltip">
                                <span className="lpr-tooltip-text">
                                    {item.blacklist_infos.map((blacklist) => {
                                        if (blacklist.userName) {
                                            return blacklist.desc + " " + translate("createdBy") + " " + blacklist.userName;
                                        } else {
                                            return blacklist.desc;
                                        }
                                    }).join(' - ')}
                                </span>
                                <div className="lpr-tooltip-icon">
                                    <List.Icon
                                        icon={"alert"}
                                        color="#DB5451"
                                        style={styles.blacklistIcon}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("readPlate")}</Text>
                    </View>
                </View>
                <View style={styles.filterView}>
                    <TouchableOpacity onPress={() => setShowModal(!showModal)}>
                        <Image style={styles.filterImage} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_filter.png' }} />
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.secundaryContainer}>
                <Modal
                    animationType='slide'
                    transparent={true}
                    visible={showModal}
                    onRequestClose={() => {
                        setShowModal(!showModal);
                    }}
                    style={{
                        margin: 0,
                    }}
                >
                    <View style={styles.modalView}>
                        <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => setShowModal(false)}
                            style={{
                                height: headerSize,
                                backgroundColor: "rgba(0, 0, 0, 0.5)"
                            }}
                        />
                        <View style={{
                            height: window.innerHeight - headerSize
                        }}>
                            <View style={styles.modalHeader}>
                                <TouchableOpacity onPress={() => setShowModal(!showModal)}>
                                    <List.Icon style={{ alignSelf: 'center' }} icon='chevron-down' color='#0071bc' />
                                </TouchableOpacity>
                                <Text style={styles.modalTitle}>{translate('filter')}</Text>
                            </View>
                            <LPRFilters
                                filter={filter}
                                dropDown={dropDown}
                                lprCameras={lprCameras}
                                toggleDropDown={toggleDropDown}
                                clearFilters={clearFilters}
                                setFilter={setFilter}
                                handleFilterResults={handleFilterResults}
                                handleCameraSearch={handleCameraSearch}
                                toggleModal={toggleModal}
                            />
                        </View>
                    </View>
                </Modal>
            </View>
            <ScrollView
                bounces={false}
                style={[styles.scrollView, { height: viewHeight }]}
            >
                {!lprList.length ? (
                    !loading && (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>
                    )
                ) : (
                    <View>
                        <FlatList
                            style={[styles.scrollView, { height: viewHeight }]}
                            bounces={false}
                            data={lprList}
                            keyExtractor={(item) => item.id.toString()}
                            contentContainerStyle={styles.flatlist}
                            renderItem={renderItem}
                            removeClippedSubviews={true}
                            updateCellsBatchingPeriod={100}
                            windowSize={12}
                            ItemSeparatorComponent={ItemSeparator}
                            nestedScrollEnabled
                            onEndReached={() => {
                                handleLoadMore();
                            }}
                            onEndReachedThreshold={0.03}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            onLayout={(e: LayoutChangeEvent) => {
                                setContentHeight(e.nativeEvent.layout.height);
                            }}
                        />
                    </View>
                )}
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    flatlist: {},
    itemContainer: {
        flexDirection: 'row',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: 72,
        paddingVertical: 0,
        paddingLeft: 20,
    },
    itemContainerPlateInfo: {
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: "column",
        paddingLeft: 30,
    },
    plateLabel: {
        fontWeight: "600",
        fontSize: 16,
        fontFamily: "Helvetica"
    },
    cameraLabel: {
        fontSize: 14,
        fontFamily: "Helvetica"
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    secundaryContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    filterView: {
        width: '10%',
        height: headerSize,
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    filterImage: {
        width: 25,
        height: 25,
        marginRight: 10,
    },
    modalView: {
        flex: 1,
        height: window.innerHeight,
        justifyContent: "flex-end",
    },
    modalHeader: {
        alignSelf: 'flex-start',
        width: "100%",
        height: 60,
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    modalTitle: {
        fontSize: 16,
        color: "#0071bc",
        alignSelf: 'center',
        fontWeight: '600',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    descModal: {
        backgroundColor: "#FFF",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 250,
        height: 150,
        borderRadius: 5,
        textAlign: "center",
        paddingHorizontal: 10,
    },
    itemContainerInfo: {
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    blacklistIconBox: {
        justifyContent: "center",
        marginRight: 10,
        width: 40,
        height: 72,
    },
    blacklistIcon: {
        width: 20,
        height: 20,
    }
});

export { FilterProps };
