import React, { useState, useEffect } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    CrmGroup,
    forbiddenAccess,
    CrmGroupCamera,
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import CheckBoxLists, { CheckAssociationItem } from "../../../components/CheckAssociation";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface grouComponentProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

export default function CrmGroupsCamerasAssociation({ navigation, route, authenticatedUser }: grouComponentProps) {
    const params = route.params;
    const [readOnly, setReadOnly] = useState(true);
    const [group, setGroup] = useState<CrmGroup>();
    const [camerasList, setCamerasList] = useState<CrmGroupCamera[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        loadCameras();
    }, [navigation, route]);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    useEffect(() => {
        if (!readOnly && group) {
            loadWhitelabelCameras();
        }
    }, [readOnly, group]);

    async function save() {
        try {
            if (!group) return;

            const camerasUnchecked = group.GroupCamera.filter((groupCamera: CrmGroupCamera) => !groupCamera.checked);
            const camerasChecked = camerasList.filter((groupCamera: CrmGroupCamera) => groupCamera.checked);

            const groupToPut = {
                id: group.id,
                cameras_to_add: camerasChecked.map((camera: CrmGroupCamera) => camera.id),
                cameras_to_rm: camerasUnchecked.map((camera: CrmGroupCamera) => camera.id),
            };

            await platformAPI.updateCrmGroup(groupToPut);

            goBack();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function loadWhitelabelCameras() {
        const whitelabelCameras = await platformAPI.getWhiteLabelCameras();
        setCamerasList(whitelabelCameras.filter((camera: CrmGroupCamera) => !group?.GroupCamera.find((groupCamera) => groupCamera.id === camera.id)));
    }

    async function loadCameras() {
        try {
            setLoading(true);

            const groupRequest = await platformAPI.getCrmGroup(params.id);
            groupRequest.GroupCamera.sort((a, b) => a.title > b.title ? 1 : -1);
            setGroup(groupRequest);

            for (const camera of groupRequest.GroupCamera) {
                camera.checked = true;
            }
            groupRequest.GroupCamera.sort((a, b) => a.title > b.title ? 1 : -1);

            setGroup(groupRequest);
            setLoading(false);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Groups");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            if (!authenticatedUser.permissions.can_see_camera_group_tab) {
                navigation.navigate("Groups");
            }
            if (authenticatedUser.permissions.can_edit_camera_group) {
                setReadOnly(false);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Groups");
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    {!readOnly ?
                        <TouchableOpacity style={styles.titleHeaderView} onPress={save}>
                            <Text style={styles.titleHeader}>{translate("save")}</Text>
                        </TouchableOpacity>
                        : null}
                </View>
            </View>
            <View style={styles.scrollContainer}>
                <ScrollView style={styles.backgroundView}>
                    <CheckBoxLists
                        dataChecked={group?.GroupCamera as CheckAssociationItem[]}
                        dataUnchecked={camerasList as CheckAssociationItem[]}
                        readOnly={readOnly}
                    />
                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    backgroundView: {
        backgroundColor: "#fff",
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        width: '100%',
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
});
