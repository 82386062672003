import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Image } from 'react-native';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Area, CameraList } from '../services/platform-api';
import L, { Map, Polygon } from 'leaflet';
import { Modalize } from 'react-native-modalize';
import VideoImage from '../components/VideoImage';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../styles/map.css';

interface MapsProps {
    navigation: Navigation;
    cameras: CameraList[];
}

export default function Maps({ cameras, navigation }: MapsProps) {

    const modalizeRef = useRef<Modalize>(null);
    const [selectedCamera, setSelectedCamera] = useState<CameraList>();

    /* eslint-disable */
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
        iconUrl: require("leaflet/dist/images/marker-icon.png").default,
        shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
    });
    /* eslint-enable */

    const myIcon = L.icon({
        iconUrl: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_map_badge.png',
        iconSize: [29, 35],
        iconAnchor: [18, 41],
        popupAnchor: [0, -41]
    });

    const onOpen = () => {
        modalizeRef.current?.open();
    };

    function whenCreated(map: Map) {
        map.fitBounds(cameras.map((cam) => {
            return [parseFloat(cam.lat), parseFloat(cam.lng)];
        }));
    }

    const LeafletPolygon = ({ coordinates }: Area) => {
        const map = useMap();
        useEffect(() => {
            const coordinate: { lat: number, lng: number; }[] = [];

            for (const coord of coordinates[0]) {
                coordinate.push({
                    lat: coord[1],
                    lng: coord[0]
                });
            }
            const polygon = new Polygon(coordinate);
            const drawItems = L.featureGroup();

            drawItems.addLayer(polygon);
            polygon.addTo(map);
            drawItems.addTo(map);

            return () => {
                map.removeLayer(polygon);
                polygon.remove();
                drawItems.removeLayer(polygon);
            };
        }, [coordinates, map]);
        return null;
    };

    return (
        <>
            <View style={styles.container}>
                <View style={styles.backgroundView}>
                    <MapContainer
                        scrollWheelZoom={true}
                        zoomControl={false}
                        center={[-14.680184, -45.695575]}
                        zoom={18}
                        id="map"
                        whenCreated={whenCreated}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup>
                            {cameras?.map((cam, index) => (
                                <>
                                    <Marker
                                        icon={myIcon}
                                        position={[parseFloat(cam.lat), parseFloat(cam.lng)]}
                                        key={index}
                                        eventHandlers={{
                                            click: () => {
                                                onOpen();
                                                setSelectedCamera(cam);
                                            }
                                        }}
                                    />
                                    {cam.area && (
                                        <LeafletPolygon coordinates={cam.area.coordinates} type={cam.area.type} />
                                    )}
                                </>
                            ))}
                        </MarkerClusterGroup>
                    </MapContainer>
                    <Modalize ref={modalizeRef} modalHeight={280} overlayStyle={{ backgroundColor: 'transparent' }}>
                        <TouchableOpacity onPress={() => {
                            navigation.navigate("CameraWatch", { id: selectedCamera?.id });
                        }}>
                            <VideoImage src={selectedCamera?.lastShot} style={styles.imageCamera} />
                            <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_play.png' }} style={styles.playIcon} />
                        </TouchableOpacity>
                        <Text style={styles.popupTitle}>{selectedCamera?.title}</Text>
                    </Modalize>
                </View>
            </View>
        </>

    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#0071bc"
    },
    backgroundView: {
        flex: 1,
        backgroundColor: '#fff'
    },
    popupTitle: {
        fontSize: 20,
        alignSelf: 'center',
        marginTop: 230,
        position: 'absolute',
    },
    playIcon: {
        position: 'absolute',
        alignSelf: 'center',
        marginTop: 60,
        height: 100,
        width: 100,
        tintColor: '#D1D1D1',
        opacity: 0.8
    },
    imageCamera: {
        alignSelf: 'center',
        width: '100%',
        height: 220,
        borderTopLeftRadius: 11,
        borderTopRightRadius: 11,
        position: 'absolute'
    }
});
