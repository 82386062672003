import React, { useEffect, useRef, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ScrollView,
    TextInput,
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    ClientError,
    forbiddenAccess,
    GetCrmUser,
    invalidBearerToken,
    platformAPI,
    ZipCode,
} from "../../../services/platform-api";
import { translate } from "../../../services/service-translate";
import '../../../styles/tooltips.css';
import { TextInputMask } from "react-native-masked-text";
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import Toast from "react-native-toast-message";
import { cnpjValidation, cpfValidation } from "../../../services/user-validations";
import DropDownPicker from "react-native-dropdown-picker";
import { Switch } from 'react-native-elements';
import moment from "moment";

interface CrmUserFormComponentProps {
    navigation: Navigation;
    route: {
        params: GetCrmUser;
    };
    authenticatedUser?: UserData;
}

enum UserType {
    Individual = 0,
    Company = 1
}

const headerSize = 60;
const footerSize = 30;

export default function CrmUserForm({ navigation, route, authenticatedUser }: CrmUserFormComponentProps) {
    const userParam = route.params;
    const cepRef = useRef<TextInputMask>(null);
    const cpfRef = useRef<TextInputMask>(null);
    const cnpjRef = useRef<TextInputMask>(null);
    const numRef = useRef<TextInputMask>(null);
    const [canSeeUserDetails, setCanSeeUserDetails] = useState(false);
    const [canEditUser, setCanEditUser] = useState(false);
    const [canCreateTrialUsers, setCanCreateTrialUsers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [userType, setUserType] = useState<UserType>(UserType.Individual);
    const [crmUser, setCrmUser] = useState<GetCrmUser>();
    const [cep, setCep] = useState<ZipCode>();
    const [checkEmail, setCheckEmail] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);
    const [phoneValidError, setPhoneValidError] = useState(false);
    const [cpfValidError, setCpfValidError] = useState(false);
    const [cnpjValidError, setCnpjValidError] = useState(false);
    const [cepValidError, setCepValidError] = useState(false);
    const [passwordValidError, setPasswordValidError] = useState(false);
    const [passwordConfirmationValidError, setPasswordConfirmationValidError] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [inputs, setInputs] = useState<GetCrmUser>({
        name: "",
        email: "",
        password: "",
        phone: "",
        cpf: "",
        cnpj: "",
        cep: "",
        state: "",
        city: "",
        street: "",
        streetNumber: "",
        complement: "",
        district: "",
        observation: "",
        demoExpirationDate: undefined
    });

    useEffect(() => {
        document.body.style.position = "fixed";
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id != 1 && authenticatedUser.permissions.can_see_user_details || authenticatedUser.permissions.can_edit_user) {
                setCanSeeUserDetails(authenticatedUser.permissions.can_see_user_details);
                setCanEditUser(authenticatedUser.permissions.can_edit_user);
                setCanCreateTrialUsers(authenticatedUser.permissions.can_create_trial_users);
            } else {
                navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {
        if (userParam?.id) {
            const unsubscribe = navigation.addListener('focus', () => {
                getUser();
            });
            return unsubscribe;
        }
    }, [navigation, route]);

    useEffect(() => {
        setInputs({
            ...inputs,
            city: cep?.localidade,
            state: cep?.uf,
            street: cep?.logradouro,
            district: cep?.bairro,
        });
    }, [cep]);

    useEffect(() => {
        handleValidEmail;
    }, [inputs.email]);

    async function loadZipCode() {
        setLoading(true);
        if (!cepValidError) {
            try {
                const zipCode = await platformAPI.getZipCode(inputs.cep);
                if (!zipCode) {
                    return;
                }
                setCep(zipCode);
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setLoading(false);
                    Toast.show({
                        type: 'error',
                        text1: translate('invalidZipCode'),
                    });
                }
            }
            return;
        } else {
            setLoading(false);
            Toast.show({
                type: 'error',
                text1: translate('invalidZipCode'),
            });
        }
    }

    async function registerUser() {
        setLoading(true);
        try {
            if (validate()) {
                await platformAPI.registerUser(inputs);
                setLoading(false);
                allValidErrors();
                goBack();
                return;
            }
            return setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof ClientError) {
                setLoading(false);
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            setLoading(false);
            console.log(err);
        }
    }

    async function updateUser() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            if (validate()) {
                await platformAPI.updateUser(userParam.id, {
                    cep: inputs.cep,
                    city: inputs.city,
                    complement: inputs.complement,
                    cpf: inputs.cpf,
                    cnpj: inputs.cnpj,
                    district: inputs.district,
                    email: inputs.email,
                    name: inputs.name,
                    phone: inputs.phone,
                    state: inputs.state,
                    street: inputs.street,
                    streetNumber: inputs.streetNumber,
                    observation: inputs.observation,
                    demoExpirationDate: inputs.demoExpirationDate
                });
                allValidErrors();
                goBack();
                setLoading(false);
                return;
            }
            setLoading(false);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof ClientError) {
                setLoading(false);
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
                return;
            }
            setLoading(false);
            console.log(err);
        }
    }

    async function getUser() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            const userData = await platformAPI.getCrmUser(userParam?.id);
            setCrmUser(userData);
            setInputs({
                name: userData.name,
                email: userData.email,
                phone: userData.phone,
                cpf: userData.cpf,
                cnpj: userData.cnpj,
                cep: userData.cep,
                state: userData.state,
                city: userData.city,
                street: userData.street,
                streetNumber: userData.streetNumber,
                complement: userData.complement,
                district: userData.district,
                observation: userData.observation,
                demoExpirationDate: userData.demoExpirationDate
            });
            if (!userData.cnpj) {
                setUserType(UserType.Individual);
                setLoading(false);
                return;
            }
            if (!userData.cpf) {
                setUserType(UserType.Company);
                setLoading(false);
                return;
            }
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.log(err);
        }
    }

    async function handleValidEmail() {
        const reg = new RegExp(/^[a-zA-Z0-9-_-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
        if (!inputs.email) {
            setEmailValidError(true);
            Toast.show({
                type: 'error',
                text1: translate("invalidEmail"),
            });
            return;
        }

        if (!reg.test(inputs.email)) {
            setEmailValidError(true);
            Toast.show({
                type: 'error',
                text1: translate("invalidEmail"),
            });
        }
        if (reg.test(inputs.email)) {
            setLoading(true);
            const emailValidate = await platformAPI.checkEmail(inputs.email);

            if (inputs.email == crmUser?.email) {
                setLoading(false);
                setEmailValidError(false);
                return;
            }
            if (emailValidate) {
                setLoading(false);
                setEmailValidError(false);
                setCheckEmail(emailValidate);
            } else {
                setLoading(false);
                setEmailValidError(true);
                Toast.show({
                    type: 'error',
                    text1: translate("emailAlreadyRegistered"),
                });
            }
        }
    }

    function goBack() {
        setInputs({
            name: "",
            email: "",
            phone: "",
            cpf: "",
            cnpj: "",
            cep: "",
            state: "",
            city: "",
            street: "",
            streetNumber: "",
            complement: "",
            district: "",
            observation: "",
            demoExpirationDate: undefined
        });
        setCep({
            logradouro: "",
            bairro: "",
            localidade: "",
            uf: "",
        });
        allValidErrors();
        navigation.navigate("CrmUserList");
        setUserType(UserType.Individual);
        return;
    }

    function allValidErrors() {
        setCnpjValidError(false);
        setCepValidError(false);
        setCpfValidError(false);
        setPhoneValidError(false);
        setPasswordValidError(false);
        setPasswordConfirmationValidError(false);
    }

    function validate() {
        if (inputs.email == crmUser?.email) {
            return true;
        }
        if (passwordConfirmationValidError || passwordValidError) {
            return false;
        }
        if (!inputs.name || !checkEmail || emailValidError) {
            return false;
        } else {
            return true;
        }
    }

    function handleValidPhone(val: string) {
        if (val.length === 16) {
            setPhoneValidError(false);
            return;
        }
        setPhoneValidError(true);
    }

    function handleValidCpf() {
        if (!inputs.cpf) {
            return setCpfValidError(true);
        }
        if (!cpfValidation(inputs.cpf)) {
            Toast.show({
                type: 'error',
                text1: translate('INVALID_CPF'),
            });
            return setCpfValidError(true);
        }
        return setCpfValidError(false);
    }

    function handleValidCnpj() {
        if (!inputs.cnpj) {
            return setCnpjValidError(true);
        }
        if (!cnpjValidation(inputs.cnpj)) {
            Toast.show({
                type: 'error',
                text1: translate('INVALID_CNPJ'),
            });
            return setCnpjValidError(true);
        }
        return setCnpjValidError(false);
    }

    function handleValidCep(val: string) {
        if (val.length === 9) {
            setCepValidError(false);
            return;
        }
        if (val.length === 0) {
            setCepValidError(false);
            return;
        }
        setCepValidError(true);
    }

    function checkValidPassword(text?: string) {
        const inputPassword = text ? text : inputs.password;
        const regex = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z]).*$/;


        if (inputPassword && regex.test(inputPassword) || inputPassword === "") {
            setPasswordValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'longError',
                    text1: translate('invalidPassword'),
                });
            }
            setPasswordValidError(true);
            return false;
        }
    }

    function checkValidPasswordConfirmation(text?: string) {
        const inputPasswordConfirmation = text ? text : passwordConfirmation;

        if (inputPasswordConfirmation && inputPasswordConfirmation === inputs.password || inputPasswordConfirmation === "") {
            setPasswordConfirmationValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'error',
                    text1: translate('invalidPasswordConfirmation')
                });
            }
            setPasswordConfirmationValidError(true);
            return false;
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{userParam ? translate("editRegistration") : translate("registerUser")}</Text>
                    </View>
                </View>
                {canSeeUserDetails && canEditUser ?
                    <View style={styles.registerView}>
                        {validate() ?
                            <TouchableOpacity onPress={userParam ? updateUser : registerUser}>
                                <Text style={styles.register}>{translate("save")}</Text>
                            </TouchableOpacity> :
                            <Text style={styles.registerInvalid}>{translate("save")}</Text>
                        }
                    </View> : null}
            </View>

            <ScrollView
                bounces={false}
                style={{ height: 1 }}
            >
                <View style={styles.infosView}>
                    <View style={styles.viewInputs} >
                        <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('name')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser}
                            style={canSeeUserDetails && canEditUser ? styles.textInputs : styles.inputsDisabled}
                            value={inputs.name}
                            onChangeText={(text) => setInputs({ ...inputs, name: text })}
                        />
                        <Text style={styles.labelText}>{translate('email')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser}
                            onBlur={handleValidEmail}
                            style={canSeeUserDetails && canEditUser ?
                                !emailValidError ? styles.textInputs : styles.emptyTextInputs
                                : styles.inputsDisabled}
                            value={inputs.email}
                            onChangeText={(text) => setInputs({ ...inputs, email: text })}
                        />
                        {canCreateTrialUsers && authenticatedUser?.isFranchise ?
                            <View style={styles.switchItem}>
                                <Text style={styles.switchItemText}>{translate("trialUser")}</Text>
                                <Switch
                                    trackColor={{ false: '#767577', true: '#ADBCF9' }}
                                    color={inputs.demoExpirationDate ? '#5a7bf4' : '#f4f3f4'}
                                    onValueChange={(value) => {
                                        if (!value) {
                                            return setInputs({ ...inputs, demoExpirationDate: undefined });
                                        }
                                        setInputs({ ...inputs, demoExpirationDate: moment().add(7, 'day').toDate() });
                                    }}
                                    value={Boolean(inputs.demoExpirationDate)}
                                />
                            </View>
                            : null}
                        <ItemSeparator />
                        {!crmUser ?
                            <>
                                <Text style={styles.labelText}>{translate('password')}</Text>
                                <TextInput
                                    editable={canSeeUserDetails && canEditUser}
                                    style={canSeeUserDetails && canEditUser ?
                                        !passwordValidError ? styles.textInputs : styles.emptyTextInputs
                                        : styles.inputsDisabled}
                                    secureTextEntry
                                    onBlur={() => checkValidPassword()}
                                    value={inputs.password}
                                    onChangeText={(text) => {
                                        setInputs({ ...inputs, password: text }); checkValidPassword(text);
                                    }}
                                />
                                <Text style={styles.labelText}>{translate('passwordConfirm')}</Text>
                                <TextInput
                                    editable={canSeeUserDetails && canEditUser}
                                    style={canSeeUserDetails && canEditUser ?
                                        !passwordConfirmationValidError ? styles.textInputs : styles.emptyTextInputs
                                        : styles.inputsDisabled}
                                    onBlur={() => checkValidPasswordConfirmation()}
                                    secureTextEntry
                                    value={passwordConfirmation}
                                    onChangeText={(text) => {
                                        setPasswordConfirmation(text); checkValidPasswordConfirmation(text);
                                    }}
                                />
                            </>
                            : null}
                        <Text style={styles.labelText}>{translate('phoneNumber')}</Text>
                        <TextInputMask
                            editable={canSeeUserDetails && canEditUser}
                            type={'cel-phone'}
                            style={canSeeUserDetails && canEditUser ?
                                !phoneValidError ? styles.textInputs : styles.emptyTextInputs
                                : styles.inputsDisabled}
                            options={{
                                maskType: 'BRL',
                                withDDD: true,
                                dddMask: '(99) 9 9999-9999'
                            }}
                            value={inputs.phone}
                            onChangeText={(text) => {
                                setInputs({ ...inputs, phone: text }); handleValidPhone(text);
                            }}
                            maxLength={16}
                            keyboardType='numeric'
                            ref={numRef}
                        />
                        <Text style={styles.labelText}>{translate('userType')}</Text>
                        <DropDownPicker
                            open={dropDown}
                            value={userType}
                            items={[
                                { label: translate("individual"), value: UserType.Individual },
                                { label: translate("legalPerson"), value: UserType.Company }
                            ]}
                            disabled={!(canSeeUserDetails && canEditUser)}
                            style={canSeeUserDetails && canEditUser ? styles.dropDown : styles.dropDownDisabled}
                            dropDownContainerStyle={styles.sugestionView}
                            listItemContainerStyle={styles.drops}
                            selectedItemContainerStyle={{ backgroundColor: '#F3F4F5' }}
                            showTickIcon={false}
                            setOpen={setDropDown}
                            setValue={setUserType}
                        />
                        <Text style={styles.labelText}>{userType == UserType.Individual ? translate("cpf") : translate("cnpj")}</Text>
                        {userType == UserType.Individual ?
                            <TextInputMask
                                editable={canSeeUserDetails && canEditUser}
                                type={'cpf'}
                                style={canSeeUserDetails && canEditUser ?
                                    !cpfValidError ? styles.textInputs : styles.emptyTextInputs
                                    : styles.inputsDisabled}
                                options={{ mask: '999.999.999-99' }}
                                value={inputs.cpf}
                                onBlur={() => handleValidCpf()}
                                onChangeText={(text) => setInputs({ ...inputs, cpf: text })}
                                maxLength={14}
                                keyboardType='numeric'
                                ref={cpfRef}
                            /> :
                            <TextInputMask
                                editable={canSeeUserDetails && canEditUser}
                                type={'cnpj'}
                                style={canSeeUserDetails && canEditUser ?
                                    !cnpjValidError ? styles.textInputs : styles.emptyTextInputs
                                    : styles.inputsDisabled}
                                options={{ mask: '99.999.999/9999-99' }}
                                value={inputs.cnpj}
                                onBlur={() => handleValidCnpj()}
                                onChangeText={(text) => setInputs({ ...inputs, cnpj: text })}
                                maxLength={18}
                                keyboardType='numeric'
                                ref={cnpjRef}
                            />}
                        <Text style={styles.labelText}>{translate('cep')}</Text>
                        <TextInputMask
                            editable={canSeeUserDetails && canEditUser}
                            onBlur={canSeeUserDetails && canEditUser ? (loadZipCode) : undefined}
                            type={'zip-code'}
                            style={canSeeUserDetails && canEditUser ?
                                !cepValidError ? styles.textInputs : styles.emptyTextInputs : styles.inputsDisabled}
                            options={{
                                mask: '99.999-999'
                            }}
                            value={inputs.cep}
                            onChangeText={(text) => {
                                setInputs({ ...inputs, cep: text }); handleValidCep(text);
                            }}
                            maxLength={9}
                            keyboardType='numeric'
                            ref={cepRef}
                        />
                        <Text style={styles.labelText}>{translate('state')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser && !cep?.uf}
                            selectTextOnFocus={!cep?.uf}
                            value={cep?.uf ? cep.uf : inputs.state}
                            style={canSeeUserDetails && canEditUser ?
                                cep?.uf ? styles.inputsDisabled : styles.textInputs : styles.inputsDisabled}
                            onChangeText={(text) => setInputs({ ...inputs, state: text })}
                        />
                        <Text style={styles.labelText}>{translate('city')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser && !cep?.localidade}
                            selectTextOnFocus={!cep?.localidade}
                            value={cep?.localidade ? cep.localidade : inputs.city}
                            style={canSeeUserDetails && canEditUser ?
                                cep?.localidade ? styles.inputsDisabled : styles.textInputs : styles.inputsDisabled}
                            onChangeText={(text) => setInputs({ ...inputs, city: text })}
                        />
                        <Text style={styles.labelText}>{translate('district')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser && !cep?.bairro}
                            selectTextOnFocus={!cep?.bairro}
                            value={cep?.bairro ? cep.bairro : inputs.district}
                            style={canSeeUserDetails && canEditUser ?
                                cep?.bairro ? styles.inputsDisabled : styles.textInputs : styles.inputsDisabled}
                            onChangeText={(text) => setInputs({ ...inputs, district: text })}
                        />
                        <Text style={styles.labelText}>{translate('street')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser && !cep?.logradouro}
                            selectTextOnFocus={!cep?.logradouro}
                            value={cep?.logradouro ? cep.logradouro : inputs.street}
                            style={canSeeUserDetails && canEditUser ?
                                cep?.logradouro ? styles.inputsDisabled : styles.textInputs : styles.inputsDisabled}
                            onChangeText={(text) => setInputs({ ...inputs, street: text })}
                        />
                        <Text style={styles.labelText}>{translate('streetNumber')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser}
                            style={canSeeUserDetails && canEditUser ? styles.textInputs : styles.inputsDisabled}
                            value={inputs.streetNumber}
                            onChangeText={(text) => setInputs({ ...inputs, streetNumber: text })}
                        />
                        <Text style={styles.labelText}>{translate('complement')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser}
                            style={canSeeUserDetails && canEditUser ? styles.textInputs : styles.inputsDisabled}
                            value={inputs.complement}
                            onChangeText={(text) => setInputs({ ...inputs, complement: text })}
                        />
                        <Text style={styles.labelText}>{translate('observation')}</Text>
                        <TextInput
                            editable={canSeeUserDetails && canEditUser}
                            style={canSeeUserDetails && canEditUser ? styles.textInputs : styles.inputsDisabled}
                            multiline={true}
                            numberOfLines={4}
                            value={inputs.observation}
                            onChangeText={(text) => setInputs({ ...inputs, observation: text })}
                        />
                    </View>
                </View>
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    infosView: {
        width: "100%",
        paddingHorizontal: 10,
        alignItems: 'center'
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    subTitle: {
        fontSize: 12,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
        marginBottom: 10
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    sugestionView: {
        width: "100%",
        backgroundColor: '#fff',
        borderWidth: 0,
        alignSelf: 'center',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },
    viewInputs: {
        flexDirection: 'column',
        width: '100%',
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        width: "100%",
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    textAreaInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        width: "100%",
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        height: "100",
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginBottom: 10,
    },
    textAreaInputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        width: "100%",
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        height: "100",
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginBottom: 10,
    },
    emptyTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        width: "100%",
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        width: "100%",
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        cursor: 'default'
    },
    dropDown: {
        borderColor: "#9a9a9a4f",
        flexDirection: "row",
        width: "100%",
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dropDownDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        flexDirection: "row",
        width: "100%",
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    drops: {
        borderColor: "#9a9a9a4f",
        width: "100%",
        borderWidth: 1,
        borderTopWidth: 0,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15,
    },
    dropDownInput: {
        color: '#000',
        fontSize: 16,
    },
    dropDownIcon: {
        height: 7,
        width: 1
    },
    labelText: {
        fontSize: 16,
    },
    switchItemText: {
        fontSize: 16,
        paddingEnd: 20,
    },
    switchItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10
    },
});
