import React, { useState, useCallback, useEffect } from 'react';
import {
    View,
    Text,
    TextInput,
    StyleSheet,
    TouchableOpacity,
    NativeSyntheticEvent,
    TextInputKeyPressEventData
} from 'react-native';
import { translate } from '../../services/service-translate';
import { List } from 'react-native-paper';
import { platformAPI, invalidBearerToken, ClientError, UserData } from '../../services/platform-api';
import { useFocusEffect } from '@react-navigation/native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import Toast from 'react-native-toast-message';

interface ImpersonateProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}
const headerHeight = 60;

export default function ImpersonateUsers({ navigation, authenticatedUser }: ImpersonateProps) {
    const [userId, setUserId] = useState<string>();

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleImpersonate();
        }
    }

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    useEffect(() => {
        checkAuth();
    }, [authenticatedUser]);

    async function checkAuth() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();
            if (wl.id != 1 || !authenticatedUser.permissions.can_impersonate_users) {
                navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
            }
        }
    }

    async function handleImpersonate() {
        try {
            if (!userId) {
                return;
            }
            await platformAPI.impersonateUser(userId);
            window.location.href = '/cameras';
        } catch (err) {
            console.error(err);
            let message = 'UNEXPECTED_ERROR';
            if (err instanceof ClientError) {
                message = err.message;
            }
            Toast.show({
                type: 'error',
                text1: translate(message),
            });
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.backButtonView}>
                    <TouchableOpacity
                        style={styles.backButton}
                        onPress={() => navigation.navigate("Camera", { screen: "More" })}
                    >
                        <List.Icon icon='arrow-left' color="#fff" />
                    </TouchableOpacity>
                    <Text style={styles.titleHeader}>{translate('impersonateTitle')}</Text>
                </View>
            </View>
            <View style={styles.bodyView}>
                <View style={{ flexDirection: "row" }}>
                    <Text style={styles.textLabel}>User Id: </Text>
                    <TextInput
                        value={userId}
                        style={styles.textInput}
                        onChangeText={setUserId}
                        onKeyPress={handleKeyDown}
                        keyboardType='numeric'
                    />
                </View>
                <TouchableOpacity style={styles.impersonateButton} onPress={handleImpersonate}>
                    <Text style={styles.impersonateButtonText}>{translate('impersonate')}</Text>
                </TouchableOpacity>
            </View>
        </DefaultPageContainer>

    );
}
const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        alignSelf: 'flex-start',
        width: '100%',
        height: 60,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backButtonView: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    backArrow: {
        height: 25,
        width: 25,
        marginLeft: 15
    },
    backButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
        alignSelf: 'center'
    },
    bodyView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: headerHeight
    },
    textLabel: {
        fontSize: 16,
        fontWeight: '600'
    },
    textInput: {
        borderBottomColor: '#0071bc',
        borderBottomWidth: 1,
        width: 60,
        paddingBottom: 2,
        paddingHorizontal: 3,
        fontSize: 16,
        fontWeight: '600'
    },
    impersonateButton: {
        backgroundColor: "#0071bc",
        marginTop: 20,
        padding: 10,
        borderRadius: 24
    },
    impersonateButtonText: {
        fontSize: 14,
        color: "#fff",
        fontWeight: "600"
    }
});
