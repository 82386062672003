import React, { useCallback, useState } from "react";
import {
    View,
    StyleSheet,
    Image,
    ListRenderItemInfo,
    Text,
    TouchableOpacity
} from 'react-native';
import { FlatList } from "react-native-gesture-handler";
import { platformAPI, ChatGroup, invalidBearerToken, UserData } from "../services/platform-api";
import { translate } from '../services/service-translate';
import { useFocusEffect } from "@react-navigation/native";
import DefaultPageContainer from "../components/DefaultPageContainer";
import PersonalAlert from "../components/PersonalAlert";

interface ChatGroupsProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function ChatGroupComponent({ navigation, authenticatedUser }: ChatGroupsProps) {

    const [groups, setGroups] = useState<ChatGroup[]>([]);
    const [noChats, setNoChats] = useState(false);

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
            getMessages();
        }, [navigation])
    );

    async function getMessages() {
        try {
            const res = await platformAPI.getChatGroups();

            if (res.length == 0) {
                setNoChats(!noChats);
            }
            setGroups(res);

            if (res.length == 1) {
                navigation.navigate("Chat", { id: res[0].id });
            }

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function renderItem({ item }: ListRenderItemInfo<ChatGroup>) {
        return (
            <TouchableOpacity onPress={() => navigation.navigate("Chat", { id: item.id })}>
                <View style={styles.chatGroupView}>
                    <Text style={styles.chatGroupTitle}>{item.title}</Text>
                </View>
            </TouchableOpacity>
        );
    }

    return (
        <DefaultPageContainer excludeBottomPadding={true}>
            <View style={styles.header}>
                <Image
                    source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-topo.png' }}
                    style={styles.logo}
                />
            </View>
            {noChats ?
                <View style={styles.invalidView}>
                    <Text style={styles.invalidText}>{translate('noChat')}</Text>
                </View> :
                <>
                    <FlatList
                        data={Object(groups)}
                        renderItem={renderItem}
                    />
                </>
            }
            <PersonalAlert authenticatedUser={authenticatedUser} />
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: '#fff'
    },
    header: {
        backgroundColor: '#0071bc',
        height: 60,
        justifyContent: 'center'
    },
    logo: {
        width: 166,
        height: 32,
        alignSelf: 'center',
        justifyContent: 'center'
    },
    chatGroupView: {
        width: '100%',
        height: 60,
        borderBottomWidth: 1,
        borderBottomColor: "#cecece",
        justifyContent: 'space-between'
    },
    chatGroupTitle: {
        fontWeight: "500",
        fontSize: 20,
        fontFamily: 'sans serif',
        marginLeft: 15,
        marginTop: 8
    },
    chatGroupText: {
        marginLeft: 15,
        marginBottom: 8,
        color: "#626262"
    },
    invalidView: {
        justifyContent: 'center',
        flex: 1
    },
    invalidText: {
        fontSize: 16,
        fontWeight: '500',
        color: '#aeaeae',
        alignSelf: 'center',
        justifyContent: 'center',
        textAlignVertical: 'center',
        textAlign: 'center'
    }
});
