import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { GetUserAsaasPayments, platformAPI, UserData, invalidBearerToken } from '../../services/platform-api';
import { translate } from '../../services/service-translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import { List } from 'react-native-paper';

interface DisabledAccountProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function DisabledAccount({ navigation, authenticatedUser }: DisabledAccountProps) {
    const [overduePayment, setOverduePayment] = useState<GetUserAsaasPayments>();

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        getPayments();
    }, []);

    async function goBack() {
        await platformAPI.signOut();
        return navigation.navigate('Authentication');
    }

    async function getPayments() {
        if (authenticatedUser?.disabledReason !== 'OVERDUE_PAYMENT_ON_ASAAS') return;

        try {
            const userPayments = await platformAPI.getUserPayments();
            const overduePayment = userPayments.findIndex(item => item.status === 'OVERDUE');

            setOverduePayment(userPayments[overduePayment]);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }

            console.error(err);
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("logout")}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.container}>
                <View style={styles.contentContainer}>
                    <View>
                        <Text style={styles.textTitle}>{translate("disabledAccount")}</Text>
                        <Text style={styles.textContent}>
                            {translate(authenticatedUser?.disabledReason ? authenticatedUser.disabledReason : '')}
                        </Text>
                    </View>
                    {overduePayment?.invoiceUrl ?
                        <TouchableOpacity
                            style={styles.button}
                            onPress={() => window.open(overduePayment?.invoiceUrl, "_blank")}
                        >
                            <Text style={styles.buttonText}>Visualizar Fatura</Text>
                        </TouchableOpacity>
                        : null}
                </View>
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    contentContainer: {
        flex: 1,
        padding: 10,
        justifyContent: 'center',
        alignSelf: 'center'
    },
    header: {
        height: 60,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: 'flex-start',
    },
    titleHeaderView: {
        justifyContent: 'center'
    },
    titleHeader: {
        alignSelf: 'flex-start',
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
    },
    textContent: {
        fontSize: 16,
        textAlign: "justify",
        textAlignVertical: "center",
        marginBottom: 20
    },
    textTitle: {
        fontSize: 26,
        textAlignVertical: "center",
        fontWeight: "600",
    },
    button: {
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginTop: 10,
        cursor: 'pointer',
        paddingVertical: 10,
        paddingHorizontal: 20
    },
    buttonText: {
        color: "#0071bc",
        fontSize: 20,
        textAlign: 'right',
        fontWeight: '500',
    },
});

