/* eslint-disable guard-for-in */
import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ScrollView
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    Predictions,
    Plan,
    RentalData,
    ServicePackagesPredictions,
    Rentals,
} from "../../../services/platform-api";
import moment from "moment";
import { getDefaultLanguage, translate } from "../../../services/service-translate";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

const headerSize = 60;
const footerSize = 30;

interface CrmPredictionsComponentProps {
    navigation: Navigation;
    route: {
        params: Predictions;
    };
    authenticatedUser?: UserData;
}

export default function CrmPredictions({
    navigation, route, authenticatedUser
}: CrmPredictionsComponentProps) {
    const [loading, setLoading] = useState(false);
    const [usages, setUsages] = useState<Predictions["usages"]>([]);
    const [storages, setStorages] = useState<Predictions["storages"]>([]);
    const [rentals, setRentals] = useState<{ [key: string]: RentalData; }>();
    const [rentalPackages, setRentalPackages] = useState<{ [key: string]: RentalData; }>();
    const [rentalPackagesLenght, setRentalPackagesLenght] = useState<number>(0);
    const [planPackages, setPlanPackages] = useState<Predictions["planPackages"]>([]);
    const [servicePackages, setServicePackages] = useState<ServicePackagesPredictions[]>([]);
    const [accumulateds, setAccumulateds] = useState<Predictions["accumulateds"]>([]);
    const [accumulated, setAccumulated] = useState<number>(0);
    const [planDiscounts, setPlanDiscounts] = useState<Predictions["planDiscounts"]>([]);
    const [serviceDiscounts, setServiceDiscounts] = useState<Predictions["serviceDiscounts"]>([]);
    const [rentalsLength, setRentalsLength] = useState<number>(0);
    const [royalties, setRoyalties] = useState<Predictions["royalties"]>([]);
    const [marketingFeeTotal, setMarketingFeeTotal] = useState<Predictions["marketingFeeTotal"]>();
    const [royaltiesValue, setRoyaltiesValue] = useState<number>(0);
    const [horus, setHorus] = useState<number>(0);
    const [usage, setUsage] = useState<number>(0);
    const [storage, setStorage] = useState<number>(0);
    const [planPackage, setPlanPackage] = useState<number>(0);
    const [servicePackage, setServicePackage] = useState<number>(0);
    const [planDiscount, setPlanDiscount] = useState<number>(0);
    const [serviceDiscount, setServiceDiscount] = useState<number>(0);
    const [generic, setGeneric] = useState<Predictions["generic"]>([]);
    const [plans, setPlans] = useState<Plan[]>([]);

    useEffect(() => {
        momentHeader();
    }, []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            document.body.style.position = 'fixed';
        });
        return unsubscribe;
    }, [navigation, route]);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (!authenticatedUser.permissions.can_see_platform_payment_info || wl.id == 1) {
                return navigation.navigate("Camera", { screen: "More" });
            }

            loadPredictions(wl.id);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    async function momentHeader() {

        const lang = await getDefaultLanguage();

        if (lang === 'pt') return moment.locale('pt-br');

        if (lang === 'es') return moment.locale('es');

        moment.locale('en');
    }

    async function loadPredictions(wlId: number) {
        setLoading(true);
        try {
            const predictions = await platformAPI.getCurrentCharge(wlId);
            const plans = await platformAPI.getAllPlans();
            setPlans(plans);

            const data = predictions;

            let rentalsLength = 0;
            let rentalPackagesLength = 0;

            const planDiscounts: Predictions["planDiscounts"] = [];
            for (const discount of data.planDiscounts) {
                planDiscounts[discount.PlanId] = planDiscounts[discount.PlanId] || {
                    PlanId: discount.PlanId,
                    p2p: discount.p2p,
                    description: discount.description,
                    quantity: 0,
                    total: 0,
                };

                planDiscounts[discount.PlanId].quantity += discount.quantity;
                planDiscounts[discount.PlanId].total += discount.total;
            }

            data.planDiscounts = [];
            for (const planId in planDiscounts) {
                data.planDiscounts.push(planDiscounts[planId]);
            }

            const rentals: Rentals = {};
            for (const rentalValue in data.rentals) {
                for (const rental in data.rentals[rentalValue]) {
                    rentals[rentalValue + " " + rental] = data.rentals[rentalValue][rental];
                }
                rentalsLength += 1;
            }

            const rentalPackages: Rentals = {};
            for (const whitelabelPlan in data.rentalPackages) {
                for (const rentalPackageValue in data.rentalPackages[whitelabelPlan]) {
                    rentalPackages[whitelabelPlan + " " + rentalPackageValue] = data.rentalPackages[whitelabelPlan][rentalPackageValue];
                }
                rentalPackagesLength += 1;
            }

            const rentalsObj = rentals;
            const rentalPackagesObj = rentalPackages;

            setUsages(data.usages);

            setStorages(data.storages);

            setRoyaltiesValue(data.royaltiesValue);

            setRentals(rentalsObj);

            setRentalPackages(rentalPackagesObj);

            setRentalPackagesLenght(rentalPackagesLength);

            setPlanPackages(data.planPackages);

            setServicePackages(data.servicePackages);

            setAccumulateds(data.accumulateds);

            setAccumulated(data.accumulated);

            setPlanDiscounts(data.planDiscounts);

            setServiceDiscounts(data.serviceDiscounts);

            setRentalsLength(rentalsLength);

            setRoyalties(data.royalties);

            setMarketingFeeTotal(data.marketingFeeTotal);

            setHorus(data.horus);

            setUsage(data.usage);

            setStorage(data.storage);

            setPlanPackage(data.planPackage);

            setServicePackage(data.servicePackage);

            setPlanDiscount(data.planDiscount);

            setServiceDiscount(data.serviceDiscount);

            setGeneric(data.generic);

            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("CrmChargesList");
    }

    return (
        <DefaultPageContainer>
            <View style={styles.backgroundView}>
                <View style={styles.header}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                        <TouchableOpacity onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{
                                moment().format("MMMM/YYYY").toUpperCase()
                            }</Text>
                        </View>
                    </View>
                </View>
                <ScrollView style={{ height: 1, paddingBottom: 34 }}>
                    {accumulated > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("CHARGE_ACCUMULATED")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("GENERIC_CHARGE")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("month")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {accumulateds?.map(accumulated => <>
                                <View style={styles.bodyInformation}>
                                    <View style={{ width: "60%" }}>
                                        <Text style={styles.propTextListDescription}>{accumulated.charge}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{accumulated.month}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{accumulated.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                    </View>
                                </View>
                                <View style={styles.itemSeparator} />
                            </>)}

                        </> : null}
                    {usage > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("cloudServices")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {usages?.map(item => <>
                                <View style={styles.bodyInformation}>
                                    <View style={{ width: "60%" }}>
                                        <Text style={styles.propTextListDescription}>{translate(item.name)}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{item.unique_cameras}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                    </View>
                                </View>
                                <View style={styles.itemSeparator} />
                            </>)}

                        </> : null}
                    {storage > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("cloudStorage")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {storages?.map(storage =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(storage.name)} {storage.isP2p ? '(P2P)' : ''}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{storage.unique_cameras}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{storage.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {royaltiesValue > 0 && royalties ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("royalties")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "40%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("value")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {royalties.map(royalty =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "40%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(royalty.description)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{royalty.quantity}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{royalty.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{royalty.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}

                        </> : null}
                    {marketingFeeTotal?.total && marketingFeeTotal?.total > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("platform")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextListDescription}>{translate("MARKETING_FEE")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{marketingFeeTotal.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />
                        </> : null}
                    {planPackage > 0 && planPackages ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("packages")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {planPackages.map(pack => {
                                const plan = plans.find(plan => plan.id === pack.PlanId);
                                return (
                                    <>
                                        <View style={styles.bodyInformation}>
                                            <View style={{ width: "60%" }}>
                                                <Text style={styles.propTextListDescription}>{plan?.name ? translate(plan.name) : ''}</Text>
                                            </View>
                                            <View style={{ width: "20%" }}>
                                                <Text style={styles.propTextList}>{pack.cameras}</Text>
                                            </View>
                                            <View style={{ width: "20%" }}>
                                                <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.itemSeparator} />
                                    </>
                                );
                            }
                            )}
                        </> : null}
                    {servicePackage > 0 && servicePackages ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("servicePacks")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {servicePackages.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(`CLOUD_SERVICE_${pack.type}`)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.cameras}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>

                            )}
                        </> : null}
                    {rentals && rentalsLength > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("rentals")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {Object.entries(rentals).map(([key, val]) =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>
                                                {`${translate(key.split(" ")[0])} ${Number(key.split(" ")[1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                            </Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{val.amount}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{(val.value * val.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {rentalPackages && rentalPackagesLenght > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("rentalPackages")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {Object.entries(rentalPackages).map(([key, val]) =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>
                                                {`${translate(key.split(" ")[0])} ${Number(key.split(" ")[1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                            </Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{val.amount}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{(val.value * val.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {horus && horus > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("GENERIC_HORUS")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextListDescription}>{translate("GENERIC_HORUS")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{horus.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />


                        </> : null}
                    {planDiscount ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("packageDiscount")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {planDiscounts?.map(pack => {
                                const plan = plans.find(plan => plan.id === pack.PlanId);
                                return (
                                    <>
                                        <View style={styles.bodyInformation}>
                                            <View style={{ width: "60%" }}>
                                                <Text style={styles.propTextListDescription}>{plan ? translate(plan.name) : ''}</Text>
                                            </View>
                                            <View style={{ width: "20%" }}>
                                                <Text style={styles.propTextList}>{pack.quantity}</Text>
                                            </View>
                                            <View style={{ width: "20%" }}>
                                                <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.itemSeparator} />
                                    </>
                                );
                            }
                            )}
                        </> : null}
                    {serviceDiscount ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("serviceDiscounts")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {serviceDiscounts?.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(`CLOUD_SERVICE_${pack.type}`)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.camera}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {generic?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("others")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {generic.map(item =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "80%" }}>
                                            <Text style={styles.propTextListDescription}>{item.data.translate.description}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={styles.loading}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    topInformation: {
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 7,
        paddingLeft: 20,
        justifyContent: "center",
        width: "100%"
    },
    bodyInformation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginVertical: 7,
        paddingLeft: 20
    },
    propTextTitle: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        paddingTop: 20,
    },
    propTextDescription: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        justifyContent: "flex-start"
    },
    propText: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        justifyContent: "center"
    },
    propTextListDescription: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        justifyContent: "flex-start"
    },
    propTextList: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        justifyContent: "center"
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
});
