export function cpfValidation(cpf: string) {

    const newValue = cpf.replace(/[^\d]+/g, '');

    if (newValue == '' || newValue.length !== 11) {
        return false;
    }
    const regex = /^(0{11}|1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11})$/;

    if (regex.test(newValue)) {
        return false;
    }

    let sum = 0;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(newValue.substring(i - 1, i)) * (11 - i);
    }

    let remainder = (sum * 10) % 11;

    if (remainder == 10 || remainder == 11) {
        remainder = 0;
    }

    if (remainder != parseInt(newValue.substring(9, 10))) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum += parseInt(newValue.substring(i - 1, i)) * parseInt(12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder == 10 || remainder == 11) {
        remainder = 0;
    }

    if (remainder != parseInt(newValue.substring(10, 11))) {
        return false;
    }

    return true;

}

export function cnpjValidation(cnpj: string) {

    const newValue = cnpj.replace(/[^\d]+/g, '');

    if (newValue == '') {
        return false;
    }

    if (newValue.length != 14) {
        return false;
    }

    const regex = /^(0{14}|1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14})$/;

    if (regex.test(newValue)) {
        return false;
    }

    let size = newValue.length - 2;
    let numbers = newValue.substring(0, size);
    const digits = newValue.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0)) {
        return false;
    }

    size = size + 1;
    numbers = newValue.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1)) {
        return false;
    }

    return true;
}
