/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ScrollView,
    Image
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    Predictions,
    DetailedCharge,
} from "../../../services/platform-api";
import moment from "moment";
import { getDefaultLanguage, translate } from "../../../services/service-translate";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

const headerSize = 60;
const footerSize = 30;

interface CrmDetailedChargeComponentProps {
    navigation: Navigation;
    route: {
        params: Predictions;
    };
    authenticatedUser?: UserData;
}

interface ChargeItemsType {
    usagesAndStorage: {
        name: string;
        p2p: number | undefined;
        unique_cameras: number | undefined;
        total: number;
    }[];
    rentalsAndrentalPackages: {
        [key: string]: {
            amount: number;
            value: number;
        };
    };
    planPackages: {
        description: string;
        PlanId: number | undefined;
        cameras: number | undefined;
        total: number;
    }[];
    servicePackages: {
        type: string;
        cameras: number | undefined;
        total: number;
    }[];
    accumulateds: {
        charge: string | undefined;
        month: number | undefined;
        total: number;
    }[];
    planDiscounts: {
        description: string;
        p2p: number | undefined;
        PlanId: number;
        quantity: number;
        total: number;
    }[];
    serviceDiscounts: {
        type: string;
        camera: number | undefined;
        amount: number;
        total: number;
    }[];
    generic: {
        description: string;
        total: number;
    }[];
    royalty: {
        description: string;
        quantity: number;
        value: number;
        total: number;
    } | undefined;
}

export default function CrmDetailedCharge({
    navigation, route, authenticatedUser
}: CrmDetailedChargeComponentProps) {
    const chargeParam = route.params;
    const [detailedCharge, setDetailedCharge] = useState<DetailedCharge>();
    const [loading, setLoading] = useState(false);
    const [usages, setUsages] = useState<ChargeItemsType["usagesAndStorage"]>();
    const [storages, setStorages] = useState<ChargeItemsType["usagesAndStorage"]>();
    const [rentals, setRentals] = useState<ChargeItemsType["rentalsAndrentalPackages"]>();
    const [rentalPackages, setRentalPackages] = useState<ChargeItemsType["rentalsAndrentalPackages"]>();
    const [rentalPackagesLenght, setRentalPackagesLenght] = useState<number>(0);
    const [planPackages, setPlanPackages] = useState<ChargeItemsType["planPackages"]>();
    const [servicePackages, setServicePackages] = useState<ChargeItemsType["servicePackages"]>();
    const [accumulateds, setAccumulateds] = useState<ChargeItemsType["accumulateds"]>();
    const [planDiscounts, setPlanDiscounts] = useState<ChargeItemsType["planDiscounts"]>();
    const [serviceDiscounts, setServiceDiscounts] = useState<ChargeItemsType["serviceDiscounts"]>();
    const [generic, setGeneric] = useState<ChargeItemsType["generic"]>();
    const [rentalsLength, setRentalsLength] = useState<number>(0);
    const [royalty, setRoyalty] = useState<ChargeItemsType["royalty"] | undefined>(undefined);
    const [marketingFeeTotal, setMarketingFeeTotal] = useState<number>(0);
    const [horus, setHorus] = useState<number>(0);

    useEffect(() => {
        momentHeader();
    }, []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            document.body.style.position = 'fixed';
        });
        return unsubscribe;
    }, [navigation, route]);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (!authenticatedUser.permissions.can_see_platform_payment_info || wl.id == 1) {
                return navigation.navigate("Camera", { screen: "More" });
            }

            loadCharges();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    async function momentHeader() {

        const lang = await getDefaultLanguage();

        if (lang === 'pt') return moment.locale('pt-br');

        if (lang === 'es') return moment.locale('es');

        moment.locale('en');
    }

    async function loadCharges() {
        setLoading(true);
        try {
            const detailedCharge = await platformAPI.getDetailedCharge(chargeParam.id);
            const plans = await platformAPI.getAllPlans();
            setDetailedCharge(detailedCharge);

            const rentalType = await platformAPI.getRentalTypes();

            const usages: ChargeItemsType["usagesAndStorage"] = [];
            const storages: ChargeItemsType["usagesAndStorage"] = [];
            const rentals: ChargeItemsType["rentalsAndrentalPackages"] = {};
            const rentalPackages: ChargeItemsType["rentalsAndrentalPackages"] = {};
            const planPackages: ChargeItemsType["planPackages"] = [];
            const servicePackages: ChargeItemsType["servicePackages"] = [];
            const accumulateds: ChargeItemsType["accumulateds"] = [];
            const planDiscounts: ChargeItemsType["planDiscounts"] = [];
            const serviceDiscounts: ChargeItemsType["serviceDiscounts"] = [];
            const generic: ChargeItemsType["generic"] = [];
            let royalty: ChargeItemsType["royalty"] = undefined;
            let rentalsLength = 0;
            let rentalPackagesLength = 0;
            let marketingFeeTotal = 0;
            let horus = 0;

            for (const item of detailedCharge.ChargeItems) {

                if ((item.group == "ROYALTY")) {
                    royalty = {
                        description: "GENERIC_USERS",
                        quantity: Number(item.quantity),
                        value: Number(item.value),
                        total: Number(item.quantity) * Number(item.value)
                    };
                    continue;
                }

                if (item.description == "MARKETING_FEE") {
                    marketingFeeTotal = Number(item.quantity) * Number(item.value);
                    continue;
                }

                if (item.description == "GENERIC_HORUS") {
                    horus = Number(item.quantity) * Number(item.value);
                    continue;
                }

                if (item.description == "CHARGE_ACCUMULATED" && Number(item.value) > 0) {
                    accumulateds.push({
                        charge: item.data.charge,
                        month: item.data.month,
                        total: Number(item.value)
                    });
                    continue;
                }

                if (item.description == "PACKAGE_VALUE" && Number(item.value) > 0) {
                    if (item.data.type) {
                        servicePackages.push({
                            type: item.data.type,
                            cameras: item.data.cameras,
                            total: Number(item.value)
                        });
                        continue;
                    }

                    if (item.data.hasOwnProperty('PlanId')) {
                        const plan = plans.find(plan => plan.id === item.data.PlanId);
                        planPackages.push({
                            description: plan ? plan.name : '',
                            PlanId: item.data.PlanId,
                            cameras: item.data.cameras,
                            total: Number(item.value)
                        });
                        continue;
                    }

                }

                if (rentalType.includes(item.description)) {
                    rentals[item.description + " " + Number(item.value).toString()] = {
                        amount: Number(item.quantity),
                        value: Number(item.value)
                    };
                    rentalsLength += 1;
                    continue;
                }

                if (item.description.includes("RENTAL_PACKAGE_")) {
                    rentalPackages[item.description + " " + Number(item.value).toString()] = {
                        amount: Number(item.quantity),
                        value: Number(item.value)
                    };
                    rentalPackagesLength += 1;
                    continue;
                }

                if (item.description.includes("CLOUD_SERVICE_") && item.group != 'DISCOUNT') {
                    usages.push({
                        name: item.description,
                        p2p: item.data.p2p ? item.data.p2p : undefined,
                        unique_cameras: item.data.cameras,
                        total: Number(item.quantity) * Number(item.value)
                    });
                    continue;
                }

                if (item.group == "STORAGE") {
                    storages.push({
                        name: item.description,
                        p2p: item.data.p2p ? item.data.p2p : undefined,
                        unique_cameras: item.data.cameras,
                        total: Number(item.quantity) * Number(item.value)
                    });
                    continue;
                }

                if (item.group == 'DISCOUNT') {
                    if (item.description.includes("CLOUD_SERVICE_")) {
                        serviceDiscounts.push({
                            type: item.description,
                            camera: item.data.cameras,
                            amount: Number(item.quantity),
                            total: Number(item.quantity) * Number(item.value)
                        });
                        continue;
                    }
                    if (item.data.PlanId) {
                        const plan = plans.find(plan => plan.id === item.data.PlanId);
                        planDiscounts.push({
                            description: plan ? plan.name : '',
                            p2p: item.data.p2p,
                            PlanId: item.data.PlanId,
                            quantity: Number(item.data.cameras),
                            total: Number(item.quantity) * Number(item.value),
                        });
                        continue;
                    }
                }

                generic.push({
                    description: item.data.translate ? translate(item.description, item.data.translate).toString() : translate(item.description).toString(),
                    total: Number(item.quantity) * Number(item.value)
                });
            }

            setUsages(usages);

            setStorages(storages);

            setRentals(rentals);

            setRentalPackages(rentalPackages);

            setRentalPackagesLenght(rentalPackagesLength);

            setPlanPackages(planPackages);

            setServicePackages(servicePackages);

            setAccumulateds(accumulateds);

            setPlanDiscounts(planDiscounts);

            setServiceDiscounts(serviceDiscounts);

            setGeneric(generic);

            setRentalsLength(rentalsLength);

            setRoyalty(royalty);

            setMarketingFeeTotal(marketingFeeTotal);

            setHorus(horus);

            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    function goBack() {
        setDetailedCharge(undefined);

        navigation.navigate("CrmChargesList");
    }

    async function downloadDetailedCharge() {
        if (!detailedCharge) return;
        try {
            await platformAPI.downloadDetailedCharge(chargeParam.id);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.backgroundView}>
                <View style={styles.header}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                        <TouchableOpacity onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{
                                moment(detailedCharge?.createdAt).format("MMMM/YYYY").toUpperCase()
                            }</Text>
                        </View>
                    </View>
                    {chargeParam ?
                        <TouchableOpacity
                            onPress={() => downloadDetailedCharge()}
                            style={{ justifyContent: "flex-end" }}>
                            <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_arrow_down.png' }} style={styles.downloadLogo} />
                        </TouchableOpacity> : null
                    }
                </View>
                <ScrollView style={{ height: 1, paddingBottom: 34 }}>
                    {accumulateds?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("CHARGE_ACCUMULATED")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("GENERIC_CHARGE")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("month")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {accumulateds.map(accumulated => <>
                                <View style={styles.bodyInformation}>
                                    <View style={{ width: "60%" }}>
                                        <Text style={styles.propTextListDescription}>{accumulated.charge}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{accumulated.month}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{accumulated.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                    </View>
                                </View>
                                <View style={styles.itemSeparator} />
                            </>)}

                        </> : null}
                    {usages?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("cloudServices")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {usages.map(item => <>
                                <View style={styles.bodyInformation}>
                                    <View style={{ width: "60%" }}>
                                        <Text style={styles.propTextListDescription}>{translate(item.name)} {item.p2p ? '(P2P)' : ''}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{item.unique_cameras}</Text>
                                    </View>
                                    <View style={{ width: "20%" }}>
                                        <Text style={styles.propTextList}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                    </View>
                                </View>
                                <View style={styles.itemSeparator} />
                            </>)}

                        </> : null}
                    {storages && storages?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("cloudStorage")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {storages.map(storage =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(storage.name)} {storage.p2p ? '(P2P)' : ''}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{storage.unique_cameras}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{storage.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {royalty && royalty.total > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("royalties")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "40%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("value")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "40%" }}>
                                    <Text style={styles.propTextListDescription}>{translate(royalty.description)}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{royalty.quantity}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{royalty.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{royalty.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                        </> : null}
                    {marketingFeeTotal && marketingFeeTotal > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("platform")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextListDescription}>{translate("MARKETING_FEE")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{marketingFeeTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />
                        </> : null}
                    {planPackages?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("packages")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {planPackages.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(pack.description)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.cameras}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {servicePackages?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("servicePacks")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {servicePackages.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(`CLOUD_SERVICE_${pack.type}`)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.cameras}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>

                            )}
                        </> : null}
                    {rentals && rentalsLength > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("rentals")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {Object.entries(rentals).map(([key, val]) =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>
                                                {`${translate(key.split(" ")[0])} ${Number(key.split(" ")[1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                            </Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{val.amount}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{(val.value * val.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {rentalPackages && rentalPackagesLenght > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("rentalPackages")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("quantity")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {Object.entries(rentalPackages).map(([key, val]) =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>
                                                {`${translate(key.split(" ")[0])} ${Number(key.split(" ")[1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                            </Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{val.amount}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{(val.value * val.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {horus > 0 ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("GENERIC_HORUS")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextListDescription}>{translate("GENERIC_HORUS")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propTextList}>{horus.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />


                        </> : null}
                    {planDiscounts?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("packageDiscount")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("plan")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {planDiscounts.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(pack.description)} {pack.p2p ? '(P2P)' : ''}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.quantity}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {serviceDiscounts?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("serviceDiscounts")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "60%" }}>
                                    <Text style={styles.propTextDescription}>{translate("type")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("cameras")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {serviceDiscounts.map(pack =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "60%" }}>
                                            <Text style={styles.propTextListDescription}>{translate(pack.type)}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.camera}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{pack.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}
                    {generic?.length ?
                        <>
                            <View style={styles.topInformation}>
                                <Text style={styles.propTextTitle}>{translate("others")}</Text>
                            </View>
                            <View style={styles.itemSeparator} />

                            <View style={styles.bodyInformation}>
                                <View style={{ width: "80%" }}>
                                    <Text style={styles.propTextDescription}>{translate("description")}</Text>
                                </View>
                                <View style={{ width: "20%" }}>
                                    <Text style={styles.propText}>{translate("total")}</Text>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />

                            {generic.map(item =>
                                <>
                                    <View style={styles.bodyInformation}>
                                        <View style={{ width: "80%" }}>
                                            <Text style={styles.propTextListDescription}>{item.description}</Text>
                                        </View>
                                        <View style={{ width: "20%" }}>
                                            <Text style={styles.propTextList}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemSeparator} />
                                </>
                            )}
                        </> : null}

                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={styles.loading}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    topInformation: {
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 7,
        paddingLeft: 20,
        justifyContent: "center",
        width: "100%"
    },
    bodyInformation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginVertical: 7,
        paddingLeft: 20,
    },
    propTextTitle: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        paddingTop: 20,
    },
    propTextDescription: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        justifyContent: "flex-start"
    },
    propText: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
        justifyContent: "center"
    },
    propTextListDescription: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        justifyContent: "flex-start"
    },
    propTextList: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        justifyContent: "center"
    },
    ListInfo: {
        flexDirection: 'row',
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        height: 72,
        paddingVertical: 0,
        paddingHorizontal: 20,
    },
    propList: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center"
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    downloadLogo: {
        width: 35,
        height: 25,
        alignSelf: 'center',
        tintColor: "#fff",
        margin: 20
    },
});
