import React, { useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    TextInput
} from "react-native";
import { TimePickerModal, DatePickerModal } from "react-native-paper-dates";
import { Checkbox } from "react-native-paper";
import {
    LprCamera
} from "../services/platform-api";
import { FilterProps } from "../pages/config/LicensePlateRecognitionList";
import { translate } from "../services/service-translate";
import moment from "moment";
import DropDownPicker from "react-native-dropdown-picker";

const headerSize = 60;

interface LPRFiltersProps {
    filter: FilterProps;
    dropDown: boolean;
    lprCameras: LprCamera[];
    toggleDropDown: () => void;
    clearFilters: () => void;
    setFilter: React.Dispatch<React.SetStateAction<FilterProps>>;
    handleFilterResults: () => void;
    handleCameraSearch: (camera: LprCamera) => void;
    toggleModal: () => void;
}

export default function LPRFilters({ filter, dropDown, lprCameras, toggleDropDown, clearFilters, setFilter, handleFilterResults, handleCameraSearch, toggleModal }: LPRFiltersProps) {
    const [startDateModal, setStartDateModal] = useState(false);
    const [endDateModal, setEndDateModal] = useState(false);
    const [startTimeModal, setStartTimeModal] = useState(false);
    const [endTimeModal, setEndTimeModal] = useState(false);
    const [value, setValue] = useState(null);

    function emptyDropdownSearch() {
        return (
            <Text
                style={{
                    textAlign: "center",
                    paddingVertical: 6,
                    color: '#818181'
                }}
            >
                {translate('invalidDropdownSearch')}
            </Text>
        );
    }

    return (
        <View style={{ height: window.innerHeight - headerSize, backgroundColor: "#fff" }}>

            <Text style={styles.filterLabel}>{translate('cameraFilter')}</Text>
            <DropDownPicker
                open={dropDown}
                value={value}
                setValue={setValue}
                searchPlaceholder={translate('dropSearchPlaceholder')}
                items={lprCameras.map(camera => {
                    return { label: camera.title, value: camera.id };
                })}
                style={styles.dropDownButton}
                listItemContainerStyle={styles.listItemContainerStyle}
                dropDownContainerStyle={styles.dropDownContainerStyle}
                searchContainerStyle={styles.searchContainerStyle}
                ListEmptyComponent={emptyDropdownSearch}
                placeholder={translate('select')}
                placeholderStyle={{ color: '#818181'}}
                onSelectItem={(item) => handleCameraSearch({ title: String(item.label), id: Number(item.value) })}
                searchTextInputStyle={{ width: "100%", height: "100%", borderWidth: 0, marginLeft: 0, backgroundColor: "#fff" }}
                textStyle={{ marginLeft: 10 }}
                searchable={true}
                setOpen={toggleDropDown}
            />
            <Text style={styles.filterLabel}>{translate('plateFilter')}</Text>
            <TextInput
                style={styles.textInput}
                placeholder={translate('search')}
                placeholderTextColor="#818181"
                value={filter.plate}
                onChangeText={(text) => setFilter({
                    ...filter,
                    plate: text
                })}
            />
            <View style={styles.modalContainer}>
                <Text style={styles.textFilterPeriod}>{translate('periodFilter')}</Text>
                <View style={styles.viewDateTitle}>
                    <View style={styles.datePickerContainer}>
                        <Text>{translate("from")}:</Text>
                        <View style={styles.datePickerButtonsContainer}>
                            <View style={styles.viewDateBox}>
                                <View style={styles.dateTimeFilterContainer}>
                                    <TouchableOpacity style={styles.dateContainer} onPress={() => setStartDateModal(!startDateModal)}>
                                        <View style={styles.dateContainer}>
                                            <Text style={styles.startTime}>{translate("date")}: {moment(filter.startDate).format('DD/MM/YYYY')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <DatePickerModal
                                    locale={translate("lang")}
                                    mode="single"
                                    label={translate("date")}
                                    visible={startDateModal}
                                    date={filter.startDate}
                                    onDismiss={() => {
                                        setStartDateModal(false);
                                    }}
                                    onConfirm={(d) => {
                                        if (!d.date) {
                                            setStartDateModal(false);
                                            return;
                                        }
                                        const newStart = filter.startDate;
                                        newStart.setDate(d.date.getDate());
                                        newStart.setMonth(d.date.getMonth());
                                        newStart.setFullYear(d.date.getFullYear());
                                        setFilter({
                                            ...filter,
                                            startDate: newStart
                                        });
                                        setStartDateModal(false);
                                    }}
                                    validRange={{
                                        endDate: filter.endDate
                                    }}
                                />
                            </View>
                            <View style={styles.viewDateBox}>
                                <View style={styles.dateTimeFilterContainer}>
                                    <TouchableOpacity style={styles.dateContainer} onPress={() => setStartTimeModal(!startTimeModal)}>
                                        <View style={styles.dateContainer}>
                                            <Text style={styles.startTime}>{translate("hour")}: {moment(filter.startDate).format('HH:mm')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <TimePickerModal
                                    label={translate('hour')}
                                    cancelLabel={translate('cancel')}
                                    hours={filter.startDate.getHours()}
                                    minutes={filter.startDate.getMinutes()}
                                    visible={startTimeModal}
                                    onDismiss={() => setStartTimeModal(false)}
                                    onConfirm={(hoursAndMinutes) => {
                                        setStartTimeModal(false);
                                        const newStart = filter.startDate;
                                        newStart.setHours(hoursAndMinutes.hours);
                                        newStart.setMinutes(hoursAndMinutes.minutes);
                                        setFilter({
                                            ...filter,
                                            startDate: newStart
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={styles.datePickerContainer}>
                        <Text>{translate("to")}:</Text>
                        <View style={styles.datePickerButtonsContainer}>
                            <View style={styles.viewDateBox}>
                                <View style={styles.dateTimeFilterContainer}>
                                    <TouchableOpacity style={styles.dateContainer} onPress={() => setEndDateModal(!endDateModal)}>
                                        <View style={styles.dateContainer}>
                                            <Text style={styles.startTime}>{translate("date")}: {moment(filter.endDate).format('DD/MM/YYYY')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <DatePickerModal
                                    locale={translate("lang")}
                                    mode="single"
                                    label={translate("date")}
                                    visible={endDateModal}
                                    date={filter.endDate}
                                    onDismiss={() => {
                                        setEndDateModal(false);
                                    }}
                                    onConfirm={(d) => {
                                        if (!d.date) {
                                            setEndDateModal(false);
                                            return;
                                        }
                                        const newEnd = filter.endDate;
                                        newEnd.setDate(d.date.getDate());
                                        newEnd.setMonth(d.date.getMonth());
                                        newEnd.setFullYear(d.date.getFullYear());
                                        setFilter({
                                            ...filter,
                                            endDate: newEnd
                                        });
                                        setEndDateModal(false);
                                    }}
                                    validRange={{
                                        startDate: filter.startDate,
                                        endDate: new Date()
                                    }}
                                />
                            </View>
                            <View style={styles.viewDateBox}>
                                <View style={styles.dateTimeFilterContainer}>
                                    <TouchableOpacity style={styles.dateContainer} onPress={() => setEndTimeModal(!endTimeModal)}>
                                        <View style={styles.dateContainer}>
                                            <Text style={styles.startTime}>{translate("hour")}: {moment(filter.endDate).format('HH:mm')}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <TimePickerModal
                                    label={translate('finishModal')}
                                    cancelLabel={translate('cancel')}
                                    hours={filter.endDate.getHours()}
                                    minutes={filter.endDate.getMinutes()}
                                    visible={endTimeModal}
                                    onDismiss={() => setEndTimeModal(false)}
                                    onConfirm={(hoursAndMinutes) => {
                                        setEndTimeModal(false);
                                        const newEnd = filter.endDate;
                                        newEnd.setHours(hoursAndMinutes.hours);
                                        newEnd.setMinutes(hoursAndMinutes.minutes);
                                        setFilter({
                                            ...filter,
                                            endDate: newEnd
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.checkView}>
                    <Checkbox
                        onPress={() => setFilter({
                            ...filter,
                            blacklist: !filter.blacklist,
                        })}
                        status={filter.blacklist ? 'checked' : 'unchecked'}
                    />
                    <Text style={styles.checkText}>{'Blacklist'}</Text>
                </View>
                <View style={styles.bottomView}>
                    <View style={styles.buttonClean}>
                        <TouchableOpacity
                            onPress={clearFilters}
                        >
                            <Text style={styles.textClean}>{translate('clean')}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.buttonSearch}>
                        <TouchableOpacity style={styles.searchStyle} onPress={() => {
                            handleFilterResults();
                            toggleModal();
                        }}>
                            <Text style={styles.textSearch}>{translate('results')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    filterInputView: {
        backgroundColor: 'red',
        width: '100%',
        height: 150,
        marginTop: 10
    },
    filterLabel: {
        color: '#0071bc',
        fontSize: 14,
        marginLeft: 18,
        marginTop: 15,
        fontWeight: '600',
    },
    textInput: {
        borderBottomColor: '#0071bc',
        width: '90%',
        borderBottomWidth: 1,
        alignSelf: 'center',
        marginTop: 15,
        padding: 6,
        fontSize: 14,
    },
    dropDownButton: {
        borderBottomColor: '#0071bc',
        width: '90%',
        marginTop: 15,
        borderBottomWidth: 1,
        paddingVertical: 4,
        alignSelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexDirection: 'row'
    },
    dropDownContainerStyle: {
        borderWidth: 1,
        maxHeight: 400,
        marginTop: 15,
        borderColor: "#9a9a9a4f",
        width: "90%",
        alignSelf: 'center',
        backgroundColor: '#F9FAFC',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    listItemContainerStyle: {
        borderColor: "#9a9a9a4f",
        flexDirection: 'row',
        width: "100%",
        borderWidth: 1,
        alignSelf: 'center',
        borderTopWidth: 0,
        paddingVertical: 15,
        color: '#000',
        fontSize: 16
    },
    searchContainerStyle: {
        borderColor: "#9a9a9a4f",
        width: '100%',
        alignSelf: 'center',
        paddingVertical: 10,
        borderWidth: 1,
        borderTopWidth: 0
    },
    dropDownInput: {
        marginBottom: 4,
        color: "#adadad",
        fontSize: 14
    },
    dropDownArrow: {
        height: 25,
        width: 25,
        tintColor: "#0071bc"
    },
    sugestionView: {
        flex: 1,
        position: 'absolute',
        width: "90%",
        backgroundColor: '#fff',
        alignSelf: 'center',
        marginTop: 77,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,

    },
    scrollView: {
        maxHeight: "60px"
    },
    modalContainer: {
        backgroundColor: '#fff',
        flex: 1,
    },
    labelFilterType: {
        marginTop: 20,
        color: '#0071bc',
        fontSize: 14,
        marginLeft: 18,
        fontWeight: '600',
    },
    checkView: {
        flexDirection: 'row',
        marginLeft: 20,
        width: '80%',
        marginTop: 15,
        marginBottom: 5
    },
    checkText: {
        alignSelf: 'center',
        fontSize: 14,
        marginLeft: 17
    },
    textFilterPeriod: {
        fontSize: 14,
        marginTop: 25,
        color: "#0071bc",
        fontWeight: '600',
        marginLeft: 18,
    },
    viewDateTitle: {
        flexDirection: 'column',
        width: '95%',
        marginLeft: 18,
        paddingTop: 20
    },
    viewDateBox: {
        width: '50%',
        flexDirection: 'column',
    },
    dateButton: {
        borderBottomWidth: 1,
        borderBottomColor: "#000",
        width: '80%',
        height: 30,
        alignItems: 'flex-end',
    },
    hourInput: {
        width: '30%',
        marginTop: 10,
    },
    dropDownIcon: {
        width: 22,
        height: 22
    },
    bottomView: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 30,
    },
    buttonClean: {
        width: '20%',
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20
    },
    textClean: {
        color: "#0071bc",
        fontWeight: '600'
    },
    buttonSearch: {
        width: '30%',
        height: 30,
        marginRight: 30,
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchStyle: {
        height: 40,
        borderRadius: 4,
        width: 140,
        backgroundColor: '#0071bc',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textSearch: {
        color: "#fff",
        fontWeight: '600'
    },
    titleDownload: {
        width: '100%',
        alignItems: 'center'
    },
    textDownload: {
        fontSize: 28,
        color: "#0071bc",
        marginTop: 5
    },
    dateContainer: {
        height: 40,
        width: "90%",
        backgroundColor: '#0071bc',
        borderRadius: 4,
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    startTime: {
        color: '#fff'
    },
    dateTimeFilterContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    datePickerContainer: {
        flexDirection: "column",
        paddingVertical: 5,
    },
    datePickerButtonsContainer: {
        flexDirection: "row",
    },
});
