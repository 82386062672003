import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    FlatList,
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import { CameraSteps } from '../../pages/crm/camera/Register';
import { platformAPI, Plan } from '../../services/platform-api';
import { translate } from '../../services/service-translate';

interface CamPlans {
    setPlan: React.Dispatch<React.SetStateAction<Plan | undefined>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    saveNewCam: () => Promise<void>;
    loading: boolean;
    plan: Plan | undefined;
}

const headerSize = 60;
const footerSize = 30;

export default function CamPlan({ setPlan, setStep, saveNewCam, setLoading, loading, plan }: CamPlans) {

    const [plans, setPlans] = useState<Plan[]>([]);
    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    async function getPlans() {
        setLoading(true);
        try {
            const res = await platformAPI.getPlans();
            setLoading(false);
            setPlans(res.sort((p1, p2) => p1.value - p2.value).filter((plan) => plan.slug !== 'aovivo'));
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    function previousPage() {
        setPlan(undefined);
        setStep('map');
    }

    useEffect(() => {
        getPlans();
    }, []);

    const renderItem = ({ item }: { item: Plan; }) => (
        <>
            <View style={styles.viewButton}>
                {plan === item ?
                    <TouchableOpacity
                        style={styles.buttonActive}
                        onPress={() => setPlan(item)}>
                        <View style={styles.button}>
                            <Text style={styles.textButton}>{translate(item.slug)}</Text>
                        </View>
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={styles.button} onPress={() => setPlan(item)}>
                        <View style={styles.button}>
                            <Text style={styles.textButton}>{translate(item.slug)}</Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>
            <ItemSeparator />
        </>
    );

    return (
        <>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={() => previousPage()}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("recordingPlans")}</Text>
                    </View>
                </View>
            </View>
            <FlatList
                bounces={false}
                data={plans}
                renderItem={renderItem}
                keyExtractor={(item: Plan) => item.slug}
            />
            <View style={styles.footer}>
                <TouchableOpacity
                    style={plan !== undefined ? styles.buttonNext : styles.invalidButtonNext}
                    disabled={plan !== undefined ? false : true}
                    onPress={() => plans.length ? saveNewCam() : null}
                >
                    <Text style={styles.buttonNextText}>{translate('save')}</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.footerLoading}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </>
    );
}
const styles = StyleSheet.create({
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    footer: {
        position: "absolute",
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    footerLoading: {
        position: "absolute",
        bottom: 50,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "white"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    textButton: {
        fontSize: 26,
        color: 'black',
        textAlign: 'center',
        justifyContent: 'center',
    },
    button: {
        flexDirection: 'row',
        width: "100%",
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
    },
    viewButton: {
        flexDirection: 'row',
    },
    buttonActive: {
        backgroundColor: '#F0F0F0',
        flexDirection: 'row',
        width: "100%",
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
});

export { CamPlan };
