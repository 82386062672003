const IMAGE_WIDTH = 115;
const IMAGE_HEIGHT = 65;
const MARGIN_RIGHT = 70;

class TimelineShot {
    private img: HTMLImageElement;
    private src: string;
    private loaded: boolean;
    private loading: boolean;

    constructor(src: string) {
        this.loaded = false;
        this.loading = false;
        this.img = new Image();
        this.src = src;
    }

    load() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.img.onload = () => {
            this.loaded = true;
        };
        this.img.src = this.src;
    }

    draw(ctx: CanvasRenderingContext2D, canvasWidth: number, yPosition: number) {
        ctx.fillStyle = "#a9a9a9";
        ctx.fillRect(100, yPosition, canvasWidth - 100 - MARGIN_RIGHT, 1);

        if (!this.loaded) {
            ctx.fillRect(canvasWidth - MARGIN_RIGHT - IMAGE_WIDTH, yPosition - (IMAGE_HEIGHT / 2), IMAGE_WIDTH, IMAGE_HEIGHT);
            return;
        }
        ctx.drawImage(this.img, canvasWidth - MARGIN_RIGHT - IMAGE_WIDTH, yPosition - (IMAGE_HEIGHT / 2), IMAGE_WIDTH, IMAGE_HEIGHT);
    }
}

export default TimelineShot;
