import React, { ReactElement } from "react";
import {
    View,
    Text,
} from "react-native";

import { ActionSheetOptions } from "@expo/react-native-action-sheet";

interface Option {
    title: string;
    subtitle?: string;
    destructive?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

interface CameriteActionSheetOptions extends ActionSheetOptions {
    options: ReactElement[];
}

type CameriteShowActionSheetWithOptions = (options: CameriteActionSheetOptions, callback: (i: number) => void | Promise<void>) => void;

function showActionSheet(showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void, options: Option[]) {

    const listOptions: ReactElement[] = [];
    const disabledButtonIndices: number[] = [];
    const destructiveButtonIndex: number[] = [];
    const myShowActionSheetWithOptions = showActionSheetWithOptions as unknown as CameriteShowActionSheetWithOptions;

    let index = 0;

    for (const option of options) {

        if (option.disabled) {
            disabledButtonIndices.push(index);
        }

        if (option.destructive) {
            destructiveButtonIndex.push(index);
        }

        if (option.subtitle) {
            listOptions.push(
                <View key={index} style={{ alignItems: "center" }}>
                    <Text>{option.title}{"\n"}</Text>
                    <Text style={{ fontSize: 11 }}>{option.subtitle}</Text >
                </View>
            );
        } else {
            listOptions.push(<>{option.title}</>);
        }
        index++;
    }

    myShowActionSheetWithOptions({
        options: listOptions,
        destructiveButtonIndex,
        disabledButtonIndices,
        cancelButtonIndex: -1,
        autoFocus: false,
        textStyle: {
            margin: 'auto'
        },
        showSeparators: true,
    }, (selectedIndex: number) => {

        if (selectedIndex < 0) {
            return;
        }

        if (selectedIndex > options.length - 1) {
            console.error('This option doesn\'t exists.');
            return;
        }

        const onClick = options[selectedIndex].onClick;
        if (onClick) {
            onClick();
        }
    });
}

export { showActionSheet, Option };
