/* eslint-disable @typescript-eslint/no-unused-vars */
import Fingerprint2 from 'fingerprintjs2';

import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

import AsyncStorage from '@react-native-async-storage/async-storage';

import { platformAPI } from './platform-api';
import { translate } from './service-translate';

interface FirebaseNotification {
    type: "PLATE" | "CHAT_MESSAGE" | "CAMERA_MOVEMENT" | "CAMERA_DOWNLOAD" | "CAMERA_PANIC" | "CAMERA_STATUS" | "PERSONAL_PANIC" | "GENERIC";
}

interface ChatNotification extends FirebaseNotification {
    id: string;
    message: string;
    timestamp: string;
    title: string;
    username: string;
}

interface PlateNotification extends FirebaseNotification {
    detectionId: string;
    cameraId: string;
    cameraTitle: string;
    groupTitle: string;
    imageUrl: string;
    stringfiedPlatePosition: string;
    stringfiedCarPosition: string;
    plate: string;
    description: string;
}

interface MovementNotification extends FirebaseNotification {
    cameraId: string;
    cameraTitle: string;
    title?: string;
}

interface DownloadNotification extends FirebaseNotification {
    cameraId: string;
    cameraTitle: string;
    downloadId: string;
    downloadTitle: string;
}

interface PanicNotification extends FirebaseNotification {
    userName: string;
    cameraTitle: string;
    cameraId: string;
}

interface CameraStatusNotification extends FirebaseNotification {
    cameraId: string;
    cameraTitle: string;
    cameraStatus: "OFFLINE" | "ONLINE";
    userId: string;
}

interface PersonalPanicNotification extends FirebaseNotification {
    userId: string;
    userName: string;
}

interface GenericNotification extends FirebaseNotification {
    userId: string;
    userName: string;
}

class ExtendableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

class invalidFirebaseToken extends ExtendableError {
}

class NotificationService {
    device: { components: { [keys: string]: unknown; }; id: string; };
    token: string;
    messaging: firebase.messaging.Messaging | undefined;

    constructor() {
        this.device = {
            components: {},
            id: ""
        };
        this.token = "";

        try {

            const app = firebase.initializeApp({
                apiKey: process.env.FIREBASE_API_KEY,
                authDomain: process.env.FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.FIREBASE_DATABASE_URL,
                projectId: process.env.FIREBASE_PROJECT_ID,
                storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.FIREBASE_APP_ID
            });

            this.messaging = firebase.messaging(app);

            this.messaging.onMessage(async (payload: firebase.messaging.MessagePayload) => {
                console.log('payload-notification', payload);
                if (!payload.data) {
                    return;
                }

                const data = payload.data as unknown as FirebaseNotification;

                if (data.type == "CHAT_MESSAGE") {
                    this.chatNotificationListener(data as ChatNotification);
                    return;
                }
                if (data.type == "PLATE") {
                    this.plateNotification(data as PlateNotification);
                    return;
                }
                if (data.type === "CAMERA_MOVEMENT") {
                    this.movementNotification(data as MovementNotification);
                    return;
                }
                if (data.type === "CAMERA_DOWNLOAD") {
                    this.downloadNotification(data as DownloadNotification);
                }
                if (data.type === "CAMERA_PANIC") {
                    this.panicNotification(data as PanicNotification);
                }
                if (data.type === "CAMERA_STATUS") {
                    this.statusNotification(data as CameraStatusNotification);
                }
                if (data.type === "PERSONAL_PANIC") {
                    this.personalPanicNotification(data as PersonalPanicNotification);
                }
                if (data.type === "GENERIC") {
                    this.genericNotification(data as GenericNotification);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    chatNotificationListener(data: ChatNotification) {
        this.defaultChatNotificationListener(data);
    }

    async defaultChatNotificationListener(data: ChatNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(`${data.username} @ ${data.title}`, {
            body: data.message,
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            tag: 'chat_message',
            data: { clickAction: '/chat/' + data.id },
        });
    }

    async plateNotification(data: PlateNotification) {
        const img = new Image();
        img.src = data.imageUrl || '';
        img.crossOrigin = 'anonymous';
        const lprImageWidth = 1920;
        const lprImageHeight = 1080;
        const lprImageRatio = lprImageWidth / lprImageHeight;

        const stringPlatePosition = data.stringfiedPlatePosition;
        const stringCarPosition = data.stringfiedCarPosition;

        if (!stringPlatePosition && !stringCarPosition) {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            serviceWorkerRegistration.showNotification(data.groupTitle != '' ? `${data.plate} - ${data.groupTitle}` : translate("NOTIFICATION_BLACKLIST_TITLE_PWA", { Plate: data.plate }), {
                body: data.description,
                image: data.imageUrl,
                icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                data: { clickAction: '/lpr/' + data.detectionId },
            });
            return;
        }

        const vehicleDimension = !stringCarPosition ? JSON.parse(stringPlatePosition) : JSON.parse(stringCarPosition)[0];

        if (stringPlatePosition && !stringCarPosition) {
            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            for (const position of vehicleDimension) {
                if (position.x < rect.minX) {
                    rect.minX = position.x;
                }

                if (position.x > rect.maxX) {
                    rect.maxX = position.x;
                }

                if (position.y < rect.minY) {
                    rect.minY = position.y;
                }

                if (position.y > rect.maxY) {
                    rect.maxY = position.y;
                }
            }

            const x = rect.minX;
            const y = rect.minY;
            const width = rect.maxX - rect.minX;
            const height = rect.maxY - rect.minY;

            const vehicleWidth = width * 4;
            const widthCenter = x + width - (width / 2);
            const heightCenter = y + height - (height / 2);


            vehicleDimension.x = widthCenter - (vehicleWidth / 2);
            vehicleDimension.y = heightCenter - (vehicleWidth / 2);
            vehicleDimension.width = vehicleWidth;
            vehicleDimension.height = vehicleWidth;
        }

        if (vehicleDimension.width / vehicleDimension.height > lprImageRatio) {
            // based on vehicle width
            const oldHeight = vehicleDimension.height;
            vehicleDimension.height = vehicleDimension.width / lprImageRatio;
            const heightDiff = vehicleDimension.height - oldHeight;
            vehicleDimension.y -= (heightDiff / 2);

            if (vehicleDimension.height + vehicleDimension.y > lprImageHeight) {
                vehicleDimension.y -= vehicleDimension.height + vehicleDimension.y - lprImageHeight;
            }

            if (vehicleDimension.y < 0) {
                vehicleDimension.y = 0;
            }
        } else {
            // based on vehicle height
            const oldWidth = vehicleDimension.width;
            vehicleDimension.width = vehicleDimension.height * lprImageRatio;
            const widthDiff = vehicleDimension.width - oldWidth;
            vehicleDimension.x -= (widthDiff / 2);

            if (vehicleDimension.width + vehicleDimension.x > lprImageWidth) {
                vehicleDimension.x -= vehicleDimension.width + vehicleDimension.x - lprImageWidth;
            }

            if (vehicleDimension.x < 0) {
                vehicleDimension.x = 0;
            }
        }

        img.onload = async () => {
            const canvas = document.createElement('canvas');

            const ctx = canvas.getContext("2d");

            canvas.width = 1920;
            canvas.height = 1080;

            ctx?.drawImage(
                img,
                vehicleDimension.x,
                vehicleDimension.y,
                vehicleDimension.width,
                vehicleDimension.height,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob(async (blob) => {
                if (!blob) {
                    return;
                }
                if (!data) {
                    return;
                }
                const newUrl = URL.createObjectURL(blob);

                const serviceWorkerRegistration = await navigator.serviceWorker.ready;
                serviceWorkerRegistration.showNotification(data.groupTitle != '' ? `${data.plate} - ${data.groupTitle}` : translate("NOTIFICATION_BLACKLIST_TITLE_PWA", { Plate: data.plate }), {
                    body: data.description,
                    image: newUrl,
                    icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                    badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                    data: { clickAction: '/lpr/' + data.detectionId },
                });
            }, 'image/jpeg', 0.8);
        };
    }

    async movementNotification(data: MovementNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(translate("NOTIFICATION_MOTION_TITLE"), {
            body: data.title ?
                translate("NOTIFICATION_MOTION_BODY", {
                    CameraTitle: data.cameraTitle,
                    AreaTitle: data.title
                }) :
                translate("NOTIFICATION_MOTION_ZOWEE_BODY", {
                    CameraTitle: data.cameraTitle
                }),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/cameras/' + data.cameraId },
        });
    }

    async downloadNotification(data: DownloadNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(translate('NOTIFICATION_DOWNLOAD_TITLE'), {
            body: translate('NOTIFICATION_DOWNLOAD_BODY', {
                VideoName: data.downloadTitle
            }),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/videos/' + data.downloadId }
        });
    }

    async panicNotification(data: PanicNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(translate('NOTIFICATION_PANIC_TITLE'), {
            body: translate('NOTIFICATION_PANIC_BODY', {
                UserName: data.userName,
                CameraName: data.cameraTitle
            }),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/cameras/' + data.cameraId }
        });
    }

    async statusNotification(data: CameraStatusNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(data.cameraStatus === "OFFLINE" ? translate("MAIL_CAMERA_OFF") : translate("MAIL_CAMERA_ON"), {
            body: translate("NOTIFICATION_CAMERA_STATUS", {
                CameraTitle: data.cameraTitle,
                Status: data.cameraStatus
            }),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/cameras/' + data.cameraId }
        });
    }

    async personalPanicNotification(data: PersonalPanicNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(translate('NOTIFICATION_PERSONAL_PANIC_TITLE'), {
            body: translate('NOTIFICATION_PERSONAL_PANIC_BODY', {
                UserName: data.userName,
            }),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/cameras' }
        });
    }

    async genericNotification(data: GenericNotification) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.showNotification(translate('notification_default_title'), {
            body: translate('notification_default_body'),
            icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
            data: { clickAction: '/cameras' }
        });
    }

    private async loadDeviceInfo() {

        const components = await Fingerprint2.getPromise();

        for (const component of components) {
            this.device.components[component.key] = component.value;
        }

        this.device.id = Fingerprint2.x64hash128(components.map((each) => each.value).join(''), 31);

    }

    private async loadToken() {

        if (Notification.permission != 'granted') {
            await Notification.requestPermission();
        }

        if (Notification.permission != 'granted') {
            throw new invalidFirebaseToken("The page doesn't have permission to access notifications.");
        }

        let token;

        try {
            token = await this.messaging?.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY });
        } catch (error) {
            // An error occurred while retrieving token.
            // BUT THE NEW TOKEN WILL PROBLABLY BE SUCCESSFULY FETCHED
            // note: idk why this happens :(
            token = await this.messaging?.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY });
        }

        if (!token) {
            throw new invalidFirebaseToken("Invalid firebase token.");
        }

        this.token = token;
    }

    async registerToken() {

        if (!await platformAPI.isAuthenticated()) {
            throw new Error("You need to be autheticated to register firebase token.");
        }

        if (!this.device.id) {
            await this.loadDeviceInfo();
        }

        if (!this.token) {
            await this.loadToken();
        }

        let deviceOS = navigator.platform || 'Unknow';

        if (this.device.components.platform && typeof this.device.components.platform == "string") {
            deviceOS = this.device.components.platform;
        }

        const oldToken = await AsyncStorage.getItem("FIREBASE_TOKEN");

        if (oldToken != this.token) {
            if (oldToken) {
                await platformAPI.unregisterUserDevice(oldToken);
            }
            await AsyncStorage.setItem("FIREBASE_TOKEN", this.token);
        }
        await platformAPI.registerUserDevice(this.token, this.device.id, deviceOS);

    }

    async unregisterToken() {

        const oldToken = await AsyncStorage.getItem("FIREBASE_TOKEN");
        if (oldToken) {
            await platformAPI.unregisterUserDevice(oldToken);
            await AsyncStorage.removeItem("FIREBASE_TOKEN");
        }

        if (!this.token) {
            try {
                await this.loadToken();
            } catch (error) {
                // shut up
            }
        }

        if (this.token && this.token != oldToken) {
            await platformAPI.unregisterUserDevice(this.token);
        }

    }

}

export const notificationService = new NotificationService();
export { ChatNotification };
