// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export async function register() {
    await new Promise((resolve, reject) => {
        if ("serviceWorker" in navigator && process.env.PUBLIC_URL) {
            // The URL constructor is available in all browsers that support SW.
            const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
            if (publicUrl.origin !== window.location.origin) {
                // Our service worker won't work if PUBLIC_URL is on a different origin
                // from what our page is served on. This might happen if a CDN is used to
                // serve assets; see https://github.com/facebook/create-react-app/issues/2374
                return;
            }

            window.addEventListener("load", async () => {
                try {
                    const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
                    await registerValidSW(swUrl, resolve);
                } catch (err) {
                    return reject(err);
                }
            });
        } else {
            reject("Development environment won't register a service-worker");
        }
    });
}

async function registerValidSW(swUrl: string, resolve: (value: unknown) => void) {

    const registration = await navigator.serviceWorker.register(swUrl);

    if (registration.installing == null) {
        resolve(null);
    }

    registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
            resolve(null);
            return;
        }
        installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                    // At this point, the updated precached content has been fetched,
                    // but the previous service worker will still serve the older
                    // content until all client tabs are closed.
                    console.log(
                        "New content is available and will be used when all " +
                        "tabs for this page are closed. See https://cra.link/PWA."
                    );
                    installingWorker.postMessage({ type: "SKIP_WAITING" });
                } else {
                    console.log("Content is cached for offline use.");
                }
                resolve(null);
            }
        };
    };

}

export async function unregister() {
    if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.ready;
        registration.unregister();
    }
}
