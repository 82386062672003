import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ListRenderItemInfo,
    FlatList,
    Modal,
    Image
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    ChargesList
} from "../../../services/platform-api";
import { translate } from "../../../services/service-translate";
import moment from "moment";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { showActionSheet, Option } from "../../../components/ActionSheet";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

const headerSize = 60;
const footerSize = 30;

interface CrmChargesListComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function CrmChargesList({
    navigation, authenticatedUser
}: CrmChargesListComponentProps) {
    const { showActionSheetWithOptions } = useActionSheet();
    const [chargesList, setChargesList] = useState<ChargesList[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [filteredFiles, setFilteredFiles] = useState<ChargesList[]>([]);
    const [selectedItem, setSelectedItem] = useState<string>("1");
    const [onlyPendingChargesList, setOnlyPendingChargesList] = useState<ChargesList[]>([]);
    const [loading, setLoading] = useState(false);
    const today = new Date();

    const options = [{
        option: 'allCharges',
        value: '0'
    }, {
        option: 'pendingCharges',
        value: '1'
    }];

    const filterFiles = (item: string) => {
        setSelectedItem(item);

        if (item === "0") {
            setFilteredFiles(chargesList);
        } else {
            setFilteredFiles(onlyPendingChargesList);
        }
    };

    useEffect(() => {
        document.body.style.position = 'fixed';
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();
            getCharges(wl.id);
            if (!authenticatedUser.permissions.can_see_platform_payment_info || wl.id == 1) {
                return navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    async function getCharges(wlId: number) {
        setLoading(true);
        try {
            const wlCharges = await platformAPI.getHistoryCharges();
            const currentCharge = await platformAPI.getCurrentCharge(wlId);
            wlCharges.sort((a, b) => {
                const dateA = a.boleto_expiration_date || a.createdAt;
                const dateB = b.boleto_expiration_date || b.createdAt;
                return new Date(dateB).getTime() - new Date(dateA).getTime();
            });
            wlCharges.unshift({
                id: -1,
                WhitelabelId: wlId,
                status: 'estimatedValue',
                createdAt: '',
                boleto_expiration_date: '',
                total: currentCharge.total,
                type: "MONTHLY",
            });

            setChargesList(wlCharges);

            const onlyPendingCharges = wlCharges.filter(charge => !['paid', 'accumulated',
                'cleared', 'canceled', 'accumulation_transferred', 'chargedback', 'refunded'].includes(charge.status));
            onlyPendingCharges.sort((a, b) => {
                const dateA = a.boleto_expiration_date || a.createdAt;
                const dateB = b.boleto_expiration_date || b.createdAt;
                return new Date(dateA).getTime() - new Date(dateB).getTime();
            });
            onlyPendingCharges.shift();

            setOnlyPendingChargesList(onlyPendingCharges);
            setFilteredFiles(onlyPendingCharges);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        filterFiles('1');
        navigation.navigate("Camera", { screen: "More" });
    }

    function colors(item: ChargesList) {
        if (['paid', 'cleared'].includes(item.status)) {
            return '#00a035';
        }
        if (['refunded', 'chargedback'].includes(item.status)) {
            return 'purple';
        }
        if (['accumulated', 'accumulation_transferred'].includes(item.status)) {
            return 'blue';
        }
        if ((item.boleto_expiration_date < today.toISOString()) &&
            ['waiting_payment', 'unpaid', 'outsourced_billing', 'to_clear'].includes(item.status)) {
            return 'red';
        }
        if (['waiting_payment', 'pending_payment', 'analyze_pending',
            'outsourced_billing', 'unpaid', 'to_clear'].includes(item.status)) {
            return 'orange';
        }
        if (['canceled', 'refused'].includes(item.status)) {
            return 'red';
        }
    }

    function actionSheet(charge: ChargesList) {
        const options: Option[] = [];

        if (charge.transaction_id && charge.boleto_url && charge.status
            && !(['canceled', 'paid', 'analyze_pending', 'accumulated', 'accumulation_transferred'].includes(charge.status))) {
            options.push({
                title: translate("viewBankSlip"),
                onClick: () => {
                    window.open(charge.boleto_url, "_blank");
                }
            });
        }

        const invoiceButton: Option = {
            title: translate("viewInvoice"),
            onClick: () => {
                window.open(charge.Invoice?.pdf, "_blank");
            }
        };

        if (!charge.Invoice || !charge.Invoice.pdf) {
            invoiceButton.subtitle = translate('processing');
            invoiceButton.disabled = true;
            invoiceButton.onClick = undefined;
        }

        if (charge.id != -1) {
            options.push(invoiceButton);
        }

        options.push({
            title: translate("viewDetailedCharge"),
            onClick: () => {
                if (charge.id != -1) {
                    return navigation.navigate("CrmDetailedCharge", { id: charge.id });
                }
            }
        });

        if (options.length) {
            showActionSheet(showActionSheetWithOptions, options);
        }
    }

    const renderItem = ({ item }: ListRenderItemInfo<ChargesList>) => {
        return (
            <TouchableOpacity onPress={() => item.id != -1 ? actionSheet(item) : navigation.navigate("CrmPredictions")}>
                <View style={styles.ListInfo}>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propList, { justifyContent: "center" }]}>{item.boleto_expiration_date ? moment(item.boleto_expiration_date).format("DD/MM/YYYY") : ""}</Text>
                    </View>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propList, { justifyContent: "center" }]}>{
                            item.boleto_expiration_date < today.toISOString() &&
                                ['waiting_payment', 'unpaid', 'outsourced_billing', 'to_clear'].includes(item.status) ?
                                translate("overdue") : translate(item.status)}</Text>
                    </View>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propList, { justifyContent: "center", color: `${colors(item)}` }]}>{(+item.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                    </View>
                </View>
                <View style={styles.itemSeparator} />
            </TouchableOpacity>
        );
    };

    const modalRenderItem = ({ item }: ListRenderItemInfo<{ value: string, option: string }>) => {
        return (
            <TouchableOpacity
                style={[
                    styles.modalItem,
                    {
                        backgroundColor: item.value === selectedItem ? "#0071bc" : "#fff",
                    },
                ]}
                onPress={() => filterFiles(item.value)}
            >
                <View style={styles.ListInfo}>
                    <Text style={{
                        fontWeight: 'bold',
                        color: item.value === selectedItem ? "#fff" : "#000",
                    }}>{translate(item.option)}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <DefaultPageContainer>
            <View style={styles.backgroundView}>
                <View style={styles.header}>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate('charges')}</Text>
                        </View>
                    </View>
                    <TouchableOpacity
                        style={styles.filterView}
                        onPress={() => setModalVisible(true)}
                    >
                        <Image
                            style={styles.filterImage}
                            source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_filter.png' }}
                        />
                    </TouchableOpacity>
                </View>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => setModalVisible(false)}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Text style={styles.titleModal}>{translate("filter")}</Text>
                            <ItemSeparator />
                            <FlatList
                                data={Object(options)}
                                renderItem={modalRenderItem}
                            />
                            <TouchableOpacity
                                style={styles.confirmButton}
                                onPress={() => setModalVisible(false)}
                            >
                                <Text style={styles.confirmButtonText}>{translate("confirmCategories")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
                <View style={styles.topInformation}>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("dueDate")}</Text>
                    </View>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("status")}</Text>
                    </View>
                    <View style={{ width: '33%' }}>
                        <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("value")}</Text>
                    </View>
                </View>
                <View style={styles.itemSeparator} />
                {filteredFiles.length ?
                    <FlatList
                        style={{ height: 1 }}
                        data={Object(filteredFiles)}
                        renderItem={renderItem}
                    />
                    :
                    <View style={styles.noPendingCharges}>
                        <Text>{translate("noPendingCharges")}</Text>
                    </View>
                }
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={styles.loading}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    topInformation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginHorizontal: 18,
        marginVertical: 25,
    },
    propText: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
    },
    ListInfo: {
        flexDirection: 'row',
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: 72,
        paddingVertical: 0,
        paddingHorizontal: 20,
    },
    propList: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    filterIcon: {
        width: 5,
        height: 5,
        marginRight: 20
    },
    check: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingHorizontal: 5,
        fontSize: 14,
    },
    textFilter: {
        textAlign: "center",
        fontSize: 16,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modalView: {
        backgroundColor: "#fff",
        width: 350,
        borderRadius: 8,
        paddingVertical: 20,
        paddingHorizontal: 30,
        marginHorizontal: 20,
        alignItems: "center",
    },
    titleModal: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 20,
    },
    confirmButton: {
        marginTop: 20,
        width: 250,
        textAlign: 'center',
        backgroundColor: "red",
        borderRadius: 8,
        paddingVertical: 10,
        paddingHorizontal: 30,
    },
    confirmButtonText: {
        fontSize: 16,
        color: '#fff',
        textAlign: 'center'
    },
    filterView: {
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
    },
    filterImage: {
        width: 25,
        height: 25,
        tintColor: "#fff",
    },
    modalItem: {
        marginTop: 10,
        paddingHorizontal: 20,
        borderRadius: 8,
        marginBottom: 10,
        width: 250,
    },
    noPendingCharges: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    }
});
