import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { Dialog } from 'react-native-paper';
import { Survey } from './NpsSurvey';
import { translate } from '../../services/service-translate';

interface StepProps {
    setDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
    scores: Scores[];
    inputs: Survey;
    setInputs: React.Dispatch<React.SetStateAction<Survey>>
    sendScore: () => Promise<void>;
}

interface Scores {
    label: number;
    value: number;
}

export default function StepTwo({ scores, inputs, setInputs, sendScore }: StepProps) {

    return (
        <>
            <Dialog.Title style={styles.title} onPressIn={{}} onPressOut={{}}>{translate('NPS_SURVEY_TITLE_STEP_2')}</Dialog.Title>
            <Dialog.Content style={styles.content}>
                <View style={styles.notesView}>
                    <View style={styles.notes}>
                        {scores.map((score: Scores) => (
                            <TouchableOpacity onPress={() => setInputs({ ...inputs, score: score.value })} style={[styles.noteButton, { backgroundColor: inputs.score == score.label ? "#0071bc" : "#fff" }]} key={score.value}>
                                <Text style={{ color: inputs.score == score.label ? "#fff" : "#A5A5A5" }}>{score.label}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                    <View style={styles.recomendation}>
                        <Text style={styles.recomendationText}>{translate('NPS_SURVEY_NOT_RECOMMENDED')}</Text>
                        <Text style={styles.recomendationText}>{translate('NPS_SURVEY_RECOMMEND')}</Text>
                    </View>
                </View>
                <View style={styles.viewText}>
                    <Text style={styles.titleTextArea}>{translate('NPS_SURVEY_JUSTIFICATION')}</Text>
                    <TextInput value={inputs.scoreJustification || undefined} onChangeText={(text) => setInputs({...inputs, scoreJustification: text })} style={styles.textArea} numberOfLines={4} multiline={true} />
                </View>
            </Dialog.Content>
            <Dialog.Actions style={styles.actions}>
                <TouchableOpacity onPress={sendScore}>
                    <View style={styles.buttons}>
                        <Text style={styles.textButton}>{translate('send')}</Text>
                    </View>
                </TouchableOpacity>
            </Dialog.Actions>
        </>
    );
}
const styles = StyleSheet.create({
    title: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        color: '#0071bc',
        fontWeight: 'bold'
    },
    content: {
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        paddingBottom: -20
    },
    notesView: {
        flexDirection: 'column'
    },
    notes: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    noteButton: {
        borderWidth: 1,
        borderColor: "#cecece",
        borderRadius: 50,
        height: 25,
        width: 25,
        textAlign: 'center',
        justifyContent: 'center',
        alignContent: 'center'
    },
    recomendation: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    recomendationText: {
        color: '#A5A5A5'
    },
    viewText: {
        paddingVertical: 20
    },
    titleTextArea: {
        color: "#0071bc",
        padding: 3,
        fontSize: 16,
        fontWeight: 'bold'
    },
    textArea: {
        height: 80,
        borderRadius: 5,
        padding: 2,
        borderWidth: 1,
        borderColor: "#A5A5A5"
    },
    actions: {
        width: '100%',
        paddingHorizontal: 20,
        paddingBottom: 20,
        justifyContent: 'flex-end'
    },
    buttons: {
        padding: 15,
        borderRadius: 10,
        backgroundColor: '#0071bc',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    textButton: {
        color: '#fff',
        fontWeight: 'bold'
    }
});
