import React, { useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Share } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import moment from 'moment';

import VideoPlayer from '../../components/VideoPlayer';
import { translate } from '../../services/service-translate';
import { platformAPI, Video, invalidBearerToken, forbiddenAccess } from "../../services/platform-api";
import { useNavigationState } from '@react-navigation/native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import Toast from 'react-native-toast-message';


interface WatchVideoComponentProps {
    navigation: Navigation;
    route: {
        params: Video;
    };
}

export default function WatchVideo({ navigation, route }: WatchVideoComponentProps) {

    const videoParam = route.params;
    const videoPlayerRef = React.useRef<VideoPlayer>(null);
    const safeAreaInsets = useSafeAreaInsets();
    const [video, setVideo] = React.useState<Video>();
    const index = useNavigationState(state => state);

    function getVideoHeightRatio() {
        return window.innerHeight * .4;
    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getVideo();

            document.body.style.position = 'fixed';
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#000000');
        });
        return unsubscribe;
    }, [navigation, route]);

    async function getVideo() {
        try {
            const res = await platformAPI.getVideo(videoParam.id);

            if (res) {
                setVideo(res);
            }

        } catch (err) {
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "Video" });
                Toast.show({
                    type: 'error',
                    text1: translate('videoInvalidAccess'),
                });
                return;
            }

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {

        const videoPlayer = videoPlayerRef.current;

        if (!videoPlayer) {
            return;
        }

        videoPlayer.addBackButton(() => {
            if (index.routes.length > 1) {
                navigation.navigate("Camera", { screen: "Video" });
                return;
            }
            window.location.href = '/videos';
        }, safeAreaInsets.top);

        function handleResize() {
            videoPlayer?.setWidth(window.innerWidth);
            videoPlayer?.setHeight(getVideoHeightRatio());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [video]);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        width: window.innerWidth,
        height: getVideoHeightRatio(),
        responsive: true,
        sources: [
            {
                src: video?.videoUrl,
                type: "video/mp4"
            }
        ],
        playbackRates: [0.25, 0.50, 1, 2],
        loop: true,
    };

    const onShare = async () => {
        try {
            await Share.share({
                message:
                    video ? video.videoUrl : videoParam.videoUrl,
            });
        } catch (error) {
            console.error(error);
        }
    };

    async function createPicture() {
        const timestamp = await videoPlayerRef.current?.getTime();
        if (timestamp) {
            const pictureName = video?.title + "_" + (timestamp * 1000).toFixed();
            videoPlayerRef.current?.takePicture(pictureName);
        }
    }

    return (
        <DefaultPageContainer customTopInsetColor='#000000'>
            {video ?
                <>
                    <VideoPlayer {...videoJsOptions} ref={videoPlayerRef} />
                    <View style={styles.backgroundVideoInfos}>
                        <View style={styles.videoInfos}>
                            <Text style={styles.videoTitle}>{video.title}</Text>
                            <View style={styles.lineView}>
                                <Text style={styles.cameraInfo}><Text style={{ fontWeight: "600" }}>{translate('cameraName')}:</Text> {video.Camera.title}</Text>
                                <Text style={styles.cameraInfo}><Text style={{ fontWeight: "600" }}>{translate('duration')}:</Text> {Math.floor((video.finish - video.start) / 60000)}m {(video.finish - video.start) % 60000 / 1000}s</Text>
                                <Text style={styles.cameraInfo}><Text style={{ fontWeight: "600" }}>{translate('date')}:</Text> {moment(video.start).format("LLL")}</Text>
                                <Text style={styles.cameraInfo}><Text style={{ fontWeight: "600" }}>{translate('request')}:</Text> {moment(video.createdDate).format('LLL')}</Text>
                                <Text style={[styles.cameraInfo, { fontWeight: "600" }]}>{translate('video_expiration_message', { Days: moment(video.createdDate).add(30, 'days').diff(moment(new Date()), 'days') })}</Text>
                            </View>
                            <View style={styles.viewActions}>
                                <View style={styles.screenShotView}>
                                    <TouchableOpacity onPress={createPicture}>
                                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_picture_black.png' }} style={styles.screenShotLogo} />
                                        <Text style={styles.actionsText}>{translate('screenshot')}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.downloadView}>
                                    <TouchableOpacity onPress={() => window.open(video.videoUrl, "_blank")}>
                                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_download_black.png' }} style={styles.downloadLogo} />
                                        <Text style={styles.actionsText}>{translate('download')}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.shareView}>
                                    <TouchableOpacity onPress={onShare}>
                                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_share_black.png' }} style={styles.shareLogo} />
                                        <Text style={styles.actionsText}>{translate('share')}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </>
                :
                <></>
            }
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundVideoInfos: {
        flex: 1,
        width: "100%",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#fff",
        zIndex: 5
    },
    videoInfos: {
        flex: 1,
        width: "70%",
        marginTop: 30
    },
    videoTitle: {
        fontSize: 18,
        fontWeight: '500'
    },
    lineView: {
        marginTop: 10,
        borderBottomWidth: 1,
        paddingBottom: 10,
        borderTopWidth: 1,
        borderColor: '#bdbdbd'
    },
    cameraInfo: {
        paddingTop: 10,
    },
    viewActions: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 30,
        paddingLeft: 50
    },
    screenShotView: {
        marginRight: 50,
        justifyContent: 'center',
        alignItems: 'center'
    },
    screenShotLogo: {
        width: 35,
        height: 25,
        alignSelf: 'center'
    },
    downloadView: {
        marginRight: 50,
        justifyContent: 'center',
        alignItems: 'center'
    },
    downloadLogo: {
        width: 35,
        height: 25,
        alignSelf: 'center'
    },
    shareView: {
        marginRight: 50,
        justifyContent: 'center',
        alignItems: 'center'
    },
    shareLogo: {
        width: 30,
        height: 25,
        alignSelf: 'center'
    },
    actionsText: {
        color: '#808080',
        textAlign: 'center',
        marginTop: 10,
        fontSize: 12
    }
});
