import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ListRenderItemInfo,
    FlatList,
    ScrollView,
    LayoutChangeEvent,
    Modal,
    TextInput,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
    useWindowDimensions,
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { ActivityIndicator, List } from "react-native-paper";
import {
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import {
    invalidBearerToken,
    platformAPI,
    CrmUserData,
    UserData,
    forbiddenAccess,
    GetUsersParams
} from "../../../services/platform-api";
import { translate } from "../../../services/service-translate";
import '../../../styles/tooltips.css';
import { useActionSheet } from "@expo/react-native-action-sheet";
import { showActionSheet, Option } from "../../../components/ActionSheet";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface CrmUserListComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

const headerSize = 60;
const footerSize = 30;

export default function CrmUserList({
    navigation, authenticatedUser
}: CrmUserListComponentProps) {
    const insets = useSafeAreaInsets();
    const { showActionSheetWithOptions } = useActionSheet();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState<CrmUserData>();
    const [canSeeUserDetails, setCanSeeUserDetails] = useState(false);
    const [canEditUserPayment, setCanEditUserPayment] = useState(false);
    const [canSeeUserTab, setCanSeeUserTab] = useState(false);
    const [canEditUser, setCanEditUser] = useState(false);
    const [usersList, setUsersList] = useState<CrmUserData[]>([]);
    const [viewHeight, setViewHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emptyList, setEmptyList] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [searchParams, setSearchParams] = useState<GetUsersParams>({
        page: 1,
        filter: search
    });

    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSearchParams({
                page: 1,
                filter: search
            });
        });
        return unsubscribe;
    }, [navigation]);

    useEffect(() => {

        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";
        checkPermissions();

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [authenticatedUser]);

    useEffect(() => {
        loadCrmUsers();
    }, [searchParams]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !usersList.length || usersList.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id != 1 && authenticatedUser.permissions.can_see_user_tab) {
                setCanSeeUserTab(authenticatedUser.permissions.can_see_user_tab);
                setCanEditUserPayment(authenticatedUser.permissions.can_edit_user_payment);
                setCanSeeUserDetails(authenticatedUser.permissions.can_see_user_details);
                setCanEditUser(authenticatedUser.permissions.can_edit_user);
            } else {
                navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function loadCrmUsers() {
        if (!searchParams.page) {
            return;
        }
        try {
            setLoading(true);
            if (searchParams.page === 1) {
                setRefreshing(true);
            }
            const crmUsers = await platformAPI.getCrmUsers(searchParams);

            if (crmUsers.length === 0 && searchParams.page === 1) {
                setUsersList([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setUsersList(crmUsers);
            } else {
                setUsersList([...usersList, ...crmUsers]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
        }
    }

    async function filteredCrmUsers() {
        try {
            setLoading(true);

            if (search === searchParams.filter) {
                return setLoading(false);
            }

            if (search === '') {
                return cleanSearchBar();
            }

            setRefreshing(true);
            setSearchParams({
                page: 1,
                filter: search
            });

            const crmUsers = await platformAPI.getCrmUsers(searchParams);

            if (crmUsers.length === 0) {
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setUsersList(crmUsers);
            } else {
                setUsersList([...usersList, ...crmUsers]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            filteredCrmUsers();
        }
    }

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            return setOnChangeSearch(true);
        }
        setOnChangeSearch(false);
    };

    function cleanSearchBar() {
        setSearchParams({
            page: 1,
            filter: ''
        });
        setSearch('');
    }

    function handleLoadMore() {
        if (!searchParams.page) {
            return;
        }
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    async function handleRefresh() {
        setSearchParams({
            ...searchParams,
            page: 1,
        });
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    function goCrmUserForm() {
        navigation.navigate("CrmUserForm");
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    async function deleteUser() {
        if (!userToBeDeleted) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteUser(userToBeDeleted.id);
            setDeleteModalVisible(false);
            handleRefresh();

            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function changeUserStatus(user: CrmUserData) {
        setLoading(true);
        try {
            await platformAPI.changeUserStatus(user.id, !user.is_activated);
            handleRefresh();

            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function actionSheet(user: CrmUserData) {
        setUserToBeDeleted(user);
        const options: Option[] = [];

        options.push({
            title: translate("editRegistration"), onClick: () => {
                navigation.navigate('CrmUserForm', { id: user.id });
            }
        });

        if (canEditUserPayment && authenticatedUser?.id != user.id) {
            options.push({
                title: translate("editPayment"),
                subtitle: !user.hasValidInfoForPayment ?
                    translate("validateRegistration") : "",

                disabled: !user.hasValidInfoForPayment,
                onClick: () => {
                    navigation.navigate('CrmUserPayments', { id: user.id });
                }
            });
        }

        if (canSeeUserDetails) {
            options.push({
                title: translate("cameraGroups"), onClick: () => {
                    navigation.navigate('CrmUserCameraGroups', { id: user.id });
                }
            });

            options.push({
                title: translate("userPermissionLevels"), onClick: () => {
                    navigation.navigate('CrmUserPermissionLevels', { id: user.id });
                }
            });
        }

        if (canEditUser && authenticatedUser?.id != user.id) {
            options.push({
                title: user.is_activated ? translate("disable") : translate("enable"),
                disabled: authenticatedUser?.id === user.id || user.master_admin,
                subtitle: user.master_admin ? translate("disableMasterAdmin") : "",
                onClick: () => changeUserStatus(user)
            });
            options.push({
                title: translate("delete"),
                destructive: true,
                disabled: authenticatedUser?.id === user.id || user.master_admin,
                subtitle: user.master_admin ? translate("deleteMasterAdmin") : "",
                onClick: () => {
                    setDeleteModalVisible(true);
                }
            });
        }
        showActionSheet(showActionSheetWithOptions, options);
    }

    const renderItem = ({ item }: ListRenderItemInfo<CrmUserData>) => {
        return (
            <>
                <TouchableOpacity disabled={canSeeUserTab && !canSeeUserDetails} onPress={() => actionSheet(item)}>
                    <View
                        style={styles.itemContainer}
                        onLayout={(e: LayoutChangeEvent) => {
                            if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                        }}
                    >
                        <View style={[styles.itemContainerInfo]}>
                            <View style={styles.itemContainerUsersInfo}>
                                <Text style={styles.title} numberOfLines={1} adjustsFontSizeToFit>
                                    {item.name}
                                </Text>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={styles.subTitle}>
                                    {item.email ? item.email : item.phone}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.iconContainer}>
                            {item.is_activated ? (
                                <div className="ur-tooltip">
                                    <span className="ur-tooltip-text">
                                        {translate("enabled")}
                                    </span>
                                    <div className="ur-tooltip-icon">
                                        <List.Icon
                                            icon={"check-circle"}
                                            color="green"
                                            style={styles.enabledIcon}
                                        />
                                    </div>
                                </div>
                            ) : <div className="ur-tooltip">
                                <span className="ur-tooltip-text">
                                    {item.disabled_reason == "MANUAL_DISABLED" ? translate("manualDisabled") : null}
                                    {item.disabled_reason == "OVERDUE_PAYMENT_ON_ASAAS" ? translate("paymentDisabled") : null}
                                    {item.disabled_reason == "EXPIRED_DEMO" ? translate("expiredDisabled") : null}
                                </span>
                                <div className="ur-tooltip-icon">
                                    <List.Icon
                                        icon={"close-circle"}
                                        color="red"
                                        style={styles.enabledIcon}
                                    />
                                </div>
                            </div>}
                        </View>
                    </View>
                </TouchableOpacity>
            </>
        );
    };

    return (
        <DefaultPageContainer>

            {showSearchBar ?
                <View style={styles.header}>
                    <View style={styles.bothSides}>
                        <TouchableOpacity
                            onPress={() => {
                                setShowSearchBar(false); cleanSearchBar();
                            }}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.searchInput}>
                        <TextInput
                            selectionColor='#0071bc'
                            style={[styles.searchInput, { width: windowWidth - 168 }]}
                            value={search}
                            onKeyPress={handleKeyDown}
                            onChangeText={text => {
                                setSearch(text); onChangeSearchBar(text);
                            }}
                            autoFocus
                        />
                    </View>
                    <View style={styles.bothSides}>
                        {onChangeSearch ?
                            <TouchableOpacity onPress={cleanSearchBar}>
                                <List.Icon
                                    icon='close'
                                    color='#fff' />
                            </TouchableOpacity>
                            : null}
                        <TouchableOpacity
                            onPress={() => filteredCrmUsers()}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="magnify"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={styles.header}>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate("users")}</Text>
                        </View>
                    </View>
                    <View style={styles.registerView}>
                        <TouchableOpacity onPress={() => setShowSearchBar(true)}>
                            {showSearchBar ? null : <List.Icon
                                icon="magnify"
                                style={styles.searchIcon}
                                color='#fff'
                            />}
                        </TouchableOpacity>
                        {canSeeUserTab ? canSeeUserDetails ? canEditUser ?
                            <TouchableOpacity onPress={goCrmUserForm}>
                                <List.Icon
                                    style={styles.register}
                                    icon="plus"
                                    color="#fff"
                                />
                            </TouchableOpacity> : null : null : null
                        }
                    </View>
                </View>}

            <ScrollView
                bounces={false}
                style={[styles.scrollView, { height: viewHeight }]}
            >
                {!usersList.length ? (
                    !loading && (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>
                    )
                ) : (
                    <View>
                        <Modal
                            animationType='fade'
                            transparent={true}
                            visible={deleteModalVisible}
                            onRequestClose={() => {
                                setDeleteModalVisible(!deleteModalVisible);
                            }}>
                            <View style={styles.fadeView} />
                            <View style={styles.centeredView}>
                                <View style={[styles.containerModal, { marginTop: viewHeight / 2.2 }]}>
                                    <Text style={styles.titleModal} numberOfLines={2} adjustsFontSizeToFit>{translate("deleteUserConfirmation")}{"\n"}{userToBeDeleted?.name}</Text>
                                    <View style={styles.viewButtonsModal}>
                                        <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                            <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deleteUser()}>
                                            <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        </Modal>
                        <FlatList
                            style={[styles.scrollView, { height: viewHeight }]}
                            bounces={false}
                            data={usersList}
                            keyExtractor={(item) => item.id.toString()}
                            renderItem={renderItem}
                            removeClippedSubviews={true}
                            updateCellsBatchingPeriod={100}
                            windowSize={12}
                            ItemSeparatorComponent={ItemSeparator}
                            nestedScrollEnabled
                            onEndReached={() => {
                                handleLoadMore();
                            }}
                            onEndReachedThreshold={0.03}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            onLayout={(e: LayoutChangeEvent) => {
                                setContentHeight(e.nativeEvent.layout.height);
                            }}
                        />
                    </View>
                )}
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    searchInputView: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: headerSize,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    register: {
        alignSelf: "flex-end",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    registerView: {
        flexDirection: "row",
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    itemContainer: {
        flexDirection: 'row',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: 72,
        paddingVertical: 0,
        paddingLeft: 20,
    },
    itemContainerUsersInfo: {
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: "column",
        gap: 5
    },
    title: {
        fontSize: 16,
    },
    subTitle: {
        fontSize: 12,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    secundaryContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    itemContainerInfo: {
        width: "87%",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    iconContainer: {
        justifyContent: "center",
        marginRight: 5,
        width: 40,
        height: 72,
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 17,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    textButtonCloseModal: {
        color: "#000",
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    searchInput: {
        height: '100%',
        color: '#fff',
        fontSize: 20,
        flexGrow: 1
    },
    bothSides: {
        flexDirection: "row",
        alignItems: "center"
    }
});
