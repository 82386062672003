import React, { useState, useEffect } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    forbiddenAccess,
    WhitelabelGroup,
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import CheckBoxLists, { CheckAssociationItem } from "../../../components/CheckAssociation";
import { useNavigationState } from "@react-navigation/native";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface groupComponentProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

export default function CrmCameraGroupsAssociation({ navigation, route, authenticatedUser }: groupComponentProps) {
    const params = route.params;
    const [whitelabelGroup, setWhitelabelGroup] = useState<WhitelabelGroup[]>([]);
    const [checkedGroups, setCheckedGroups] = useState<WhitelabelGroup[]>([]);
    const [loading, setLoading] = useState(false);
    const [isUserOwner, setIsUserOwner] = useState(false);

    const index = useNavigationState(state => state);

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
    }, [navigation, route]);

    useEffect(() => {
        checkPermissions();
        loadCrmGroups();
    }, [authenticatedUser]);

    async function save() {
        try {
            const checkedGroupsChanged = checkedGroups.filter((groupUser: WhitelabelGroup) => !groupUser.checked).map(group => group.id);
            const uncheckedGroupsChanged = whitelabelGroup.filter((groupUser: WhitelabelGroup) => groupUser.checked).map(group => group.id);

            await platformAPI.updateCameraGroups({
                id: params.id,
                groups_to_add: uncheckedGroupsChanged,
                groups_to_rm: checkedGroupsChanged
            });

            goBack();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            const camera = await platformAPI.getCameraForUpdate(params.id);

            if (authenticatedUser.id === camera.UserId) {
                setIsUserOwner(true);
            }
            if ((!authenticatedUser.permissions.can_see_camera_tab && !(authenticatedUser.id === camera.UserId)) || wl.id === 1) {
                goBack();
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function loadCrmGroups() {
        try {
            if (!authenticatedUser) return;
            setLoading(true);
            const cameraGroups = await platformAPI.getCameraGroups(params.id);

            const cGroups: WhitelabelGroup[] = [];
            for (const group of cameraGroups) {
                cGroups.push({
                    id: group.id,
                    title: group.title,
                    checked: true
                });
            }
            setCheckedGroups(cGroups);

            if (authenticatedUser?.permissions.can_see_camera_group_tab) {
                const groupsResponse = await platformAPI.getCrmGroupWl();
                const crmGroups = groupsResponse.filter((uGroup: WhitelabelGroup) =>
                    !cGroups.find((cGroup) => cGroup.id === uGroup.id));

                setWhitelabelGroup(crmGroups);
            }

            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    function goBack() {
        if (index.routes.length > 1) {
            navigation.navigate("CrmCameraList");
            return;
        }
        window.location.href = '/crm/cameras';
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    {(authenticatedUser?.permissions.can_edit_camera_options || isUserOwner) && authenticatedUser?.permissions.can_see_camera_group_tab ?
                        <TouchableOpacity style={styles.titleHeaderView} onPress={save}>
                            <Text style={styles.titleHeader}>{translate("save")}</Text>
                        </TouchableOpacity>
                        : null}
                </View>
            </View>
            <View style={styles.scrollContainer}>
                <ScrollView style={styles.backgroundView}>
                    <CheckBoxLists
                        dataChecked={checkedGroups as CheckAssociationItem[]}
                        dataUnchecked={whitelabelGroup as CheckAssociationItem[]}
                        readOnly={(!authenticatedUser?.permissions.can_edit_camera_options && !isUserOwner) || !authenticatedUser?.permissions.can_see_camera_group_tab}
                    />
                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    backgroundView: {
        backgroundColor: "#fff",
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        width: '100%',
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
});
