import React, { useEffect, useState } from 'react';
import { View, ScrollView, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { platformAPI, invalidBearerToken, UserData } from '../../services/platform-api';
import RenderHtml from 'react-native-render-html';
import { List } from 'react-native-paper';
import { translate } from '../../services/service-translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';

interface sourceProps {
    html: string;
}

interface TermComponentProps {
    navigation: Navigation;
}

export default function Policy({ navigation }: TermComponentProps) {
    const [source, setSource] = useState<sourceProps>({
        html: ''
    });
    const [user, setUser] = useState<UserData>();

    async function getUser() {
        try {
            const userReq = await platformAPI.getUserData();
            setUser(userReq);
        } catch (e) {
            if (!(e instanceof invalidBearerToken)) {
                console.error(e);
            }
        }
    }

    async function loadPolicy() {
        try {
            const policy = await platformAPI.getPolicy();
            if (policy) {
                setSource({ html: policy.replaceAll('<p class="ql-align-justify">', '<p style="text-align:justify">') });
            }

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        getUser();
        loadPolicy();
    }, []);

    async function goBack() {
        if (!user) {
            navigation.navigate('Authentication');
            return;
        }
        if (user.didAcceptUseTerms) {
            navigation.navigate("Camera", { screen: "More" });
            return;
        }
        if (!user.didAcceptUseTerms) {
            navigation.navigate("AcceptPrivacyAndTerms");
            return;
        }
    }


    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <TouchableOpacity onPress={goBack}>
                    <List.Icon style={styles.backArrow} icon='arrow-left' color="#fff" />
                </TouchableOpacity>
                <View style={styles.titleHeaderView}>
                    <Text style={styles.titleHeader}>{translate('policy')}</Text>
                </View>
            </View>
            <ScrollView style={[styles.scrollView, { height: 1 }]} bounces={false}>
                <RenderHtml
                    source={source}
                    contentWidth={window.innerWidth}
                />
            </ScrollView>
        </DefaultPageContainer>
    );


}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#000"
    },
    backgroundView: {
        flex: 1,
        backgroundColor: '#fff'
    },
    header: {
        height: 60,
        width: '100%',
        backgroundColor: '#0071bc',
        flexDirection: 'row'
    },
    backArrow: {
        alignSelf: 'flex-start',
    },
    titleHeaderView: {
        justifyContent: 'center'
    },
    titleHeader: {
        alignSelf: 'flex-start',
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
    },
    scrollView: {
        backgroundColor: '#fff',
        width: "100%",
        alignContent: 'center',
        paddingHorizontal: 20
    },
});
