import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Dialog } from 'react-native-paper';
import StepOne from './Step-1';
import StepTwo from './Step-2';
import StepThree from './Step-3';
import { platformAPI } from '../../services/platform-api';

interface SurveyProps {
    visible: boolean;
}

export enum Steps {
    One = 1,
    Two = 2,
    Three = 3
}

export interface Survey {
    scoreJustification: string | null;
    score: number | null;
}

export default function NpsSurvey({ visible }: SurveyProps) {
    const [step, setStep] = useState<Steps>(1);
    const [isVisible, setIsVisible] = useState<boolean>(visible);
    const [inputs, setInputs] = useState<Survey>({
        scoreJustification: null,
        score: null
    });
    const scores = getScores(10);

    function getScores(scores: number) {
        const scoreList = [];
        for (let i = 1; i <= scores; ++i) {
            scoreList.push({ label: i, value: i });
        }
        return scoreList;
    }

    async function sendScore() {
        if (step == 1) {
            setIsVisible(false);
        } else {
            setStep(3);
        }

        await platformAPI.sendNpsSurvey(inputs.score, inputs.scoreJustification);
    }

    return (
        <Dialog onDismiss={() => setIsVisible(false)} style={styles.dialog} visible={isVisible}>
            {step == 1 && (
                <StepOne sendScore={sendScore} setStep={setStep} />
            )}
            {step == 2 && (
                <StepTwo setDialogVisible={setIsVisible} sendScore={sendScore} scores={scores} inputs={inputs} setInputs={setInputs} />
            )}
            {step == 3 && (
                <StepThree setDialogVisible={setIsVisible} />
            )}
        </Dialog>
    );
}
const styles = StyleSheet.create({
    dialog: {
        borderRadius: 15
    }
});
