/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Dialog, List } from 'react-native-paper';
import { UserData } from '../../../services/platform-api';
import { translate } from '../../../services/service-translate';

export interface NotificationProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    user: UserData | undefined;
    sendNotification: () => Promise<void>;
}

enum ValidationState {
    INITIAL,
    FIREBASE_REGISTER_CHECKING,
    NOTIFICATION_PERMISSION_CHECKING,
    NOTIFICATION_PUSH_ALL,
    USER_PERMISSION_CHECKING,
    COMPLETED,
}

interface Requirement {
    title: string;
    success: boolean | 'stopped' | undefined;
}

const REQUIREMENTS: Requirement[] = [
    {
        title: "firebase_register",
        success: undefined
    },
    {
        title: "notification_granted",
        success: undefined
    },
    {
        title: "push_all_notifications",
        success: undefined
    },
    {
        title: "user_can_see_cameras",
        success: undefined
    }
];

export default function NotificationDialog({ open, setOpen, user, sendNotification }: NotificationProps) {
    const [currentState, setCurrentState] = useState<ValidationState>(ValidationState.INITIAL);
    const [requirements, setRequirements] = useState<Requirement[]>(REQUIREMENTS);
    const [canSendNotification, setCanSendNotification] = useState(false);

    useEffect(() => {
        const validate = async () => {
            switch (currentState) {
                case ValidationState.INITIAL:
                    setCurrentState(ValidationState.FIREBASE_REGISTER_CHECKING);
                    break;
                case ValidationState.FIREBASE_REGISTER_CHECKING:
                    const firebaseToken = localStorage.getItem("FIREBASE_TOKEN");
                    const firebaseSuccess = !!firebaseToken;
                    updateRequirement("firebase_register", firebaseSuccess);
                    if (!firebaseSuccess) {
                        updateRequirement("notification_granted", 'stopped');
                        updateRequirement("push_all_notifications", 'stopped');
                        updateRequirement("user_can_see_cameras", 'stopped');
                        break;
                    } else {
                        setTimeout(() => {
                            setCurrentState(ValidationState.NOTIFICATION_PERMISSION_CHECKING);
                        }, 1000);
                    }
                    break;
                case ValidationState.NOTIFICATION_PERMISSION_CHECKING:
                    const notificationPermission = Notification.permission === 'granted';
                    updateRequirement("notification_granted", notificationPermission);
                    if (!notificationPermission) {
                        updateRequirement("push_all_notifications", 'stopped');
                        updateRequirement("user_can_see_cameras", 'stopped');
                        break;
                    }
                    setTimeout(() => {
                        setCurrentState(ValidationState.NOTIFICATION_PUSH_ALL);
                    }, 1000);
                    break;
                case ValidationState.NOTIFICATION_PUSH_ALL:
                    const pushAll = user?.pushAll;
                    updateRequirement("push_all_notifications", pushAll);
                    if (!pushAll) {
                        updateRequirement("user_can_see_cameras", 'stopped');
                        break;
                    }
                    setTimeout(() => {
                        setCurrentState(ValidationState.USER_PERMISSION_CHECKING);
                    }, 1000);
                    break;
                case ValidationState.USER_PERMISSION_CHECKING:
                    const userPermission = user?.permissions.can_see_camera_tab;
                    updateRequirement("user_can_see_cameras", userPermission);
                    if (!userPermission) {
                        updateRequirement("user_can_see_cameras", 'stopped');
                        break;
                    }
                    setCurrentState(ValidationState.COMPLETED);
                    break;
                case ValidationState.COMPLETED:
                    setCanSendNotification(true);
                    break;
                default:
                    break;
            }
        };

        validate();
    }, [currentState, user?.permissions.can_see_camera_tab]);

    const updateRequirement = (title: string, success: boolean | undefined | 'stopped') => {
        setRequirements(prevRequirements =>
            prevRequirements.map(req =>
                req.title === title ? { ...req, success } : req
            )
        );
    };

    return (
        <>
            <Dialog style={{ borderRadius: 10 }} visible={open} onDismiss={() => setOpen(!open)} >
                <Dialog.Title onPressIn={{}} onPressOut={{}} style={styles.title}>{translate("notification_test_title")}</Dialog.Title>
                <Dialog.Content style={styles.content}>
                    <Text style={styles.subtitle}>{translate('notification_test_subtitle')}</Text>
                    <View style={styles.requisites}>
                        {requirements.map(item => (
                            <View key={item.title} style={styles.requirementField}>
                                {item.success === 'stopped' && (
                                    <List.Icon icon={"minus-circle"} color="#cecece" />
                                )}
                                {item.success === true && (
                                    <List.Icon icon={"check-circle"} color="green" />
                                )}
                                {item.success === false && (
                                    <List.Icon icon={"close-circle"} color="red" />
                                )}
                                {item.success === undefined && (
                                    <List.Icon icon={"check-circle"} color="#cecece" />
                                )}
                                <Text numberOfLines={1} style={styles.requirementText}>{translate(item.title)}</Text>
                            </View>
                        ))}
                    </View>
                </Dialog.Content>
                <Dialog.Actions style={styles.actions}>
                    <TouchableOpacity onPress={() => setOpen(!open)}>
                        <View style={styles.buttons}>
                            <Text style={styles.textButton}>{translate('cancel')}</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity disabled={!canSendNotification} onPress={sendNotification}>
                        <View style={[styles.buttons, { backgroundColor: canSendNotification ? "#0071bc" : "#7eb8dd" }]}>
                            <Text style={styles.textButton}>
                                {translate('send')}
                            </Text>
                        </View>
                    </TouchableOpacity>
                </Dialog.Actions>
            </Dialog>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 28,
        color: '#0071bc',
        fontWeight: 'bold',
    },
    content: {
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center'
    },
    subtitle: {
        color: "#a5a5a5"
    },
    requisites: {
        paddingTop: 20,
    },
    requirementField: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 2
    },
    requirementText: {
        fontWeight: "bold",
        fontSize: 14
    },
    actions: {
        width: '100%',
        padding: 10,
        justifyContent: 'space-evenly'
    },
    buttons: {
        padding: 15,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: '#0071bc',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    textButton: {
        color: '#fff',
        fontWeight: 'bold'
    }
});
