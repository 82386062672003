import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ScrollView,
    TextInput,
    FlatList,
    ListRenderItemInfo,
    Modal
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    GetCrmUser,
    GetUserAsaasPayments,
    invalidBearerToken,
    platformAPI,
} from "../../../services/platform-api";
import { translate } from "../../../services/service-translate";
import '../../../styles/tooltips.css';
import { DatePickerModal } from "react-native-paper-dates";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { showActionSheet, Option } from "../../../components/ActionSheet";
import { TextInputMask } from "react-native-masked-text";
import moment from "moment";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface UserPaymentsComponentProps {
    navigation: Navigation;
    route: {
        params: GetCrmUser;
    };
    authenticatedUser?: UserData;
}
const headerSize = 60;
const footerSize = 30;

export default function CrmUserPayments({ navigation, route, authenticatedUser }: UserPaymentsComponentProps) {
    const userParam = route.params;
    const threeDaysAhead = moment().add(3, 'day').toDate();
    const minSubscriptionDueDate = moment().add(30, 'day').toDate();
    const maxSubscriptionDueDate = moment().add(60, 'day').toDate();
    const [deletePaymentModal, setDeletePaymentModal] = useState(false);
    const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
    const [paymentValidError, setPaymentValidError] = useState(true);
    const [dateValidError, setDateValidError] = useState(true);
    const [subscriptionValidError, setSubscriptionValidError] = useState(true);
    const [paymentToBeDeleted, setPaymentToBeDeleted] = useState<GetUserAsaasPayments>();
    const [userData, setUserData] = useState<GetCrmUser>();
    const [showChargeCalendar, setShowChargeCalendar] = useState(false);
    const [showSubscriptionCalendar, setShowSubscriptionCalendar] = useState(false);
    const [currentChargeDate, setCurrentChargeDate] = useState<string | undefined>();
    const [currentSubscriptionDate, setCurrentSubscriptionDate] = useState<string>(moment(minSubscriptionDueDate).format("DD/MM/YYYY"));
    const [chargeDateSelected, setChargeDateSelected] = useState<Date>(threeDaysAhead);
    const [subscriptionDateSelected, setSubscriptionDateSelected] = useState<Date>(minSubscriptionDueDate);
    const [userPayments, setUserPayments] = useState<GetUserAsaasPayments[]>([]);
    const [userSubscription, setUserSubscription] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [subscription, setSubscription] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const { showActionSheetWithOptions } = useActionSheet();

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getUser();
            asaasPayments();
            getAsaasSubscription();
        });
        return unsubscribe;
    }, [navigation, route]);

    useEffect(() => {
        document.body.style.position = "fixed";
        checkPermissions();
    }, [authenticatedUser]);



    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id == 1 || !authenticatedUser.permissions.can_edit_user_payment) {
                return navigation.navigate("Camera", { screen: "More" });
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    function getDate(value: Date) {
        if (showChargeCalendar) {
            setCurrentChargeDate(moment(value).format("DD/MM/YYYY"));
            setChargeDateSelected(value);
        }
        if (showSubscriptionCalendar) {
            setCurrentSubscriptionDate(moment(value).format("DD/MM/YYYY"));
            setSubscriptionDateSelected(value);
        }
        value.setHours(0, 0, 0, 0);
        setShowChargeCalendar(false);
        setShowSubscriptionCalendar(false);
    }

    async function getUser() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            const userData = await platformAPI.getCrmUser(userParam.id);
            setUserData(userData);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function newPayment() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.newAsaasPayment(userParam.id, chargeDateSelected, parseFloat(value));
            await asaasPayments();
            setValue("");
            setCurrentChargeDate("");
            setChargeDateSelected(threeDaysAhead);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function deletePayment(item: GetUserAsaasPayments) {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteAsaasPayment(item.id);
            await asaasPayments();
            setDeletePaymentModal(false);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function asaasPayments() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            const payments = await platformAPI.getAsaasPayments(userParam.id);
            setUserPayments(payments.sort((a, b) => b.dueDate.localeCompare(a.dueDate)));
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function newAsaasSubscription() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.newAsaasSubscription(
                userParam.id,
                parseFloat(subscription),
                currentSubscriptionDate
            );
            setUserSubscription(parseFloat(subscription).toFixed(2));
            asaasPayments();
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function deleteAsaasSubscription() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteAsaasSubscription(userParam.id);
            setSubscription("");
            setUserSubscription("");
            setCurrentSubscriptionDate(moment(minSubscriptionDueDate).format("DD/MM/YYYY"));
            asaasPayments();
            setDeleteSubscriptionModal(false);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
    }

    async function getAsaasSubscription() {
        if (!userParam.id) {
            return;
        }
        setLoading(true);
        try {
            const sub = await platformAPI.getAsaasSubscription(userParam.id);
            const subscriptionValue = sub !== null ? sub.toFixed(2) : "";
            setUserSubscription(subscriptionValue);
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Authentication");
                return;
            }
            console.error(err);
        }
        setLoading(false);
    }

    function goBack() {
        setSubscription("");
        setUserSubscription("");
        setUserPayments([]);
        setUserData(undefined);
        navigation.navigate("CrmUserList");
        return;
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function valueSubscriptionValidation(subscription: number) {
        if (subscription < 10) {
            return setSubscriptionValidError(false);
        }
        setSubscriptionValidError(true);
    }

    function valuePaymentValidation(payment: number) {
        if (payment < 10) {
            return setPaymentValidError(false);
        }
        setPaymentValidError(true);
    }

    function actionSheet(payment: GetUserAsaasPayments) {
        setPaymentToBeDeleted(payment);
        const options: Option[] = [];

        options.push({
            title: translate("chargeInvoice"), onClick: () => {
                window.open(payment.invoiceUrl, "_blank");
            }
        });

        if (payment.status != "RECEIVED") {
            options.push({
                title: translate("invoice"),
                subtitle: translate("chargeNotYetReceived"),
                disabled: true,
                onClick: () => {
                    window.open(payment.invoice?.pdfUrl, "_blank");
                }
            });
        } else if (!payment.invoice || payment.invoice.status != "AUTHORIZED") {
            options.push({
                title: translate("invoice"),
                subtitle: translate("awaitingInvoice"),
                disabled: true,
                onClick: () => {
                    window.open(payment.invoice?.pdfUrl, "_blank");
                }
            });
        } else {
            options.push({
                title: translate("invoice"),
                onClick: () => {
                    window.open(payment.invoice?.pdfUrl, "_blank");
                }
            });
        }

        if (payment.status == "PENDING" || payment.status == "OVERDUE") {
            options.push({
                destructive: true,
                title: translate("delete"),
                onClick: () => {
                    setDeletePaymentModal(true);
                }
            });
        } else {
            options.push({
                destructive: true,
                title: translate("delete"),
                disabled: true,
                subtitle: translate("deleteInvalidMessage"),
                onClick: () => {
                    setDeletePaymentModal(true);
                }
            });
        }

        showActionSheet(showActionSheetWithOptions, options);
    }

    const renderItem = ({ item }: ListRenderItemInfo<GetUserAsaasPayments>) => {
        return (
            <>
                <TouchableOpacity onPress={() => actionSheet(item)}>
                    <View
                        style={styles.itemContainer}
                    >
                        <View style={{ width: 100 }}>
                            <Text style={[styles.list, { justifyContent: "center" }]}>{moment(item.dueDate).format("DD/MM/YYYY")}</Text>
                        </View>
                        <View style={{ width: 100 }}>
                            <Text style={[styles.list, { justifyContent: "center" }]}>{translate(item.status)}</Text>
                        </View>
                        <View style={{ width: 100 }}>
                            <Text style={[styles.list, { justifyContent: "center" }]}>{item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </>
        );
    };
    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <TouchableOpacity onPress={goBack}>
                    <List.Icon
                        style={styles.backArrow}
                        icon="arrow-left"
                        color="#fff"
                    />
                </TouchableOpacity>
                <View style={styles.registerView}>
                    <Text style={styles.register} numberOfLines={1} adjustsFontSizeToFit>{userData?.name}</Text>
                </View>
            </View>

            <ScrollView
                bounces={false}
                style={{ height: 1 }}
            >
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={showChargeCalendar || showSubscriptionCalendar}
                    onRequestClose={() => {
                        setShowChargeCalendar(false);
                        setShowSubscriptionCalendar(false);
                    }}
                >
                    <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
                        <DatePickerModal
                            locale={translate("lang")}
                            label={translate("date")}
                            mode="single"
                            visible={showChargeCalendar || showSubscriptionCalendar}
                            validRange={{
                                startDate: showChargeCalendar ? threeDaysAhead : minSubscriptionDueDate,
                                endDate: showChargeCalendar ? undefined : maxSubscriptionDueDate
                            }}
                            onDismiss={() => {
                                setShowChargeCalendar(false);
                                setShowSubscriptionCalendar(false);
                            }}
                            date={showChargeCalendar ? chargeDateSelected : subscriptionDateSelected}
                            onChange={(d) => {
                                if (!d.date) {
                                    setShowChargeCalendar(false);
                                    setShowSubscriptionCalendar(false);
                                    setDateValidError(false);
                                    return;
                                }
                                getDate(d.date);
                                setChargeDateSelected(d.date);
                                setSubscriptionDateSelected(d.date);
                                setDateValidError(true);
                            }
                            }
                            onConfirm={() => {
                                setShowChargeCalendar(false);
                                setShowSubscriptionCalendar(false);
                            }}
                        />
                    </View>
                </Modal>
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={deletePaymentModal}
                    onRequestClose={() => {
                        setDeletePaymentModal(!deletePaymentModal);
                    }}>
                    <View style={styles.fadeView} />
                    <View style={styles.centeredView}>
                        <View style={[styles.containerModal, { marginTop: window.innerHeight / 2.2 }]}>
                            <Text style={styles.titleModal} numberOfLines={2} adjustsFontSizeToFit>
                                {translate("deletePaymentConfirmation")}{"\n"}{paymentToBeDeleted?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                            <View style={styles.viewButtonsModal}>
                                <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeletePaymentModal(!deletePaymentModal)}>
                                    <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.buttonDeleteModal}
                                    onPress={() => {
                                        if (paymentToBeDeleted) {
                                            deletePayment(paymentToBeDeleted);
                                        }
                                    }}>
                                    <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={deleteSubscriptionModal}
                    onRequestClose={() => {
                        setDeleteSubscriptionModal(!deleteSubscriptionModal);
                    }}>
                    <View style={styles.fadeView} />
                    <View style={styles.centeredView}>
                        <View style={[styles.containerModal, { marginTop: window.innerHeight / 2.2 }]}>
                            <Text style={styles.titleModal} numberOfLines={2} adjustsFontSizeToFit >{translate("deleteSubscriptionConfirmation")}</Text>
                            <View style={styles.viewButtonsModal}>
                                <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteSubscriptionModal(!deleteSubscriptionModal)}>
                                    <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deleteAsaasSubscription()}>
                                    <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>
                <View style={[styles.infosView, { width: "100%" }]}>
                    <View style={styles.viewInputs}>
                        <View style={{ marginRight: 10, marginLeft: 10, marginBottom: 25 }}>
                            <Text style={[styles.labelText, { marginTop: 18, marginRight: 0, marginLeft: 0 }]}>{translate("subscription")}</Text>
                            <View style={styles.container}>
                                {userSubscription === "" ?
                                    <View style={{ width: "30%", marginRight: 10 }}>
                                        <Text style={{ fontSize: 16, marginTop: 18 }}>{translate("date")}</Text>
                                        <TouchableOpacity onPress={() => setShowSubscriptionCalendar(true)}>
                                            <TextInput
                                                editable={false}
                                                style={dateValidError ?
                                                    [styles.textInput, { borderLeftWidth: 1, borderRightWidth: 1, textAlign: "left" }] :
                                                    [styles.invalidTextInput, { borderLeftWidth: 1, borderRightWidth: 1, textAlign: "left" }]}
                                                value={currentSubscriptionDate}
                                            />
                                        </TouchableOpacity>
                                    </View> : null
                                }
                                <View style={{ width: "70%" }}>
                                    <Text style={{ fontSize: 16, marginTop: 18 }}>{translate("value")}</Text>
                                    <View style={styles.container}>
                                        <Text style={[styles.iconInput, { paddingHorizontal: 10 }]}>R$</Text>
                                        <TextInputMask
                                            type={'money'}
                                            editable={!userSubscription}
                                            style={userSubscription ? styles.disabledInput : !subscriptionValidError ? styles.invalidTextInput : styles.textInput}
                                            includeRawValueInChangeText={true}
                                            keyboardType='numeric'
                                            options={{
                                                precision: 2,
                                                separator: ',',
                                                delimiter: '.',
                                                unit: '',
                                                suffixUnit: ''
                                            }}
                                            maxLength={9}
                                            value={!userSubscription ? subscription : userSubscription}
                                            onChangeText={(maskedText, rawText) => {
                                                setSubscription(rawText || '');
                                                valueSubscriptionValidation(parseFloat(rawText || '0'));
                                            }}
                                        />
                                        <Text style={[styles.iconInput, { marginBottom: 38, paddingHorizontal: 10 }]}>{translate("month")}</Text>
                                        {userSubscription ? (
                                            <TouchableOpacity onPress={() => setDeleteSubscriptionModal(true)}>
                                                <List.Icon
                                                    style={[styles.plusBox, { marginRight: 18 }]}
                                                    icon="close-box"
                                                    color="red"
                                                />
                                            </TouchableOpacity>
                                        ) : (
                                            <TouchableOpacity onPress={() => {
                                                if (parseFloat(subscription) >= 10) {
                                                    return newAsaasSubscription();
                                                } setSubscriptionValidError(false);
                                            }}>
                                                <List.Icon
                                                    style={styles.plusBox}
                                                    icon="plus-box"
                                                    color={parseFloat(subscription) >= 10 ? "#000" : "rgba(0,0,0,0.2)"}
                                                />

                                            </TouchableOpacity>
                                        )
                                        }
                                    </View>
                                </View>
                            </View>
                            <View style={styles.itemSeparator} />
                        </View>
                        <View style={{ marginRight: 10, marginLeft: 10, marginBottom: 25 }}>
                            <Text style={[styles.labelText, { marginTop: 18, marginRight: 0, marginLeft: 0 }]}>{translate("charge")}</Text>
                            <View style={styles.container}>
                                <View style={{ width: "30%" }}>
                                    <Text style={[styles.labelText, { marginTop: 10, marginLeft: 0 }]}>{translate("date")}</Text>
                                    <TouchableOpacity onPress={() => setShowChargeCalendar(true)}>
                                        <TextInput
                                            editable={false}
                                            style={dateValidError ?
                                                [styles.textInput, { borderLeftWidth: 1, borderRightWidth: 1, textAlign: "left" }] :
                                                [styles.invalidTextInput, { borderLeftWidth: 1, borderRightWidth: 1, textAlign: "left" }]}
                                            value={currentChargeDate}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: "70%" }}>
                                    <Text style={[styles.labelText, { marginTop: 10 }]}>{translate("value")}</Text>
                                    <View style={styles.container}>
                                        <Text style={[styles.iconInput, { marginLeft: 10 }]}>R$</Text>
                                        <TextInputMask
                                            type={'money'}
                                            style={paymentValidError ?
                                                [styles.textInputs, { width: "100%", borderRightWidth: 1, textAlign: "right" }] :
                                                [styles.invalidTextInputs, { width: "100%", borderRightWidth: 1, textAlign: "right" }]}
                                            includeRawValueInChangeText={true}
                                            options={{
                                                precision: 2,
                                                separator: ',',
                                                delimiter: '.',
                                                unit: '',
                                                suffixUnit: ''
                                            }}
                                            value={value}
                                            onChangeText={(maskedText, rawText) => {
                                                setValue(rawText || '');
                                                valuePaymentValidation(parseFloat(rawText || '0'));
                                            }}
                                        />
                                        {!currentChargeDate || !(parseFloat(value) >= 10) ?
                                            (<TouchableOpacity
                                                onPress={() => {
                                                    if (currentChargeDate || paymentValidError) {
                                                        setPaymentValidError(false);
                                                        setDateValidError(false);
                                                        return;
                                                    }
                                                }}>
                                                <List.Icon
                                                    style={styles.plusBox}
                                                    icon="plus-box"
                                                    color={"rgba(0,0,0,0.2)"}
                                                />
                                            </TouchableOpacity>
                                            ) : (
                                                <TouchableOpacity
                                                    onPress={() => newPayment()}>
                                                    <List.Icon
                                                        style={styles.plusBox}
                                                        icon="plus-box"
                                                        color={"#000"}
                                                    />
                                                </TouchableOpacity>)
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.itemSeparator} />
                        <View style={styles.listTitles}>
                            <View style={{ width: 100 }}>
                                <Text style={[styles.listTitle, { justifyContent: "center" }]}>{translate("dueDate")}</Text>
                            </View>
                            <View style={{ width: 100 }}>
                                <Text style={[styles.listTitle, { justifyContent: "center" }]}>{translate("status")}</Text>
                            </View>
                            <View style={{ width: 100 }}>
                                <Text style={[styles.listTitle, { justifyContent: "center" }]}>{translate("value")}</Text>
                            </View>
                        </View>
                        <View style={styles.itemSeparator} />
                    </View>
                </View>
                {!userPayments.length ? (
                    !loading ? (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>) : <></>
                ) : (
                    <FlatList
                        bounces={false}
                        data={userPayments}
                        keyExtractor={(item) => item.id.toString()}
                        renderItem={renderItem}
                        removeClippedSubviews={true}
                        updateCellsBatchingPeriod={100}
                        windowSize={12}
                        ItemSeparatorComponent={ItemSeparator}
                        nestedScrollEnabled
                    />)}
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    container: {
        display: "flex",
        flexDirection: "row",
    },
    wrapView: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    itemContainer: {
        flexDirection: 'row',
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        height: 72,
        paddingVertical: 0,
        paddingHorizontal: 20,
    },
    subContainer: {
        display: "flex",
        flexDirection: "row",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    plusBox: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: 10,
    },
    saveButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 13,
        fontSize: 17,
        color: "rgb(0, 113, 188)",
        fontWeight: "bold",
        height: 45,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    list: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
    },
    listTitle: {
        display: "flex",
        flexDirection: "row",
        textAlignVertical: "center",
        fontWeight: "bold",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    infosView: {
        alignItems: 'center',
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    subTitle: {
        fontSize: 12,
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },
    viewInputs: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'column',
        width: "100%",
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,

    },
    invalidTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    textInput: {
        textAlign: "right",
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 5,
        marginBottom: 10,
        width: "100%",
    },
    invalidTextInput: {
        textAlign: "right",
        borderColor: "red",
        backgroundColor: '#fff',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        width: "100%",
    },
    disabledInput: {
        textAlign: "right",
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        width: "100%",
    },
    iconInput: {
        display: 'flex',
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        alignItems: "center",
    },
    listTitles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginHorizontal: 18,
        marginVertical: 25,
    },
    emptyTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        cursor: 'default',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        width: "100%",
        textAlign: "right"
    },
    labelText: {
        marginLeft: 10,
        fontSize: 16,
    },
    itemContainerInfo: {
        width: "87%",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    iconContainer: {
        justifyContent: "center",
        marginRight: 5,
        width: 40,
        height: 72,
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 17,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    textButtonCloseModal: {
        color: "#000",
    },
    registerView: {
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'flex-start',
        width: "83%",
    },
    register: {
        textAlign: "left",
        alignSelf: "flex-start",
        color: "#fff",
        fontSize: 20,
    },
});
