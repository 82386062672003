/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
} from "react-native";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import L, { Map, Marker as LeafletMarker } from 'leaflet';
import { CameraSteps } from '../../pages/crm/camera/Register';
import { translate } from '../../services/service-translate';
import { Area } from '../../services/platform-api';
import { List } from 'react-native-paper';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../../styles/map.css';
import 'leaflet-draw/dist/leaflet.draw.css';

interface CamMaps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setLat: React.Dispatch<React.SetStateAction<number>>;
    setLng: React.Dispatch<React.SetStateAction<number>>;
    lat: number;
    lng: number;
    setArea: React.Dispatch<React.SetStateAction<Area | null>>;
    area: Area | null;
    brand: string;
}

interface LeafletDrawEvent extends L.LeafletEvent {
    layerType?: string;
    layers?: {
        _layers: {
            [key: string]: any;
        }
    };
}

interface PolygonType extends L.Polygon {
    _leaflet_id?: number;
}

declare module 'leaflet' {
    namespace Control {
        interface Draw {
            addVertex: any;
            _toolbars: any;
        }
    }
}

const headerSize = 60;
const footerSize = 30;

export default function CamMap({ setStep, setLoading, setLat, setLng, lat, lng, setArea, brand }: CamMaps) {
    const [address, setAddress] = useState<string>();
    const markerRef = useRef<LeafletMarker>(null);
    const [map, setMap] = useState<Map>();
    const [polygon, setPolygon] = useState<PolygonType | null>(null);
    const drawControlRef = useRef<L.Control.Draw | null>(null);
    const drawnItems = new L.FeatureGroup();

    /* eslint-disable */
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
        iconUrl: require("leaflet/dist/images/marker-icon.png").default,
        shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
    });
    /* eslint-enable */

    const myIcon = L.icon({
        iconUrl: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_map_badge.png',
        iconSize: [29, 35],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    });

    useEffect(() => {
        getLatLon();
    }, [address]);

    useEffect(() => {
        if (map) {
            map.setView([lat, lng], 18);
            if (polygon) {
                map.removeLayer(polygon);
            }
        }
    }, [lat, lng]);

    useEffect(() => {
        if (map) {
            const drawControl = new L.Control.Draw({
                draw: {
                    polygon: {
                        icon: new L.DivIcon({
                            iconSize: new L.Point(16, 16),
                            className: 'leaflet-div-icon leaflet-editing-icon my-own-class'
                        }),
                        allowIntersection: false,
                        drawError: {
                            color: '#5878B8',
                            message: '<strong>Oh snap!<strong> you can\'t draw that!'
                        },
                    },
                    polyline: false,
                    circle: false,
                    rectangle: false,
                    marker: false,
                    circlemarker: false
                },
                edit: {
                    featureGroup: drawnItems
                }
            });

            translateDrawControl();

            map.addControl(drawControl);
            map.addLayer(drawnItems);
            drawControlRef.current = drawControl;

            map.on(L.Draw.Event.DRAWSTART, async (e: LeafletDrawEvent) => {
                const { layerType } = e;
                if (layerType === 'polygon') {
                    const marker = markerRef.current;
                    if (marker != null) {
                        const latLng = marker.getLatLng();
                        drawControl._toolbars.draw._modes.polygon.handler.addVertex(latLng);
                    }
                }
            });

            map.on(L.Draw.Event.CREATED, (e: LeafletDrawEvent) => {
                const { layerType } = e;
                const layer = e.layer;

                if (layerType === 'polygon') {
                    const coordinates: [number, number][] = [];
                    setPolygon(layer);
                    for (const coord of layer.getLatLngs()[0]) {
                        coordinates.push([Number(coord.lng), Number(coord.lat)]);
                    }
                    if (coordinates) {
                        coordinates.push(coordinates[0]);
                        setArea({ coordinates: [coordinates], type: 'Polygon' });
                    }
                }
                drawnItems.addLayer(layer);
            });
        }


    }, [map]);

    useEffect(() => {
        const handleEdited = (e: LeafletDrawEvent) => {
            const { layers } = e;
            if (polygon && layers) {
                setArea(null);
                const coordinates: [number, number][] = [];
                setPolygon(layers._layers[String(polygon._leaflet_id)]);
                for (const coord of layers._layers[String(polygon._leaflet_id)].getLatLngs()[0]) {
                    coordinates.push([Number(coord.lng), Number(coord.lat)]);
                }
                if (coordinates) {
                    coordinates.push(coordinates[0]);
                    setArea({ coordinates: [coordinates], type: 'Polygon' });
                }
            }
        };

        const handleRemoved = (e: LeafletDrawEvent) => {
            const { layers } = e;
            if (polygon && layers) {
                map?.removeLayer(layers._layers[String(polygon._leaflet_id)]);
                setArea(null);
            }
        };

        if (map) {
            map.on(L.Draw.Event.EDITED, handleEdited);
            map.on(L.Draw.Event.DELETED, handleRemoved);
        }

        return () => {
            if (map) {
                if (handleEdited) {
                    map.off(L.Draw.Event.EDITED, handleEdited);
                }
                if (handleRemoved) {
                    map.off(L.Draw.Event.DELETED, handleRemoved);
                }
            }
        };
    }, [polygon]);


    const eventHandlers = useMemo<L.LeafletEventHandlerFnMap | undefined>(
        () => ({
            dragend() {
                const drawControl = drawControlRef.current;
                drawControl?._toolbars.edit.disable();
                const marker = markerRef.current;
                if (marker != null) {
                    if (polygon && map) {
                        map.removeLayer(polygon);
                        setPolygon(null);
                    }
                    const latLng = marker.getLatLng();
                    setLat(latLng.lat);
                    setLng(latLng.lng);
                }
            },
        }),
        [polygon]
    );

    const translateDrawControl = () => {
        if (map) {
            L.drawLocal.draw.handlers.polygon.tooltip.cont = translate('CLICK_TO_CONTINUE_DRAWING');
            L.drawLocal.draw.handlers.polygon.tooltip.end = translate('CLICK_FIRST_POINT_TO_CLOSE_SHAPE');
            L.drawLocal.draw.handlers.polygon.tooltip.start = translate('CLICK_TO_START_DRAWING');
            L.drawLocal.draw.handlers.polyline.error = translate('HTML_SHAPE_EDGES_CANNOT_CROSS');
            L.drawLocal.draw.toolbar.actions.text = translate('GENERIC_CANCEL');
            L.drawLocal.edit.toolbar.actions.cancel.text = translate('GENERIC_CANCEL');
            L.drawLocal.draw.toolbar.actions.title = translate('GENERIC_CANCEL_DRAWING');
            L.drawLocal.draw.toolbar.buttons.polygon = translate('GENERIC_DRAW_VIEWING_AREA');
            L.drawLocal.draw.toolbar.finish.text = translate('GENERIC_FINISH');
            L.drawLocal.draw.toolbar.finish.title = translate('GENERIC_FINISH_DRAWING');
            L.drawLocal.draw.toolbar.undo.text = translate('GENERIC_DELETE_LAST_POINT');
            L.drawLocal.draw.toolbar.undo.title = translate('GENERIC_DELETE_LAST_POINT_DRAWN');
            L.drawLocal.edit.toolbar.actions.save.text = translate('GENERIC_SAVE');
            L.drawLocal.edit.toolbar.actions.save.title = translate('GENERIC_SAVE');
            L.drawLocal.edit.toolbar.actions.cancel.title = translate('CANCEL_EDITING_DISCARD_CHANGEGS');
            L.drawLocal.edit.toolbar.buttons.edit = translate('GENERIC_EDIT');
            L.drawLocal.edit.toolbar.buttons.editDisabled = translate('GENERIC_NOTHING_TO_EDIT');
            L.drawLocal.edit.toolbar.buttons.remove = translate('GENERIC_REMOVE_DRAWING');
            L.drawLocal.edit.toolbar.buttons.removeDisabled = translate('GENERIC_NOTHING_TO_REMOVE');
            L.drawLocal.edit.handlers.edit.tooltip.text = translate('DRAG_HANDLES_OR_MARKER_TO_EDIT');
            L.drawLocal.edit.handlers.edit.tooltip.subtext = translate('CLICK_CANCEL_TO_UNDO_CHANGES');
            L.drawLocal.edit.handlers.remove.tooltip.text = translate('CLICK_ON_THE_CAMERA_OR_AREA_TO_REMOVE');
        }
    };

    const getLatLon = async () => {
        try {
            if (address !== undefined) {
                const geocode = await geocodeByAddress(address);
                const { lat, lng } = await getLatLng(geocode[0]);
                setLat(lat);
                setLng(lng);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const goBack = () => {
        if (brand === 'INTELBRAS') {
            setLoading(false);
            return setStep('serial_number_intelbras');
        } else if (brand === 'HIKVISION' || brand === 'HIK_PRO_CONNECT') {
            setLoading(false);
            return setStep('serial_number_hik');
        }
        setLoading(false);
        return setStep('rtsp_link');
    };

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>

                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("camRegister")}</Text>
                    </View>
                </View>
            </View>
            <Text style={styles.labelText}>{translate("Address")}</Text>
            <View style={styles.GooglePlaces}>
                <GooglePlacesAutocomplete
                    apiKey="AIzaSyATThzieYphmKnlRyELRUHs3NCLgAU9LrQ"
                    selectProps={{
                        onChange: (value) => {
                            setAddress(value?.label);
                        }
                    }}
                />
            </View>
            <View style={styles.mapContainer}>
                <MapContainer
                    doubleClickZoom={false}
                    dragging={false}
                    zoomControl={false}
                    scrollWheelZoom={true}
                    whenCreated={(map: Map) => setMap(map)}
                    center={[lat, lng]}
                    id="map"
                    zoom={3}
                >
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    />
                    <Marker
                        eventHandlers={eventHandlers}
                        icon={myIcon}
                        position={{ lat: lat, lng: lng }}
                        draggable={true}
                        ref={markerRef}
                    />
                </MapContainer>
            </View>
            <View style={styles.footer}>
                <TouchableOpacity style={styles.buttonNext} onPress={() => setStep('plan')}>
                    <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                </TouchableOpacity>
            </View >
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },

    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    labelText: {
        marginTop: 25,
        marginLeft: 10,
        fontSize: 16,
    },
    titleInput: {
        backgroundColor: '#fff',
        width: "95%",
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
    },
    GooglePlaces: {
        zIndex: -1,
        display: "flex",
        backgroundColor: '#fff',
        width: "95%",
        marginRight: 10,
        height: 45,
        color: '#000',
        fontSize: 16,
        marginLeft: 10,
    },
    mapContainer: {
        zIndex: -9999,
        flex: 1
    },
    footer: {
        position: "absolute",
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "#FFF"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
});

