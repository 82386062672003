import moment from "moment";
import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View, Text, Modal, ScrollView } from "react-native";
import { Image as Img } from 'react-native';
import { List } from "react-native-paper";
import VehicleImage from "../../components/VehicleImage";
import { Camera, LprDetection, platformAPI } from "../../services/platform-api";
import { translate } from "../../services/service-translate";
import { useNavigationState } from '@react-navigation/native';
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import * as Location from 'expo-location';
import L, { Map } from 'leaflet';
import 'leaflet-routing-machine';
import '../../styles/lpr-routing.css';
import DefaultPageContainer from "../../components/DefaultPageContainer";


interface LPRInfoProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
}

const headerSize = 60;

export default function LicensePlateRecognitionDetails({ navigation, route }: LPRInfoProps) {
    const params = route.params;
    const [lpr, setLpr] = useState<LprDetection>();
    const [modalOpen, setModalOpen] = useState(false);
    const [camera, setCamera] = useState<Camera>();
    const maxVehicleImageWidth = 800;
    const index = useNavigationState(state => state);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    /* eslint-disable */
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
        iconUrl: require("leaflet/dist/images/marker-icon.png").default,
        shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
    });
    /* eslint-enable */

    async function whenCreated(map: Map) {

        const button = new L.Control();

        button.setPosition('topright');
        button.onAdd = (map: Map) => {
            const helpDiv = L.DomUtil.create("div", "button-simulate-route");
            const image = L.DomUtil.create('img', 'icon-simulate-route');
            image.src = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fmap-marked-alt-solid.svg';
            const texto = L.DomUtil.create("div", "text-simulate-route");
            texto.innerHTML = translate('simulateRoute');

            helpDiv.appendChild(image);
            helpDiv.appendChild(texto);

            helpDiv.addEventListener("click", async () => {
                try {
                    const { status } = await Location.requestForegroundPermissionsAsync();
                    if (status !== 'granted') {
                        return;
                    }

                    const location = await Location.getCurrentPositionAsync({});

                    const control = L.Routing.control({
                        waypoints: [
                            L.latLng(location.coords.latitude, location.coords.longitude),
                            L.latLng(Number(camera?.latitude), Number(camera?.longitude))
                        ],
                        router: L.Routing.osrmv1({
                            serviceUrl: `https://router.project-osrm.org/route/v1/`,
                        }),
                        showAlternatives: false
                    });

                    control.addTo(map);

                    const elements = document.getElementsByClassName('leaflet-marker-draggable');
                    for (let i = 0; i < elements.length; i++) {
                        const element = elements[i];
                        element.classList.add('hidden-element');
                    }
                } catch (err) {
                    console.error(err);
                }
            });

            return helpDiv;
        };

        button.addTo(map);

    }

    const myIcon = L.icon({
        iconUrl: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_map_badge.png',
        iconSize: [29, 35],
        iconAnchor: [18, 41],
        popupAnchor: [0, -41]
    });

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');

        getLpr();

        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [navigation, route]);

    async function getLpr() {
        try {
            const res = await platformAPI.getLprDetectionById(params.id);

            setLpr(res);
        } catch (err) {
            console.log(err);
            return;
        }
    }

    useEffect(() => {
        getCameraForMap();
    }, [lpr]);

    async function getCameraForMap() {
        try {
            if (!lpr) return;
            const res = await platformAPI.getCamera(Number(lpr.camera));

            setCamera(res);
        } catch (err) {
            console.log(err);
            return;
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        if (index.routes.length > 1) {
            navigation.navigate("LPR");
            return;
        }
        window.location.href = '/lpr';
    }

    function saveImage(plate: string, detectionDate: number, imageUrl: string | null) {

        if (!imageUrl) {
            return;
        }

        const dateObject = moment(detectionDate);
        const pictureName = `${plate}_${dateObject.format("DDMMYYYYhmmssSS")}`;

        const image = new Image();
        image.crossOrigin = "anonymous";

        image.addEventListener('load', () => {
            const download = document.createElement('a');
            const canvas = document.createElement('canvas');

            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(image, 0, 0, image.width, image.height);

            download.addEventListener("click", () => {
                const url = canvas.toDataURL('image/jpeg');
                download.download = `${pictureName}.jpg`;
                download.href = url;

            });

            download.click();
        });
        image.src = imageUrl;


    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <TouchableOpacity onPress={goBack}>
                    <List.Icon
                        style={styles.backArrow}
                        icon="arrow-left"
                        color="#fff"
                    />
                </TouchableOpacity>
                <View style={styles.titleHeaderView}>
                    <Text style={styles.titleHeader}>
                        {translate("detailsTitle")}
                    </Text>
                </View>
            </View>
            <ScrollView style={{ height: 1 }}>
                <View style={styles.body}>
                    {lpr ? (
                        <>
                            <View style={styles.bodyContent}>
                                <View style={[styles.imageContainer, { height: screenWidth > maxVehicleImageWidth ? maxVehicleImageWidth / 1.77 : screenWidth / 1.77 }]}>
                                    <VehicleImage
                                        src={lpr.image_url}
                                        vehiclePosition={lpr.vehicle_position}
                                        platePosition={lpr.plate_position}
                                        maxCanvasWidth={maxVehicleImageWidth}
                                    />
                                </View>
                                <View style={[styles.infoContainer, { maxWidth: maxVehicleImageWidth }]}>
                                    <View >
                                        <View style={styles.contentView}>
                                            <View style={styles.iconView}>
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_lpr_icon.png' }} style={styles.iconLpr} />
                                            </View>
                                            <Text style={styles.plateLabel}>
                                                {lpr.plate}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.infoContainerBox}>
                                        <View style={styles.contentView}>
                                            <View style={styles.iconView}>
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_location_dot.png' }} style={styles.cameraTitleIcon} />
                                            </View>
                                            <Text style={styles.contentLabel}>
                                                {lpr["Camera.title"]}
                                            </Text>
                                        </View>
                                        <View style={styles.contentView}>
                                            <View style={styles.iconView}>
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_calendar_check.png' }} style={styles.calendarIcon} />
                                            </View>
                                            <Text style={styles.contentLabel}>
                                                {lpr.dateTZFormatted}
                                            </Text>
                                        </View>
                                        <ItemSeparator />
                                        <View style={styles.contentView}>
                                            <View style={styles.iconView}>
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_spell_check.png' }} style={styles.confidenceIcon} />
                                            </View>
                                            <Text style={styles.contentLabel}>
                                                <Text style={{ fontWeight: '600' }}>{translate('confidence')}:</Text> {Math.ceil(lpr.confidence)}%
                                            </Text>
                                        </View>
                                        {lpr.blacklist_infos ?
                                            <View style={styles.contentView}>
                                                <View style={styles.iconView}>
                                                    <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_files.png' }} style={styles.descriptionIcon} />
                                                </View>
                                                <Text numberOfLines={1} style={[styles.contentLabel]}>
                                                    <Text style={{ fontWeight: '600' }}>{translate("description")}:</Text> {
                                                        lpr.blacklist_infos.map((blacklist) => {
                                                            if (blacklist.userName) {
                                                                return blacklist.desc + " " + translate("createdBy") + " " + blacklist.userName;
                                                            } else {
                                                                return blacklist.desc;
                                                            }
                                                        }).join(' - ')
                                                    }
                                                </Text>
                                            </View>
                                            :
                                            null
                                        }
                                        {lpr.carbigdata_vehicle_information ?
                                            <>
                                                <ItemSeparator />
                                                <View style={styles.carIconView}>
                                                    <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_car.png' }} style={styles.carIcon} />
                                                </View>
                                                {lpr.carbigdata_vehicle_information.brand ?
                                                    <View style={styles.contentView}>
                                                        <View style={styles.iconView} />
                                                        <Text style={styles.contentLabel}>
                                                            <Text style={{ fontWeight: '600' }}>{translate('vehicleBrand')}:</Text> {lpr.carbigdata_vehicle_information.brand}
                                                        </Text>
                                                    </View>
                                                    : null
                                                }
                                                {lpr.carbigdata_vehicle_information.model ?
                                                    <View style={styles.contentView}>
                                                        <View style={styles.iconView} />
                                                        <Text style={styles.contentLabel}>
                                                            <Text style={{ fontWeight: '600' }}>{translate('vehicleModel')}:</Text> {lpr.carbigdata_vehicle_information.model}
                                                        </Text>
                                                    </View>
                                                    : null
                                                }
                                                {lpr.carbigdata_vehicle_information.color ?
                                                    <View style={styles.contentView}>
                                                        <View style={styles.iconView} />
                                                        <Text style={styles.contentLabel}>
                                                            <Text style={{ fontWeight: '600' }}>{translate('vehicleColor')}:</Text> {lpr.carbigdata_vehicle_information.color}
                                                        </Text>
                                                    </View>
                                                    : null
                                                }
                                                {lpr.carbigdata_vehicle_information.type ?
                                                    <View style={styles.contentView}>
                                                        <View style={styles.iconView} />
                                                        <Text style={styles.contentLabel}>
                                                            <Text style={{ fontWeight: '600' }}>{translate('vehicleType')}:</Text> {lpr.carbigdata_vehicle_information.type}
                                                        </Text>
                                                    </View>
                                                    : null
                                                }
                                                {lpr.carbigdata_vehicle_information.city ?
                                                    <View style={styles.contentView}>
                                                        <View style={styles.iconView} />
                                                        <Text style={styles.contentLabel}>
                                                            <Text style={{ fontWeight: '600' }}>{translate('vehicleOrigin')}:</Text> {lpr.carbigdata_vehicle_information.city}
                                                        </Text>
                                                    </View>
                                                    : null
                                                }
                                            </>
                                            :
                                            null

                                        }
                                        <View style={styles.buttonsView}>
                                            <TouchableOpacity style={styles.buttons}
                                                onPress={() => {
                                                    navigation.navigate("CameraWatch", { id: lpr.camera });
                                                }}
                                            >
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_circle_play.png' }} style={styles.buttonPlay} />
                                                <Text style={styles.buttonLabel}>{translate('seeCamera')}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => saveImage(lpr.plate, lpr.start, lpr.image_url)}
                                                style={styles.buttons}
                                            >
                                                <Img source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_arrow_down.png' }} style={styles.arrowDown} />
                                                <Text style={styles.buttonLabel}>{translate('saveImage')}</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.mapView}>
                                            {camera ?
                                                <TouchableOpacity onPress={() => setModalOpen(!modalOpen)}>
                                                    <MapContainer
                                                        scrollWheelZoom={false}
                                                        center={[parseFloat(camera?.latitude), parseFloat(camera?.longitude)]}
                                                        zoom={17}
                                                        dragging={false}
                                                        zoomControl={false}
                                                        doubleClickZoom={false}
                                                        style={{ height: "30vh" }}
                                                    >
                                                        <TileLayer
                                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                                        />
                                                        <Marker icon={myIcon} position={[parseFloat(camera?.latitude), parseFloat(camera?.longitude)]} />
                                                    </MapContainer>
                                                </TouchableOpacity>
                                                :
                                                null
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <Modal
                                animationType='slide'
                                transparent={true}
                                visible={modalOpen}
                                onRequestClose={() => {
                                    setModalOpen(!modalOpen);
                                }}
                            >
                                <View style={styles.mapModalView}>
                                    <TouchableOpacity style={styles.modalBackgroundView} onPress={() => setModalOpen(!modalOpen)} />
                                    <View style={styles.topModalView}>
                                        <TouchableOpacity onPress={() => setModalOpen(!modalOpen)}>
                                            <List.Icon style={{ alignSelf: 'center' }} icon='chevron-down' color='#0071bc' />
                                        </TouchableOpacity>
                                        <Text style={styles.topModalText}>{translate("cameraLocalization")}</Text>
                                    </View>
                                    {camera && (
                                        <MapContainer
                                            scrollWheelZoom={true}
                                            center={[parseFloat(camera?.latitude), parseFloat(camera?.longitude)]}
                                            zoom={17}
                                            dragging={true}
                                            zoomControl={true}
                                            doubleClickZoom={true}
                                            style={{ height: "80vh" }}
                                            whenCreated={whenCreated}
                                        >
                                            <TileLayer
                                                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                            />
                                            <Marker icon={myIcon} position={[parseFloat(camera?.latitude), parseFloat(camera?.longitude)]} />
                                        </MapContainer>
                                    )}

                                </View>
                            </Modal>
                        </>
                    ) : null}
                </View>
            </ScrollView>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    body: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    bodyContent: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    imageContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
    },
    infoContainer: {
        width: "90%",
        height: "23%",
        marginTop: 20,
    },
    vehicleImage: {
        width: "100%",
        height: "100%",
        borderRadius: 5,
    },
    contentView: {
        flexDirection: "row",
        alignItems: 'center',
        height: 30,
    },
    contentLabel: {
        fontFamily: 'Helvetica',
        marginLeft: 14,
        color: '#58595b'
    },
    iconView: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },
    carIconView: {
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 148
    },
    iconLpr: {
        width: 24,
        height: 10,
    },
    plateLabel: {
        fontFamily: "Helvetica",
        fontSize: 20,
        fontWeight: "600",
        width: "100%",
        color: "#58595b",
        marginLeft: 14
    },
    cameraTitleIcon: {
        width: 12,
        height: 16,
    },
    calendarIcon: {
        width: 14,
        height: 16,
    },
    confidenceIcon: {
        width: 16,
        height: 14,
    },
    descriptionIcon: {
        width: 12,
        height: 16
    },
    carIcon: {
        width: 16,
        height: 11.2
    },
    descriptionTextView: {
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infoContainerBox: {
        paddingVertical: 5,
    },
    buttonsContainer: {
        justifyContent: "center",
        flexDirection: "row",
    },
    buttonsView: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 15
    },
    buttons: {
        borderColor: "#0071bc",
        borderWidth: 1,
        borderRadius: 2,
        width: '48%',
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    arrowDown: {
        width: 24,
        height: 16,
    },
    buttonPlay: {
        width: 24,
        height: 24,
    },
    buttonLabel: {
        fontSize: 14,
        fontWeight: '600',
        color: "#0071bc",
        marginHorizontal: 8
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
        marginVertical: 5
    },
    mapView: {
        marginTop: 24,
        paddingBottom: 24
    },
    mapModalView: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalBackgroundView: {
        height: "15%",
        width: "100%",
    },
    topModalView: {
        height: 50,
        flexDirection: 'row',
        backgroundColor: '#fff',
        alignItems: 'center'

    },
    topModalText: {
        color: "#0071bc",
        fontSize: 16
    }
});
